import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import {
    bookingActions,
} from "../../../../actions";
import Select from "react-select";


class RepsUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            selectedRep: [],
            selectedAdditionalRep: [],
            bookingId:props.bookingId,
            reps: [],
            additionalReps:[],
            updateBookingInfo: {
                id: "",
                assigned_rep: "",
                additional_reps: []
            },
            errorMsg:{
                assigned_rep: "",
                //additional_reps: ""
            }
        };
        this.setSelectedOptionAdditionalRep = this.setSelectedOptionAdditionalRep.bind(this);
        this.setSelectedOptionRep = this.setSelectedOptionRep.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    setBookingData(data) {

        var defaultReps = this.state.reps.find(
            val => val.value === data.assigned_rep,
        );

        var defaultAdditionalReps = [];
        data.additional_reps.map(repValue => {
            defaultAdditionalReps.push(
                this.state.additionalReps.find(val => val.value === repValue),
            );
        });

        const { updateBookingInfo } = this.state;
        updateBookingInfo["id"] = data["_id"];
        updateBookingInfo["assigned_rep"] = data["assigned_rep"];
        var tempAdditionalReps = [];
        data.additional_reps.map(item => {
            //tempAdditionalReps.push({ id: item });
            tempAdditionalReps.push(item);

        });
        updateBookingInfo["additional_reps"] = tempAdditionalReps;

        this.setState(
            {
                updateBookingInfo,
                bookingInfo: data,
                selectedRep: defaultReps,
                selectedAdditionalRep: defaultAdditionalReps,
            }
        );
    }

    showRepsList(repsList) {
        var tempRepsList = [];
        repsList.map(item => {
            var option = {
                value: item._id,
                label: item.first_name + " " + item.last_name,
            };
            tempRepsList.push(option);
        });
        this.setState({
            reps: tempRepsList,
            additionalReps: tempRepsList,
        });
    }

    setSelectedOptionAdditionalRep = selectedAdditionalRep => {
        var tempAdditionalReps = [];
        selectedAdditionalRep.map(item => {
            //tempAdditionalReps.push({ id: item.value });
            tempAdditionalReps.push(item.value);

        });
        this.setState(
            {
                selectedAdditionalRep: selectedAdditionalRep,
                updateBookingInfo: {
                    ...this.state.updateBookingInfo,
                    additional_reps: tempAdditionalReps,
                },
            },
            () => {
                console.log(this.state.updateBookingInfo.additional_reps);
            },
        );
    };

    setSelectedOptionRep(e) {
        var selectedRep = [{ value: e.value, label: e.label }];
        this.setState({
            selectedRep: selectedRep,
            updateBookingInfo: {
                ...this.state.updateBookingInfo,
                assigned_rep: e.value,
            },
            // changesMade: true,
        });
    }

    async handleSubmit() {
        const { updateBookingInfo } = this.state;
        if(updateBookingInfo["assigned_rep"] == ""){
            //|| updateBookingInfo["additional_reps"]?.length === 0
            let errorMsg = {
                assigned_rep : updateBookingInfo["assigned_rep"] == "" ? "Assigned reps is required." : "",
                //additional_reps : updateBookingInfo["additional_reps"]?.length === 0 ? "Additional reps is required." : ""
            };
            this.setState({errorMsg : errorMsg});
        }else{
            updateBookingInfo["mail_sent"] = "false";
            updateBookingInfo["status"] = "repsUpdated";
            updateBookingInfo["log_by_id"] = this.state.user.data._id;
            var payload = {
                additional_reps: updateBookingInfo["additional_reps"],
                assigned_rep: updateBookingInfo["assigned_rep"],
                id: updateBookingInfo["id"],
                log_by_id:this.state.user.data._id,
                //status: "repsUpdated"
            };
            await this.props.dispatch(bookingActions.repsUpdate(payload));
            this.setState({errorMsg:{ assigned_rep: "",additional_reps: ""}});
            this.props.repsUpdateModalClose(true);
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if(window.location.pathname == "/admin/bookings/close"){
            this.setState({
                errorMsg:{ assigned_rep: "",additional_reps: ""}
            });
        }else{
            this.setState({
                errorMsg:{ assigned_rep: "",additional_reps: ""},
                selectedAdditionalRep : [],
                selectedRep : []
            });
        }

        if (nextProps.bookingInfo !== undefined) {
            this.setBookingData(nextProps.bookingInfo);
        }

        if (nextProps.repsList !== undefined) {
            this.showRepsList(nextProps.repsList);
        }
    }
    
    render() {
        const { error } = this.props;
        const { errorMsg } = this.state;
        return (
            <div>
                <Modal
                    size="ml"
                    backdrop="static"
                    isOpen={this.props.isRepsUpdateModal}
                    toggle={() => [this.props.repsUpdateModalClose(false),this.setState({errorMsg:{ assigned_rep: "",additional_reps: ""},selectedRep:[],selectedAdditionalRep:[]})]}>
                    <ModalHeader
                        className=""
                        toggle={() => [this.props.repsUpdateModalClose(false),this.setState({errorMsg:{ assigned_rep: "",additional_reps: ""},selectedRep:[],selectedAdditionalRep:[]})]}>
                        Reps Update
                    </ModalHeader>
                    <ModalBody>
                        <div>

                            <div className="d-card mt-4">
                                <div className="form-group">
                                    <label>
                                        Assigned Rep{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <div className="position_up">
                                    <Select
                                            value={this.state.selectedRep}
                                            onChange={this.setSelectedOptionRep}
                                            options={this.state.reps}
                                        />
                                        {errorMsg ? (
                                            <span className="text-danger">
                                                {error?.assigned_rep
                                                    ? error?.assigned_rep
                                                    : errorMsg?.assigned_rep ? errorMsg?.assigned_rep : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Additional Reps{" "}  
                                    {/* <span className="text-danger">*</span> */}
                                    </label>
                                    <Select
                                        value={this.state.selectedAdditionalRep}
                                        onChange={
                                            this.setSelectedOptionAdditionalRep
                                        }
                                        options={this.state.additionalReps}
                                        isMulti={true}
                                    />

                                        {/* {errorMsg ? (
                                            <span className="text-danger">
                                                {error?.additional_reps
                                                    ? error?.additional_reps
                                                    : errorMsg?.additional_reps ? errorMsg?.additional_reps : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )} */}

                                </div>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                onClick={() => [this.props.repsUpdateModalClose(false),this.setState({errorMsg:{ assigned_rep: "",additional_reps: ""},selectedRep:[],selectedAdditionalRep:[]})]}
                                className="btn btn-secondary mr-2"
                                data-dismiss="modal">
                                Cancel
                            </button>

                            <button
                                className="btn btn-primary"
                                onClick={() => this.handleSubmit()}
                            >
                                Update
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        bookingInfo,
        repsList,
        error,
        redirect,
        loading,
    } = state.rootReducer.bookings;

    return {
        bookingInfo,
        repsList,
        error,
        redirect,
        loading
    };
}

export default connect(mapStateToProps)(RepsUpdateModal);

