import { authHeader } from "../helpers";
import axios from "axios";

export const loanChargeService = {
  getLoanScoreByHospitalKit
};
export const getRecordByKitId = async (id,search) => {
  try {
    const response = await axios.post(process.env.REACT_APP_APIURL + "kits/getRecordByKitId",
    {
        "id": id,
        "search":search
    });
    console.log("getRecordByKitIdResponse",response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data by kit ID:", error);
    throw error;
  }
};

  
export const updateKitChargeOnKitHospitals = async (kitId, hospitalId, loanCharge,checkLoanCharge) => {
  const params = {
    "kitId": kitId,
    "hospitalId": hospitalId,
    "loan_charge": loanCharge,
    "check_loan_charge": checkLoanCharge
  };
        const response = await axios.post(process.env.REACT_APP_APIURL +"kits/updateLoanChargeOnKitHospitals", params);
        return response.data;
};


export const updateLoanChargeOnKitOfAllHospitals = (data) => {
  console.log("updateLoanChargeOnKitOfAllHospitals", data);
  const apiUrl = process.env.REACT_APP_APIURL + "kits/updateLoanChargeOnKitOfAllHospitals";
console.log(
  "updateAllData", data
);
  return axios.post(apiUrl, data)
    .then((response) => {
      console.log("updateAllData",response.data);
      return response.data; 
    })
    .catch((error) => {
      throw error; 
    });
};

function getLoanScoreByHospitalKit(info) {
    const requestOptions = {
        method: "POST",
        headers:{...authHeader(),"Content-Type":"application/json"},
        body: JSON.stringify(info),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "kits/getLoanChargeUsingKitAndHospitalId",
        requestOptions,
    ).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}