import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    Button,
   
} from "reactstrap";
import Select from "react-select";
import dateFormat from "dateformat";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import {
    repsBookingActions,
    kitActions,
    setActions,
} from "../../../../actions";
import DatePicker from "react-datepicker";
import { bookingService } from "../../../../services/booking.services";

// const operations =  [{ value: "Appendectomy", label: "Appendectomy" },
//     { value: "C-Section", label: "C-Section" },
//     { value: "Heart Bypass", label: "Heart Bypass" },
// ];

const deliverySlots = [
    { value: "Pre 9AM", label: "Pre 9AM" },
    { value: "Pre 10AM", label: "Pre 10AM" },
    { value: "Pre 12", label: "Pre 12" },
    { value: "Same Day", label: "Same Day" },
];

const operationSlots = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
    { value: "TBD", label: "TBD" },
];

const dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            kitSetErrorFlag: 0,
            bookingInfo: {
                booking_type: "loan",
                shipping_charge: 100,
                additional_notes: "",
                assigned_rep: "",
                additional_reps: [],
                delivery_date: "",
                delivery_date_slot: "Pre 12",
                operation_date: "",
                operation_date_slot: "AM",
                collection_date: "",
                return_date: "",
                kitData: [],
                kitSetData: [],
                log_status: "pending",
                log_by_id: "",
                other_opration: "",
                created_by: "",
                event_name:"",
                name_of_location: "",
                address: "",
                postcode: "",
                phone_number: "",
                delivery_address:"",
                collection_address:"",
                contact_detail:"",
                pickup_location_checkbox: false,
                pickup_location_text: "",
            },
           selectedDeliverySlot: [{ value: "Pre 12", label: "Pre 12" }],
            selectedOperationSlots: [{ value: "AM", label: "AM" }],
            selectedRep: [],
            selectedAdditionalRep: [],
            reps: [],
            additionalReps: [],
            repsDetails: [],
            searchReps: "",
            searchOps: "",
            kits: [],
            kitListFlag: 0,
            selectedKit: [],
            kitWithKitSet: [],
            kitSetDetailModal: false,
            kitDetails: [],
            kitDetailsClickFlag: 0,
            bookingType:"loan",
            // bookingType: [
            //     { value: "loan", label: "Loan" },
            //     { value: "consignment", label: "Consignment" },
            // ],
            // selectedBookingType: [{ value: "loan", label: "Loan" }],
          quantityError: false,
            suggestedKits: [],
        };

        this.getList = this.getList.bind(this);
       this.handleBookingInfo = this.handleBookingInfo.bind(this);
        this.setSelectedOptionDeliverySlot =
            this.setSelectedOptionDeliverySlot.bind(this);
       this.setSelectedOptionRep = this.setSelectedOptionRep.bind(this);
        this.setSelectedOptionAdditionalRep =
            this.setSelectedOptionAdditionalRep.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setSelectedOptionKit = this.setSelectedOptionKit.bind(this);
        this.handleAddKit = this.handleAddKit.bind(this);
        this.handelAddRemovSet = this.handelAddRemovSet.bind(this);
        this.handleHireCharge = this.handleHireCharge.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.kitSetDetailModalClose = this.kitSetDetailModalClose.bind(this);
        this.setSelectedOptionOperationSlots =this.setSelectedOptionOperationSlots.bind(this);
        // this.setSelectedOptionBookingType =
        //     this.setSelectedOptionBookingType.bind(this);
        this.checkNextAvalability = this.checkNextAvalability.bind(this);
       this.setSugestedSelectedOptionKit =
            this.setSugestedSelectedOptionKit.bind(this);
          }

    getList() {
        this.props.dispatch(
            repsBookingActions.getRepsList({ search: this.state.searchReps }),
        );
        this.props.dispatch(kitActions.kitListDropdownForReps({ search: "" }));
    }

    showRepsList(repsList) {
        var tempRepsList = [];
        repsList.map(item => {
            var option = {
                value: item._id,
                label: item.first_name + " " + item.last_name,
            };
            tempRepsList.push(option);
        });
        this.setState({
            repsDetails: repsList,
            reps: tempRepsList,
            additionalReps: tempRepsList,
        });
    }

    // setSelectedOptionBookingType(e) {
    //     var selectedBookingType = [{ value: e.value, label: e.label }];
    //     console.log(selectedBookingType);
    //     this.setState({
    //         selectedBookingType: selectedBookingType,
    //         bookingInfo: {
    //             ...this.state.bookingInfo,
    //             booking_type: e.value,
    //         },
    //     });
    // }

    setSelectedOptionRep(e) {
        var selectedRep = [{ value: e.value, label: e.label }];
        this.setState({
            selectedRep: selectedRep,
            bookingInfo: {
                ...this.state.bookingInfo,
                assigned_rep: e.value,
            },
        });
    }

    setSelectedOptionAdditionalRep = selectedAdditionalRep => {
        var tempAdditionalReps = [];
        selectedAdditionalRep.map(item => {
            tempAdditionalReps.push({ id: item.value });
        });
        this.setState(
            {
                selectedAdditionalRep: selectedAdditionalRep,
                bookingInfo: {
                    ...this.state.bookingInfo,
                    additional_reps: tempAdditionalReps,
                },
            },
            () => {
                console.log(this.state.bookingInfo.additional_reps);
            },
        );
    };

    setSelectedOptionKit(e) {
        var selectedKit = [{ value: e.value, label: e.label }];
        this.setState({
            selectedKit: selectedKit,
        });
    }

    setSugestedSelectedOptionKit(e) {
        var selectedKit = [{ value: e.value, label: e.label }];
        this.setState(
            {
                selectedKit: selectedKit,
            },
            () => {
                this.handleAddKit();
            },
        );
    }

    showKitsList(kitList) {
        var tempKitList = [];
        kitList.map(item => {
            var option = { value: item._id, label: item.name };
            tempKitList.push(option);
        });
        this.setState({
            kits: tempKitList,
            kitListFlag: 1,
        });
    }

    handleAddKit() {
        if (this.state.selectedKit.length > 0) {
            this.props.dispatch(
                repsBookingActions.getKitSetByKit({
                    kitId: this.state.selectedKit[0].value,
                }),
            );
        }
    }

    showKitsSetList(data) {
        const { kitWithKitSet } = this.state;
        let newKitWithKitSet = [];
        var subKitSet = [];
        data[0]["kitsets"].map(kset => {
            subKitSet.push({
                bookingKitSets: data[0]["bookingKitSets"],
                set_name: kset.set_name,
                _id: kset._id,
                hire_charge: 695,
                added: 0,
                availability: [],
            });
        });

        newKitWithKitSet.push({
            kitDocument: data[0].kitDocument,
            name: data[0].name,
            _id: data[0]._id,
            hire_charge: 695,
            quantity: "",
            sets: subKitSet,
        });

        this.setState({
            suggestedKits: data[0].suggestedKits,
        });

        var kits = this.state.kits;
        var slectedVal = data[0]._id;
        kits = kits.filter(rs => rs.value !== slectedVal);

        let newArr = [...newKitWithKitSet, ...kitWithKitSet];

        this.setState({
            kitWithKitSet: newArr,
            selectedKit: [],
            kits: kits,
        });
    }

    handelRemovKit(kiIndex) {
        const { kitWithKitSet, kits } = this.state;
        const kitValue = kitWithKitSet[kiIndex]._id;
        const kitLable = kitWithKitSet[kiIndex].name;
        kitWithKitSet.splice(kiIndex, 1);

        kits.push({ value: kitValue, label: kitLable });

        this.setState({
            kitWithKitSet,
            kits,
        });
    }

    handelAddRemovSet(kiIndex, setIndex, flag) {
        const { kitWithKitSet } = this.state;
        kitWithKitSet[kiIndex].sets[setIndex].added = flag;

        this.setState({
            kitWithKitSet,
        });
    }

    handleHireCharge(kiIndex, setIndex, val) {
        const { kitWithKitSet } = this.state;
        if (val != "" && val < 0) {
            val = "";
        }
        kitWithKitSet[kiIndex].sets[setIndex].hire_charge = val;

        this.setState({
            kitWithKitSet,
        });
    }

    handleKitHireCharge(index, val) {
        console.log(index, val);
        const { kitWithKitSet } = this.state;
        if (val != "" && val < 0) {
            val = "";
        }

        kitWithKitSet[index].hire_charge = val;

        this.setState({
            kitWithKitSet,
        });
    }

    handleKitQuantity(index, val) {
        console.log(index, val);

        const { kitWithKitSet } = this.state;
        if (val != "" && val < 0) {
            val = "";
        }

        kitWithKitSet[index].quantity = val;

        this.setState({
            kitWithKitSet,
            quantityError: false,
        });
    }

    handleBookingInfo(name, value) {
        const { bookingInfo } = this.state;
        if (
            name == "delivery_date" ||
            name == "collection_date" ||
            name == "operation_date"
        ) {
            let date = dateFormat(value, "yyyy-mm-dd");
            bookingService
                .checkHolidayDate({ date: date })
                .then(response => {
                    console.log("checkholiday", response);
                    if (response.data && response.data.length !== 0) {
                        if (name === "delivery_date") {
                            this.setState(prevState => ({
                                deliveryDateError:
                                    "Delivery Date falls on a holiday.",
                                operationDateError:
                                    prevState.operationDateError,
                                collectionDateError:
                                    prevState.collectionDateError,
                            }));
                        } else if (name === "collection_date") {
                            this.setState(prevState => ({
                                deliveryDateError: prevState.deliveryDateError,
                                collectionDateError:
                                    "Collection Date falls on a holiday.",
                                operationDateError:
                                    prevState.operationDateError,
                            }));
                        } else if (name === "operation_date") {
                            this.setState(prevState => ({
                                deliveryDateError: prevState.deliveryDateError,
                                collectionDateError:
                                    prevState.collectionDateError,
                                operationDateError:
                                    "Meeting Date falls on a holiday.",
                            }));
                        }
                    } else {
                        this.setState(prevState => ({
                            deliveryDateError:
                                name === "delivery_date"
                                    ? ""
                                    : prevState.deliveryDateError,
                            operationDateError:
                                name === "operation_date"
                                    ? ""
                                    : prevState.operationDateError,
                            collectionDateError:
                                name === "collection_date"
                                    ? ""
                                    : prevState.collectionDateError,
                        }));
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
        if (name == "event_name" ) {
            bookingInfo["event_name"] = value;
        }
        if(name=="name_of_location"){
            bookingInfo["name_of_location"] = value;
        }
        if(name=="address"){
            bookingInfo["address"] = value;
        }
        if(name=="postcode"){
            bookingInfo["postcode"] = value;
        }
        if(name=="phone_number"){
            bookingInfo["phone_number"] = value;
        }
         if(name=="delivery_address"){
            bookingInfo["delivery_address"] = value;
        }
        if(name=="collection_address"){
            bookingInfo["collection_address"] = value;
        }
        if(name=="contact_detail"){
            bookingInfo["contact_detail"] = value;
        }
        if (name == "shipping_charge" && value != "" && value < 0) {
            value = "";
        }
        bookingInfo[name] = value;

        this.setState(
            {
                bookingInfo,
            },
            () => {
                console.log(this.state.bookingInfo);
            },
        );
    }


    setSelectedOptionDeliverySlot(e) {
        var selectedDeliverySlot = [{ value: e.value, label: e.label }];
        this.setState({
            selectedDeliverySlot: selectedDeliverySlot,
            bookingInfo: {
                ...this.state.bookingInfo,
                delivery_date_slot: e.value,
            },
        });
    }

    setSelectedOptionOperationSlots(e) {
        var selectedOperationSlots = [{ value: e.value, label: e.label }];
        this.setState({
            selectedOperationSlots: selectedOperationSlots,
            bookingInfo: {
                ...this.state.bookingInfo,
                operation_date_slot: e.value,
            },
        });
    }

    handleSubmit() {
        const {
            bookingInfo,
            kitWithKitSet,
        } = this.state;
        var kitError = 0;
        var kitData = [];
        var kitSetData = [];
        let kitSetFlag = 0;
        var kitSetErrorFlag = 0;
        this.setState({ quantityError: true });

         kitWithKitSet.map(item => {
            kitData.push({
                id: item._id,
                hire_charge: item.hire_charge,
                quantity: item.quantity,
            });
            kitSetFlag = 1;
            kitError = 1;
            item.sets
                .filter(rs => rs.added === 1)
                .map(sub => {
                    kitSetData.push({
                        id: sub._id,
                        hire_charge: sub.hire_charge,
                        kitId: item._id,
                    });
                });
        });

        bookingInfo["assigned_rep"] = this.state.user.data._id;

        bookingInfo["return_date"] = "";

        if (bookingInfo["collection_date"] != "") {
            var return_date = new Date(bookingInfo["collection_date"]);
            return_date = return_date.setDate(return_date.getDate() + 1);
            bookingInfo["return_date"] = dateFormat(return_date, "yyyy-mm-dd");
        }
        if (bookingInfo["booking_type"] == "consignment") {
            bookingInfo["operation_date"] = bookingInfo["collection_date"];
        }

        if( bookingInfo["pickup_location_checkbox"] == false){
            bookingInfo["pickup_location_text"] = "";
         }

        bookingInfo["kitData"] = kitData;
        bookingInfo["kitSetData"] = kitSetData;

        console.log(kitSetFlag);

        if (
            bookingInfo["name_of_location"] != "" &&
            bookingInfo["address"] != "" &&
            bookingInfo["postcode"] != "" &&
            bookingInfo["phone_number"] != "" &&
              bookingInfo["delivery_address"] != "" &&
            bookingInfo["event_name"] != "" &&
            bookingInfo["collection_address"] != "" &&
            bookingInfo["contact_detail"] != "" &&
            bookingInfo["delivery_date"] != "" &&
            bookingInfo["operation_date"] != "" &&
            bookingInfo["collection_date"] != "" &&
            bookingInfo["kitData"].length != 0 &&
            bookingInfo["kitSetData"].length != 0
        ) {
            kitSetErrorFlag = 1;
            this.setState({
                kitSetErrorFlag: 0,
            });
        } else {
            kitSetErrorFlag = 0;
            if (kitError == 1) {
                kitSetErrorFlag = 1;
                this.setState({
                    kitSetErrorFlag: 0,
                });
            } else {
                this.setState({
                    kitSetErrorFlag: 1,
                });
            }
        }

        bookingInfo["log_by_id"] = this.state.user.data._id;

        console.log("bookingInfo", bookingInfo);
        if (kitSetErrorFlag == 1) {
            var datass = {
                additional_notes: bookingInfo["additional_notes"],
                additional_reps: bookingInfo["additional_reps"],
                assigned_rep: bookingInfo["assigned_rep"],
                booking_type: bookingInfo["booking_type"],
                collection_date: "",
                delivery_date: "",
                delivery_date_slot: bookingInfo["delivery_date_slot"],
                kitData: bookingInfo["kitData"],
                kitSetData: bookingInfo["kitSetData"],
                log_by_id: bookingInfo["log_by_id"],
                log_status: bookingInfo["log_status"],
                operation_date: "",
                operation_date_slot: bookingInfo["operation_date_slot"],
                other_opration: bookingInfo["other_opration"],
                return_date: bookingInfo["return_date"],
                shipping_charge: bookingInfo["shipping_charge"],
                created_by: this.state.user.data._id,
                event_name:bookingInfo["event_name"],
                name_of_location:bookingInfo["name_of_location"],
                address:bookingInfo["address"],
                postcode:bookingInfo["postcode"],
                phone_number:bookingInfo["phone_number"],
                 delivery_address:bookingInfo["delivery_address"],
                collection_address:bookingInfo["collection_address"],
                contact_detail:bookingInfo["contact_detail"],
                pickup_location_checkbox: bookingInfo["pickup_location_checkbox"],
                pickup_location_text: bookingInfo["pickup_location_text"],

            };

            if (bookingInfo["collection_date"] != "") {
                datass["collection_date"] = dateFormat(
                    bookingInfo["collection_date"],
                    "yyyy-mm-dd",
                );
            }
            if (bookingInfo["delivery_date"] != "") {
                datass["delivery_date"] = dateFormat(
                    bookingInfo["delivery_date"],
                    "yyyy-mm-dd",
                );
            }
            if (bookingInfo["operation_date"] != "") {
                datass["operation_date"] = dateFormat(
                    bookingInfo["operation_date"],
                    "yyyy-mm-dd",
                );
            }
            this.props.dispatch(repsBookingActions.add(datass));
        }
    }

    kitSetDetailModalClose() {
        this.setState({
            kitSetDetailModal: false,
            kitSetData: [],
        });
    }

    showKitDetails(kitDetails) {
        if (this.state.kitDetailsClickFlag == 1) {
            this.setState({
                kitDetails: kitDetails,
                kitSetDetailModal: true,
                kitDetailsClickFlag: 0,
            });
        }
    }

    handleKitDetails(id) {
        this.setState({
            kitDetailsClickFlag: 1,
        });

        this.props.dispatch(setActions.getById(id));
    }

    checkAvalability(bookingKitSets, setId, index, ind) {
        const { bookingInfo } = this.state;
        console.log(setId, index, ind);
        var htm = [];

        var currentDate = bookingInfo.delivery_date;
        var endDate = bookingInfo.collection_date;
        var falgForHtm = 0;
        var date = "";
        var separator = "-";
        var fulldate = "";
        var nextDate = [];
        var holiday = "No";
        if (currentDate != "" && endDate != "") {
            currentDate = new Date(currentDate);
            endDate = new Date(endDate);
            var dates = [];
            while (currentDate <= endDate) {
                date = `${currentDate.getFullYear()}${separator}${
                    currentDate.getMonth() + 1 < 10
                        ? `0${currentDate.getMonth() + 1}`
                        : `${currentDate.getMonth() + 1}`
                }${separator}${
                    currentDate.getDate() < 10
                        ? `0${currentDate.getDate()}`
                        : `${currentDate.getDate()}`
                }`;

                dates.push({ fulldate: date });
                currentDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 1, // Will increase month if over range
                );
            }

            if (bookingKitSets.length == 0) {
                htm = <span className="capsule green-dim">Available</span>;
                endDate = new Date(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate() + 7,
                );

                while (currentDate <= endDate) {
                    holiday = "No";
                    if (
                        currentDate.getDay() == 6 ||
                        currentDate.getDay() == 0
                    ) {
                        holiday = "holiday";
                    }

                    nextDate.push({
                        date:
                            currentDate.getDate() +
                            " " +
                            dayName[currentDate.getDay()],
                        holiday: holiday,
                        status: "",
                    });

                    currentDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() + 1, // Will increase month if over range
                    );
                }
            } else {
                dates &&
                    dates.map(diffdate => {
                        fulldate = diffdate.fulldate;
                        bookingKitSets
                            .filter(rs => rs.setId == setId)
                            .map(bookingSt => {
                                if (
                                    bookingSt.bookings &&
                                    bookingSt.bookings.filter(
                                        rs =>
                                            rs.delivery_date <= fulldate &&
                                            rs.collection_date >= fulldate,
                                    ).length
                                ) {
                                    falgForHtm += 1;
                                }
                            });
                    });

                if (falgForHtm > 0) {
                    htm = (
                        <span className="capsule red-dim">Not Available</span>
                    );
                }

                if (falgForHtm == 0) {
                    htm = <span className="capsule green-dim">Available</span>;
                }

                endDate = new Date(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate() + 7,
                );

                while (currentDate <= endDate) {
                    holiday = "No";
                    falgForHtm = 0;
                    if (
                        currentDate.getDay() == 6 ||
                        currentDate.getDay() == 0
                    ) {
                        holiday = "holiday";
                    }

                    fulldate = `${currentDate.getFullYear()}${separator}${
                        currentDate.getMonth() + 1 < 10
                            ? `0${currentDate.getMonth() + 1}`
                            : `${currentDate.getMonth() + 1}`
                    }${separator}${
                        currentDate.getDate() < 10
                            ? `0${currentDate.getDate()}`
                            : `${currentDate.getDate()}`
                    }`;

                    bookingKitSets
                        .filter(rs => rs.setId == setId)
                        .map(bookingSt => {
                            if (
                                bookingSt.bookings &&
                                bookingSt.bookings.filter(
                                    rs =>
                                        rs.delivery_date <= fulldate &&
                                        rs.collection_date >= fulldate,
                                ).length
                            ) {
                                falgForHtm += 1;
                            }
                        });

                    if (falgForHtm > 0) {
                        nextDate.push({
                            date:
                                currentDate.getDate() +
                                " " +
                                dayName[currentDate.getDay()],
                            holiday: "na",
                            status: " Not Available",
                        });
                    }

                    if (falgForHtm == 0) {
                        nextDate.push({
                            date:
                                currentDate.getDate() +
                                " " +
                                dayName[currentDate.getDay()],
                            holiday: holiday,
                            status: "Available",
                        });
                    }
                    currentDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() + 1, // Will increase month if over range
                    );
                }
            }
        } else {
            htm = <span className="capsule green-dim">Available</span>;
        }

        return (
            <div>
                {htm}
                <div key="2">
                    <div
                        className="table-chart"
                        style={{ width: "auto", display: "inline-block" }}>
                        <table className="mt-2 main-table">
                            <thead>
                                <tr style={{ borderTop: "1px solid #DDD" }}>
                                    {nextDate &&
                                        nextDate.map((next, indxx) => (
                                            <th
                                                key={indxx}
                                                in={indxx}
                                                className={
                                                    (indxx == 0
                                                        ? " first_col "
                                                        : " ") + next.holiday
                                                }
                                                style={{ width: "30px" }}>
                                                {next.date}
                                            </th>
                                        ))}
                                    {/*<th className="first_col" style={{width:"30px"}}>24 Tue</th>
                                    <th>25 Tue</th>
                                    <th className="holiday">26 Tue</th>
                                    <th className="tdy">27 Tue</th>
                                    <th>28 Tue</th>
                                    <th>29 Tue</th>
                                    <th>30 Tue</th>*/}
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    checkNextAvalability() {
        var test = [];
        test.push({ date: 5 });

        return test;
    }

    componentDidMount() {
        this.getList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("boss", nextProps);

        if (nextProps.repsList !== undefined) {
            this.showRepsList(nextProps.repsList);
        }

        if (
            nextProps.kitListDropdown !== undefined &&
            this.state.kitListFlag == 0
        ) {
            this.showKitsList(nextProps.kitListDropdown);
        }

        if (nextProps.kitSetList !== undefined) {
            this.showKitsSetList(nextProps.kitSetList);
        }

        if (nextProps.editSet == true) {
            this.showKitDetails(nextProps.set);
        }
  }

    render() {
        const { redirect } = this.props;

        if (redirect && this.state.bookingInfo.booking_type != "consignment") {
            return <Redirect to="/reps/open-booking" />;
        }
        if (redirect && this.state.bookingInfo.booking_type == "consignment") {
            return <Redirect to="/reps/consignment-booking" />;
        }
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "reps") {
            return <Redirect to="/" />;
        }
        const { error } = this.props;
        return (
            <div>
                <Navbar activePage="bookings" />
                <div className="container">
                    <h1 className="date-scroll pt-4">
                        <Link to="/reps/open-booking">
                            <i className="fa fa-long-arrow-left smbtn"></i>{" "}
                            <strong>Back</strong>
                        </Link>
                    </h1>
                    <div className="row pt-4 mobile_nocard">
                        <div className="col-md-8">
                            <div className="d-card">
                                <h4>Basic Details</h4>
                                <div className="form-group">
                                    <label>Event Name</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.event_name}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "event_name",
                                                e.target.value,
                                            )
                                        }
                                    />
                            
                                </div>

                                {/* <div className="form-group">
                                    <label>Event Address</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.event_address}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "event_address",
                                                e.target.value,
                                            )
                                        }
                                    />
                                  </div> */}
  <label>Event Address</label>
                                <div className="row purchase_date">
                                    <div
                                        className={
                                            this.state.bookingInfo
                                                .booking_type != "consignment"
                                                ? "col-md-6"
                                                : "col-md-12"
                                        }>
                                        <div className="form-group">
                                            <label className="opaq">
                                                Name of Location{" "}
                                                {/* <span className="text-danger">
                                                    *
                                                </span> */}
                                            </label>
                                            {/* <div className="inputdata">
                                                <DatePicker
                                                    selected={
                                                        this.state.bookingInfo
                                                            .delivery_date
                                                    }
                                                    onChange={date =>
                                                        this.handleBookingInfo(
                                                            "delivery_date",
                                                            date,
                                                        )
                                                    }
                                                    dateFormat="dd-MM-yyyy EEE"
                                                    className="small"
                                                    id="datepicker"
                                                    autoComplete="off"
                                                />
                                            </div> */}
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={this.state.bookingInfo.name_of_location}

                                                onChange={e =>
                                                    this.handleBookingInfo(
                                                        "name_of_location",
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            
                                            {/* {this.state.bookingInfo.delivery_date!="" &&  ((new Date(this.state.bookingInfo.delivery_date).getDate() < new Date().getDate()) || ((new Date(this.state.bookingInfo.delivery_date).getMonth()+1) < (new Date().getMonth()+1)) || (new Date(this.state.bookingInfo.delivery_date).getFullYear() < new Date().getFullYear())) && 
                                                <div><span className="text-danger">The dispatch date you booked is in the past</span></div>
                                            } */}
                                        </div>
                                    </div>
                                    {this.state.bookingInfo.booking_type !=
                                        "consignment" && (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="opaq">
                                                        Address{" "}
                                                        {/* <span className="text-danger">
                                                            *
                                                        </span> */}
                                                    </label>
                                                    {/* <div className="mt-2 position_up">
                                                        <Select
                                                            defaultValue={
                                                                this.state
                                                                    .selectedDeliverySlot
                                                            }
                                                            onChange={
                                                                this
                                                                    .setSelectedOptionDeliverySlot
                                                            }
                                                            options={deliverySlots}
                                                            className="small"
                                                        />
                                                    </div> */}
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.bookingInfo.address}

                                                        onChange={e =>
                                                            this.handleBookingInfo(
                                                                "address",
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                    {this.state.bookingInfo.booking_type !=
                                        "consignment" && (
                                            <React.Fragment>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="opaq">
                                                            Postcode{" "}
                                                            {/* <span className="text-danger">
                                                                *
                                                            </span> */}
                                                        </label>
                                                        {/* <DatePicker
                                                            selected={
                                                                this.state
                                                                    .bookingInfo
                                                                    .operation_date
                                                            }
                                                            onChange={date =>
                                                                this.handleBookingInfo(
                                                                    "operation_date",
                                                                    date,
                                                                )
                                                            }
                                                            dateFormat="dd-MM-yyyy EEE"
                                                            id="datepicker2"
                                                            className="small"
                                                            autoComplete="off"
                                                        /> */}
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.bookingInfo.postcode}

                                                            onChange={e =>
                                                                this.handleBookingInfo(
                                                                    "postcode",
                                                                    e.target.value,
                                                                )
                                                            }
                                                        />
                                                        {this.state
                                                            .operationDateError && (
                                                                <div className="error-message">
                                                                    <span className="text-danger">
                                                                        {
                                                                            this.state
                                                                                .operationDateError
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )}
                                                        {error ? (
                                                            <span className="text-danger">
                                                                {error.operation_date
                                                                    ? error.operation_date
                                                                    : ""}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group" >
                                                        <label className="opaq">
                                                            Phone Number{" "}
                                                            {/* <span className="text-danger">
                                                                *
                                                            </span> */}
                                                        </label>
                                                        {/* <div className="mt-2">
                                                            <Select
                                                                defaultValue={
                                                                    this.state
                                                                        .selectedOperationSlots
                                                                }
                                                                onChange={
                                                                    this
                                                                        .setSelectedOptionOperationSlots
                                                                }
                                                                options={
                                                                    operationSlots
                                                                }
                                                                className="small"
                                                            />
                                                        </div> */}
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.bookingInfo.phone_number}

                                                            onChange={e =>
                                                                this.handleBookingInfo(
                                                                    "phone_number",
                                                                    e.target.value,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                         <div className="form-group mt-3">
                                            <span className="">
                                                <Input
                                                    type="checkbox"
                                                    className="ml-0 form-check-input"
                                                    value={
                                                        this.state.bookingInfo.pickup_location_checkbox
                                                    }
                                                    checked={
                                                        this.state.bookingInfo.pickup_location_checkbox
                                                    }
                                                    onChange={e =>
                                                        this.handleBookingInfo(
                                                            "pickup_location_checkbox",
                                                            e.target.checked,
                                                        )
                                                    }
                                                />
                                            </span>
                                            <label className="ml-4 ">
                                            Is Pickup Location And Contact Details Different
                                            </label>
                                            {this.state.bookingInfo.pickup_location_checkbox?
                                            <Input
                                            type="text"
                                            className="form-control"
                                           onChange={e =>
                                            this.handleBookingInfo(
                                                "pickup_location_text",
                                                e.target.value,
                                            )
                                        }
                                        
                                        />
                                        :""
                                            }
                                </div>
</div>
                                            </React.Fragment>
                                        )}
                                </div>

                                <div className="form-group">
                                    <label>Delivery Address</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.delivery_address}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "delivery_address",
                                                e.target.value,
                                            )
                                        }
                                    />
                                    </div>
                                <div className="form-group">
                                    <label>Collection Address</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.collection_address}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "collection_address",
                                                e.target.value,
                                            )
                                        }
                                    />
                                   </div>
                                <div className="form-group">
                                    <label>Contact Detail</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.contact_detail}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "contact_detail",
                                                e.target.value,
                                            )
                                        }
                                    />
                                     </div>
                            </div>
                            <div className="d-card my-4">
                                <h4>Meeting Delivery/ Collection</h4>
                                <div className="row purchase_date">
                                    <div
                                        className={
                                            this.state.bookingInfo
                                                .booking_type != "consignment"
                                                ? "col-md-6"
                                                : "col-md-12"
                                        }>
                                        <div className="form-group">
                                            <label>
                                                Delivery Date{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="inputdata">
                                                <DatePicker
                                                    selected={
                                                        this.state.bookingInfo
                                                            .delivery_date
                                                    }
                                                    onChange={date =>
                                                        this.handleBookingInfo(
                                                            "delivery_date",
                                                            date,
                                                        )
                                                    }
                                                    dateFormat="dd-MM-yyyy EEE"
                                                    className="small"
                                                    id="datepicker"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            {this.state.deliveryDateError && (
                                                <div className="error-message">
                                                    <span className="text-danger">
                                                        {
                                                            this.state
                                                                .deliveryDateError
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {error ? (
                                                <span className="text-danger">
                                                    {error.delivery_date
                                                        ? error.delivery_date
                                                        : ""}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.bookingInfo
                                                .delivery_date != "" &&
                                                (new Date(
                                                    this.state.bookingInfo.delivery_date,
                                                ).getDay() == 6 ||
                                                    new Date(
                                                        this.state.bookingInfo.delivery_date,
                                                    ).getDay() == 0) && (
                                                    <div>
                                                        <span className="text-danger">
                                                            Selected date is a
                                                            weekend/holiday but
                                                            you can still make a
                                                            booking
                                                        </span>
                                                    </div>
                                                )}
                                            {/* {this.state.bookingInfo.delivery_date!="" &&  ((new Date(this.state.bookingInfo.delivery_date).getDate() < new Date().getDate()) || ((new Date(this.state.bookingInfo.delivery_date).getMonth()+1) < (new Date().getMonth()+1)) || (new Date(this.state.bookingInfo.delivery_date).getFullYear() < new Date().getFullYear())) && 
                                                <div><span className="text-danger">The dispatch date you booked is in the past</span></div>
                                            } */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label></label>
                                            <div className="mt-2 position_up">
                                                <Select
                                                    defaultValue={
                                                        this.state
                                                            .selectedDeliverySlot
                                                    }
                                                    onChange={
                                                        this
                                                            .setSelectedOptionDeliverySlot
                                                    }
                                                    options={deliverySlots}
                                                    className="small"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.bookingInfo.booking_type !=
                                        "consignment" && (
                                        <React.Fragment>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        Meeting Date{" "}
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="inputdata">
                                                        <DatePicker
                                                            selected={
                                                                this.state
                                                                    .bookingInfo
                                                                    .operation_date
                                                            }
                                                            onChange={date =>
                                                                this.handleBookingInfo(
                                                                    "operation_date",
                                                                    date,
                                                                )
                                                            }
                                                            dateFormat="dd-MM-yyyy EEE"
                                                            id="datepicker2"
                                                            className="small"
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    {this.state
                                                        .operationDateError && (
                                                        <div className="error-message">
                                                            <span className="text-danger">
                                                                {
                                                                    this.state
                                                                        .operationDateError
                                                                }
                                                            </span>
                                                        </div>
                                                    )}
                                                    {error ? (
                                                        <span className="text-danger">
                                                            {error.operation_date
                                                                ? error.operation_date
                                                                : ""}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label></label>
                                                    <div className="mt-2">
                                                        <Select
                                                            defaultValue={
                                                                this.state
                                                                    .selectedOperationSlots
                                                            }
                                                            onChange={
                                                                this
                                                                    .setSelectedOptionOperationSlots
                                                            }
                                                            options={
                                                                operationSlots
                                                            }
                                                            className="small"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>
                                                Collection Date{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            {/* <Input 
                                                type="date" 
                                                value={this.state.bookingInfo.collection_date}
                                                onChange = {(e) => this.handleBookingInfo("collection_date", e.target.value)}
                                                className="small"
                                            /> */}
                                            <DatePicker
                                                selected={
                                                    this.state.bookingInfo
                                                        .collection_date
                                                }
                                                onChange={date =>
                                                    this.handleBookingInfo(
                                                        "collection_date",
                                                        date,
                                                    )
                                                }
                                                dateFormat="dd-MM-yyyy EEE"
                                                id="datepicker3"
                                                className="small"
                                                autoComplete="off"
                                            />
                                            {this.state.collectionDateError && (
                                                <div className="error-message">
                                                    <span className="text-danger">
                                                        {
                                                            this.state
                                                                .collectionDateError
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {error ? (
                                                <span className="text-danger">
                                                    {error.collection_date
                                                        ? error.collection_date
                                                        : ""}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.bookingInfo
                                                .collection_date != "" &&
                                                (new Date(
                                                    this.state.bookingInfo.collection_date,
                                                ).getDay() == 6 ||
                                                    new Date(
                                                        this.state.bookingInfo.collection_date,
                                                    ).getDay() == 0) && (
                                                    <div>
                                                        <span className="text-danger mt-2">
                                                            Selected date is a
                                                            weekend/holiday but
                                                            you can still make a
                                                            booking
                                                        </span>
                                                    </div>
                                                )}

                                            {/* {this.state.bookingInfo.collection_date!="" &&  (new Date(this.state.bookingInfo.collection_date).getDay() == 5 || new Date(this.state.bookingInfo.collection_date).getDay() == 6)&& 
                                                <div><span className="text-danger mt-2"> Return Date is a non-working day</span></div>
                                            } */}

                                            {/* {this.state.bookingInfo.collection_date!="" &&  ((new Date(this.state.bookingInfo.collection_date).getDate() < new Date().getDate()) || ((new Date(this.state.bookingInfo.collection_date).getMonth()+1) < (new Date().getMonth()+1)) || (new Date(this.state.bookingInfo.collection_date).getFullYear() < new Date().getFullYear())) && 
                                                <div><span className="text-danger">The collection date you booked is in the past</span></div>
                                            } */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <label>
                                            Select Kit{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-sm-9 col-8"  style={{
                                            zIndex:"999"
                                        }}>
                                        <div className="form-group mb-0">
                                            <Select
                                                value={this.state.selectedKit}
                                                onChange={
                                                    this.setSelectedOptionKit
                                                }
                                                options={this.state.kits}
                                                className="small"
                                            />
                                        </div>
                                        {this.state.kitSetErrorFlag == 1 ? (
                                            <span className="text-danger">
                                                Please add at least one kit set
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-sm-3 col-4">
                                        <Button
                                            className="btn  btn-block addnow1"
                                            onClick={this.handleAddKit}>
                                            <i className="fa fa-plus"></i>{" "}
                                            <span className="d-none d-md-block">
                                                Add
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <table className="table mb-0 mobile_full1">
                                        <tbody>
                                            {this.state.suggestedKits.length >
                                                0 && (
                                                <React.Fragment>
                                                    <tr>
                                                        <th
                                                            colSpan="3"
                                                            className="w-100 border-top-0">
                                                            <div className="mb-2">
                                                                <strong>
                                                                    Suggested
                                                                    Kits
                                                                </strong>
                                                            </div>
                                                            {this.state.suggestedKits.map(
                                                                (
                                                                    sugKit,
                                                                    sugInd,
                                                                ) => (
                                                                    <React.Fragment
                                                                        key={
                                                                            sugInd
                                                                        }>
                                                                        {this.state.kitWithKitSet.filter(
                                                                            filterKit =>
                                                                                filterKit._id ==
                                                                                sugKit
                                                                                    .suggestedKitDetail[0]
                                                                                    ?._id,
                                                                        )
                                                                            .length ==
                                                                        0 ? (
                                                                            <a
                                                                                className="mr-3 my-2 btn btn-small btn-default"
                                                                                href="javascript:;"
                                                                                onClick={() =>
                                                                                    this.setSugestedSelectedOptionKit(
                                                                                        {
                                                                                            value: sugKit
                                                                                                .suggestedKitDetail[0]
                                                                                                ?._id,
                                                                                            label: sugKit
                                                                                                .suggestedKitDetail[0]
                                                                                                ?.name,
                                                                                        },
                                                                                    )
                                                                                }>
                                                                                {
                                                                                    sugKit
                                                                                        .suggestedKitDetail[0]
                                                                                        ?.name
                                                                                }{" "}
                                                                                <i className="fa fa-plus ml-2"></i>{" "}
                                                                            </a>
                                                                        ) : (
                                                                            <a
                                                                                disabled
                                                                                className="mr-3 my-2 btn btn-small btn-default"
                                                                                href="javascript:;">
                                                                                {
                                                                                    sugKit
                                                                                        .suggestedKitDetail[0]
                                                                                        ?.name
                                                                                }{" "}
                                                                                <i className="fa fa-plus ml-2"></i>{" "}
                                                                            </a>
                                                                        )}
                                                                    </React.Fragment>
                                                                ),
                                                            )}
                                                        </th>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                            {this.state.kitWithKitSet &&
                                                this.state.kitWithKitSet.map(
                                                    (kit, index) => (
                                                        <React.Fragment
                                                            key={index}>
                                                            <tr>
                                                                <th>
                                                                    <span className="mt-4">
                                                                        {
                                                                            kit.name
                                                                        }
                                                                        {kit
                                                                            .kitDocument
                                                                            .length >
                                                                            0 && (
                                                                            <span className="ml-1">
                                                                                <a
                                                                                    id={
                                                                                        "UncontrolledTooltipPdf1" +
                                                                                        index
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                    className="download-info badge1 badge-info1"
                                                                                    href={
                                                                                        process
                                                                                            .env
                                                                                            .REACT_APP_APIURL +
                                                                                        "assets/uploads/kitDocuments/" +
                                                                                        kit
                                                                                            .kitDocument[0]
                                                                                            .name
                                                                                    }>
                                                                                    Download Tray List
                                                                                </a>
                                                                                
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                </th>
                                                                <td
                                                                    style={{
                                                                        "vertical-align":
                                                                            "bottom",
                                                                        "padding-bottom":
                                                                            "5%",
                                                                    }}>
                                                                    <label className="small">
                                                                        Quantity{" "}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <Input
                                                                        type="number"
                                                                        min="0"
                                                                        className="form-control"
                                                                        value={
                                                                            kit.quantity
                                                                        }
                                                                        onChange={e =>
                                                                            this.handleKitQuantity(
                                                                                index,
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            )
                                                                        }
                                                                        name=""
                                                                        style={{
                                                                            width: "120px",
                                                                        }}
                                                                    />
                                                                    {kit.quantity ==
                                                                        "" &&
                                                                    error &&
                                                                    this.state
                                                                        .quantityError ? (
                                                                        <span
                                                                            className="text-danger"
                                                                            style={{
                                                                                position:
                                                                                    "absolute",
                                                                            }}>
                                                                            {error.kitData
                                                                                ? error.kitData
                                                                                : ""}
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        "vertical-align":
                                                                            "bottom",
                                                                        "padding-bottom":
                                                                            "5%",
                                                                    }}>
                                                                    {/* <label className="small">
                                                                        Hire
                                                                        Charges
                                                                        (Per
                                                                        Set)
                                                                    </label>
                                                                    <Input
                                                                        type="number"
                                                                        min="0"
                                                                        className="form-control"
                                                                        value={
                                                                            kit.hire_charge
                                                                        }
                                                                        onChange={e =>
                                                                            this.handleKitHireCharge(
                                                                                index,
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            )
                                                                        }
                                                                        name=""
                                                                        placeholder="£"
                                                                        style={{
                                                                            width: "120px",
                                                                        }}
                                                                    /> */}
                                                                </td>

                                                                <td className="text-right">
                                                                    <div className="trash">
                                                                        <i
                                                                            onClick={() =>
                                                                                this.handelRemovKit(
                                                                                    index,
                                                                                )
                                                                            }
                                                                            className="fa fa-trash mt-4"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ),
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            {/* <div className="d-card">
                                <h4>Charges</h4>
                                <div className="form-group">
                                    <label>
                                        Shipping Charge{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        value={
                                            this.state.bookingInfo
                                                .shipping_charge
                                        }
                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "shipping_charge",
                                                e.target.value,
                                            )
                                        }
                                    />
                                    {error ? (
                                        <span className="text-danger">
                                            {error.shipping_charge
                                                ? error.shipping_charge
                                                : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div> */}

                            <div className="d-card">
                                <h4>Booked By</h4>
                                <div className="form-group">
                                    <label>Additional Reps</label>
                                    <Select
                                        defaultValue={
                                            this.state.selectedAdditionalRep
                                        }
                                        onChange={
                                            this.setSelectedOptionAdditionalRep
                                        }
                                        options={this.state.additionalReps}
                                        isMulti={true}
                                    />
                                </div>
                            </div>

                            <div className="d-card mt-4">
                                <h4>Additional Notes</h4>
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        value={
                                            this.state.bookingInfo
                                                .additional_notes
                                        }
                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "additional_notes",
                                                e.target.value,
                                            )
                                        }></textarea>
                                </div>
                            </div>

                            <div className="mt-5 text-right">
                                <Link
                                    to="/reps/open-booking"
                                    className="btn btn-secondary mb-3 mr-2">
                                    Cancel
                                </Link>
                                <a
                                    href="javascript:;"
                                    onClick={this.handleSubmit}
                                    className="btn mb-3 btn-primary">
                                    Save Changes
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* ADD FORM */}
                </div>

                <Modal
                    size="lg"
                    backdrop="static"
                    isOpen={this.state.kitSetDetailModal}
                    toggle={() => this.kitSetDetailModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.kitSetDetailModalClose()}>
                        Kit Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.kitDetails.setProductModules &&
                                    this.state.kitDetails.setProductModules.map(
                                        (product, ind) => (
                                            <tr key={ind}>
                                                <td>
                                                    {product.productID.code}
                                                </td>
                                                <td>
                                                    {product.productID.name}
                                                </td>
                                                <td>{product.quantity}</td>
                                            </tr>
                                        ),
                                    )}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                className="btn btn-secondary"
                                onClick={() => this.kitSetDetailModalClose()}>
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Set Details Modal */}

                             <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        repsList,
        error,
        kitSetList,
        redirect,
    } = state.rootReducer.repsBookings;
    const { kitListDropdown } = state.rootReducer.kits;
    const { editSet, set } = state.rootReducer.sets;

    return {
        repsList,
        error,
        kitListDropdown,
        kitSetList,
        redirect,
        editSet,
        set,
    };
}

export default connect(mapStateToProps)(Add);

