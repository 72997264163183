import React, { Component } from "react";
import { Link } from "react-router-dom";



import { bookingService, returnBookingService, collectionBookingService } from "../../../../services";
let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
}${separator}${date < 10 ? `0${date}` : `${date}`}`;

class DashboardSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestCount: this.props.requestCount,
            dispatchCount: this.props.dispatchCount,
            returnCount: this.props.returnCount,
            collectionCount: this.props.collectionCount,
            today: todayDate,
        };
    }

    pendingCount(){

        bookingService.countByStatus({
            type: ["loan"],
            status: ["pending"],
            deliveryDateFiter: "",
            toDeliveryDateFiter:""
        })
        .then(response => {
        	this.setState({
                requestCount:response.data
            });
            
        });
        
    }

    dispatchCount(){

        bookingService.countDispatch({
            type: ["loan"],
            status: ["dispatch", "booked"],
            deliveryDateFiter: this.state.today,
            toDeliveryDateFiter: this.state.today,
        })
        .then(response => {
            this.setState({
                dispatchCount:response.data
            });
            
        });
        
    }

    returnCount(){

        returnBookingService.countByDate({
            type: ["loan"],
            status: ["dispatch", "close"],
            returnDateFiter: this.state.today,
            toReturnDateFiter: this.state.today,
        })
        .then(response => {
            this.setState({
                returnCount:response.data
            });
        });
        
    }

    collectionCount(){

        collectionBookingService.countByDate({
            type: ["loan"],
            status: ["dispatch"],
            collectionDateFilter: this.state.today,
            toCollectionDateFilter: this.state.today,
        })
        .then(response => {
            this.setState({
                collectionCount:response.data
            });
        });
        
    }

    getCount() {
        this.pendingCount();
        this.dispatchCount();
        this.returnCount();
        this.collectionCount();
        /*this.props.dispatch(
            bookingActions.countByStatus({
                type: ["loan"],
                status: ["pending"],
                deliveryDateFiter: "",
            }),
        );
        this.props.dispatch(
            bookingActions.countDispatch({
                type: ["loan"],
                status: ["dispatch", "booked"],
                deliveryDateFiter: this.state.today,
                toDeliveryDateFiter: this.state.today,
            }),
        );
        this.props.dispatch(
            returnBookingActions.countByDate({
                type: ["loan"],
                status: ["dispatch", "close"],
                returnDateFiter: this.state.today,
                toReturnDateFiter: this.state.today,
            }),
        );
        this.props.dispatch(
            collectionBookingActions.countByDate({
                type: ["loan"],
                status: ["dispatch"],
                collectionDateFilter: this.state.today,
                toCollectionDateFilter: this.state.today,
            }),
        );*/
    }



    componentDidMount() {
        this.getCount();
    }

   

    render() {
        //const { username, password} = this.state;
        const { activePage } = this.props;
        return (
            <div>
                <div className="list-group mt-4 pr-4">
                    {/*<Link to="/admin/dashboard/" className="list-group-item d-flex justify-content-between align-items-center active">
                        Dispatch
                        <span className="badge badge-primary badge-pill">14</span>        
                    </Link>
                    <a href="dashboard-return.php" className="list-group-item d-flex justify-content-between align-items-center">
                    Returns
                        <span className="badge badge-primary badge-pill">2</span>
                    </a>
                    <a href="dashboard-collections.php" className="list-group-item d-flex justify-content-between align-items-center">
                    Collections
                        <span className="badge badge-primary badge-pill">2</span>
                    </a> */}
                    <Link
                        to="/admin/dashboard/booking-dispatch"
                        className={
                            "list-group-item d-flex justify-content-between align-items-center " +
                            (activePage == "dispatch" ? "active " : "")
                        }>
                        Dispatch
                        <span className="badge badge-primary badge-pill">
                            {this.state.dispatchCount != "" &&
                                this.state.dispatchCount > 0 &&
                                this.state.dispatchCount}
                        </span>
                    </Link>

                    <Link
                        to="/admin/dashboard/booking-returns"
                        className={
                            "list-group-item d-flex justify-content-between align-items-center " +
                            (activePage == "return" ? "active " : "")
                        }>
                        Returns
                        <span className="badge badge-primary badge-pill">
                            {this.state.returnCount != "" &&
                                this.state.returnCount > 0 &&
                                this.state.returnCount}
                        </span>
                    </Link>
                    <Link
                        to="/admin/dashboard/booking-collections"
                        className={
                            "list-group-item d-flex justify-content-between align-items-center " +
                            (activePage == "collection" ? "active " : "")
                        }>
                        Collections
                        <span className="badge badge-primary badge-pill">
                            {this.state.collectionCount != "" &&
                                this.state.collectionCount > 0 &&
                                this.state.collectionCount}
                        </span>
                    </Link>

                    <Link
                        to="/admin/dashboard/booking-request"
                        className={
                            "list-group-item d-flex justify-content-between align-items-center " +
                            (activePage == "request" ? "active " : "")
                        }>
                        Booking Requests
                        <span className="badge badge-primary badge-pill">
                            {this.state.requestCount != "" &&
                                this.state.requestCount > 0 &&
                                this.state.requestCount}
                        </span>
                    </Link>
                    <Link
                        to="/admin/dashboard/equipment-used-report"
                        className={
                            "list-group-item d-flex justify-content-between align-items-center " +
                            (activePage == "equipment" ? "active " : "")
                        }>
                        Equipment Used Report
                        {/* <span className="badge badge-primary badge-pill">
                            {this.state.requestCount != "" &&
                                this.state.requestCount > 0 &&
                                this.state.requestCount}
                        </span> */}
                    </Link>
                </div>
            </div>
        );
    }
}


export default DashboardSidebar;
