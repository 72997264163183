import axios from "axios";
import { authHeader } from "../helpers";

export const setService = {
    getSetsList,
    getKitsList,
    getProductsList,
    addSet,
    getById,
    updateSet,
    delete: _delete
};

function getSetsList(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"sets/getSets", requestOptions).then(handleResponse)
        .then( setsList => {
            return setsList;
        });
}

function getKitsList(searchString) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(searchString)
    };

    return fetch(process.env.REACT_APP_APIURL+"kits/searchKits", requestOptions).then(handleResponse)
        .then(kitsList => {
            return kitsList;
        });
}

function getProductsList(searchString) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(searchString)
    };

    return fetch(process.env.REACT_APP_APIURL+"products/searchProducts", requestOptions).then(handleResponse)
        .then(productsList => {
            return productsList;
        });
}

function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_APIURL+`sets/getSet/${id}`, requestOptions).then(handleResponse);
}

function addSet(setInfo) {
    // const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(setInfo)
    // };

    // return fetch(process.env.REACT_APP_APIURL+"sets/saveSets", requestOptions).then(handleResponse);

    return axios.post(process.env.REACT_APP_APIURL + "sets/saveSets", setInfo)
    .then((result) => result);
}

function updateSet(setInfo) {
    // const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(setInfo)
    // };

    // return fetch(process.env.REACT_APP_APIURL+"sets/updateSets", requestOptions).then(handleResponse);

    return axios.post(process.env.REACT_APP_APIURL + "sets/updateSets", setInfo)
    .then((result) => result);
}

// // prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(id)
    };

    return fetch(process.env.REACT_APP_APIURL+"sets/deleteSets", requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}