export const bookingRequestConstants = {
    BOOKINGACCEPTSTATUSUPDATE_REQUEST: "BOOKING_ACCEPT_STATUSUPDATE_REQUEST",
    BOOKINGACCEPTSTATUSUPDATE_SUCCESS: "BOOKING_ACCEPT_STATUSUPDATE_SUCCESS",
    BOOKINGACCEPTSTATUSUPDATE_FAILURE: "BOOKING_ACCEPT_STATUSUPDATE_FAILURE",

    BOOKINGREJECTSTATUSUPDATE_REQUEST: "BOOKING_REJECT_STATUSUPDATE_REQUEST",
    BOOKINGREJECTSTATUSUPDATE_SUCCESS: "BOOKING_REJECT_STATUSUPDATE_SUCCESS",
    BOOKINGREJECTSTATUSUPDATE_FAILURE: "BOOKING_REJECT_STATUSUPDATE_FAILURE",

    GETALLBOOKINGS_REQUEST: "BOOKINGS_GETALL_REQUEST",
    GETALLBOOKINGS_SUCCESS: "BOOKINGS_GETALL_SUCCESS",
    GETALLBOOKINGS_FAILURE: "BOOKINGS_GETALL_FAILURE"
};