import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input} from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./DashboardSidebar";
import { bookingRequestActions, bookingActions, setActions } from "../../../../actions";
import dateFormat from "dateformat";
import Select from "react-select";

import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import { ReactComponent  as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

const head = [
    { title: "EVENT NAME", dataIndex: "event_name"},
    { title: "KITS REQUIRED",dataIndex: "kits"},
    { title: "IMPORTANT DATES",dataIndex: "import_dates"},
    { title: "REP",dataIndex: "rep_name" },
   
];

class BookingRequest extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            //bookingList: false
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 0,
            kitSetDetailModal:false,
            kitSetData:[],
            kitDetailsClickFlag:0,
            kitDetails:[],
            cancelModal:false, 
            acceptModal:false,
            bookingId:0,
            users:[],
            selectedUser:[{label:"Booked By",value:""}],
            selectedUserId: "",
            // hospitals:[],
            // selectedHospital:[{label:"All Hospital",value:""}],
            // selectedHospitalId: "",
            requestCount:0,
            rejectComment: "",
            dataXlsx:[]
        };

        this.getList = this.getList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.handleRejectComment = this.handleRejectComment.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.cancelModalClose = this.cancelModalClose.bind(this);
        this.setSelectedOptionUser = this.setSelectedOptionUser.bind(this);
        // this.setSelectedOptionHospital = this.setSelectedOptionHospital.bind(this);
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            { 
                activePage: pageNumber }
            , () => {
                this.getList();
            }
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        if(totalItemsCount==undefined)
        {
            totalItemsCount=0;
        }
        this.setState(
            {
                activePage: activePage,
                totalItemsCount: totalItemsCount,
                limit: limit
            }
        );
    }

    getList() {
        this.props.dispatch(bookingRequestActions.getList({bookedByFilter: this.state.selectedUserId, hospitalFilter: "", type:["loan"],status:["pending"],deliveryDateFiter:"", toDeliveryDateFiter: "",search: this.state.search,page: this.state.activePage, limit: this.state.limit}));
    }

    getDropdown(){
        // this.props.dispatch(bookingActions.getHospitalsList({search: ""}));
        this.props.dispatch(bookingActions.getRepsList({search: ""}));
    }

    handleSearch(val) {
        this.setState({
            search: val,
            activePage:1
        },()=>{this.getList();});
    }

    kitSetDetailModalClose(){
        this.setState({
            kitSetDetailModal :false,
            kitSetData:[]
        });
    }

    showKitDetails(kitDetails){

        if(this.state.kitDetailsClickFlag==1)
        {
            this.setState({
                kitDetails:kitDetails,
                kitSetDetailModal : true,
                kitDetailsClickFlag:0
            }); 
        }
      
    }

    handleKitDetails(id){

        this.setState({
            kitDetailsClickFlag:1
        });
       
        this.props.dispatch(setActions.getById(id));

    }

    setSelectedOptionUser(e){
        var selectedUser=[{value:e.value, label:e.label}];
        this.setState({
            selectedUser,
            selectedUserId: e.value
        },()=>{this.getList();});
    } 

    // setSelectedOptionHospital(e){
    //     var selectedHospital=[{value:e.value, label:e.label}];
    //     this.setState({
    //         selectedHospital,
    //         selectedHospitalId: e.value
    //     },()=>{this.getList();});
    // } 

    cancelModalOpen(id){

        this.setState({
            cancelModal:true,
            bookingId:id
        });
    }

    cancelModalClose(){

        this.setState({
            cancelModal:false
        });

    }

    handleRejectComment(comment) {
        this.setState({
            rejectComment: comment
        });
    }

    handleCancle(){
        this.props.dispatch(bookingRequestActions.updateRejectStatus({id: this.state.bookingId,log_by_id:this.state.user.data._id,log_status:"rejected", comment: this.state.rejectComment, status:"reject"}));
    }

    acceptModalOpen(id){

        this.setState({
            acceptModal:true,
            bookingId:id
        });
    }

    acceptModalClose(){

        this.setState({
            acceptModal:false
        });

    }

    handleAccept(){
        this.props.dispatch(bookingRequestActions.updateAcceptStatus({id: this.state.bookingId,status:"booking", log_by_id:this.state.user.data._id}));
    }


    // showHospitalsList(hospitalsList) {
    //     var tempHospitals = [{label:"All Hospital",value:""}];
    //     hospitalsList.map((item)=> {
    //         var option = {value: item._id, label: item.name};
    //         tempHospitals.push(option);
    //     });
    //     this.setState({
    //         hospitals: tempHospitals,
    //     });
    // }

    showRepsList(repsList) {
        var tempRepsList = [{label:"Booked By",value:""}];
        repsList.map((item)=> {
            var option = {value: item._id, label: item.first_name+" "+item.last_name};
            tempRepsList.push(option);
        });
        this.setState({
            users: tempRepsList
        });
    }

    showXlsData(data){

        var dataXlsx=[];
        var kitset="";
        var imDate="";

        data && data.map((item) => {
            kitset="";
            imDate="";

            item.bookingkits && item.bookingkits.map((kit)=>{
                if(kitset!="")
                {
                    kitset+="\n"+kit.kits[0].name;
                }
                else
                {
                    kitset+=kit.kits[0].name; 
                }
                
            }); 
            imDate="Delivery Date: "+dateFormat(item.bookings.delivery_date, "mmm dS, yyyy")+" "+item.bookings.delivery_date_slot;
            imDate+="\nMeeting Date: "+dateFormat(item.bookings.operation_date, "mmm dS, yyyy")+" "+item.bookings.operation_date_slot;
            imDate+="\nCollection Date: "+dateFormat(item.bookings.collection_date, "mmm dS, yyyy");
                                                         
            dataXlsx.push({
                "event_name": item.bookings.event_name,
                "kits": kitset,
                "import_dates": imDate,
                "rep_name": item.assigned_rep.first_name+" "+item.assigned_rep.last_name
               
            });
        });

        this.setState({
            dataXlsx: dataXlsx
        });
                                                                    


    }


    capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

   
    componentDidMount(){
        this.getList();
        this.getDropdown();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if(nextProps.refreshList==true || nextProps.redirect==true)
        {
            this.setState({ 
                activePage: 1,
                cancelModal:false,
                acceptModal:false,
                rejectComment:""
            },()=>{this.getList();}); 
        }

        if(nextProps.activePage != "" && nextProps.totalItemsCount != "" && nextProps.limit != ""){
           
            this.handlePageStates(nextProps.activePage, nextProps.totalItemsCount, this.state.limit);
        }    

        if(nextProps.editSet == true ) {
            this.showKitDetails(nextProps.set);
        }

        if(nextProps.repsList !== undefined) {
            this.showRepsList(nextProps.repsList);
        }

        // if(nextProps.hospitalsList !== undefined) {
        //     this.showHospitalsList(nextProps.hospitalsList);
        // }

        if(nextProps.bookingList !== undefined) {
            this.showXlsData(nextProps.bookingList);
        }

        
    }


    render() {
        const {bookingList, loading } = this.props;
        if (!this.state.user) {
            return <Redirect to="/" />;
        }

        if(this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }

        //const { loggingIn } = this.props;
        //const { username, password} = this.state;
        return (
            <div>
                <Navbar activePage="dashboard"/>
                <main className="offset">
                    <div className="container-fluid">
                        <div className="row pt-4">
                            <div className="col-lg-2 side-filters">     
                                <span className="side-heading">Dashboard</span>
                                <DashboardSidebar activePage="request" requestCount={this.state.totalItemsCount}/>
                            </div>
                           
                            <div className="col-lg-10">
                                <div className="ground">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-8"> 
                                            {/*<div className="btn-group" role="group" aria-label="Basic example">          		  
                                                <button type="button" className="btn btn-secondary active">Today</button>
                                                <button type="button" className="btn btn-secondary">Tomorrow</button>	
                                            </div> */} 
                                             {/* <div className="sub-menu">
                                                <div className="d-flex"> */}
                                                    <div className="col p-0">
                                                        <div className="mr-2">
                                                            <Select 
                                                                value={this.state.selectedUser}
                                                                onChange={this.setSelectedOptionUser}
                                                                options={this.state.users}
                                                                className="small text-left"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/*<div className="col p-0">
                                                        <div className="ml-2">
                                                            <Select 
                                                                value={this.state.selectedHospital}
                                                                onChange={this.setSelectedOptionHospital}
                                                                options={this.state.hospitals}
                                                                className="small text-left"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <form className="form-search">
                                                <Input 
                                                    className="form-control mr-sm-2" 
                                                    type="text"
                                                    placeholder="Search.."
                                                    value={this.state.search}
                                                    onChange={(e) => this.handleSearch(e.target.value)}
                                                />    
                                            </form>
                                        </div>
                                        <div className="col-md-4 text-right">
                                            {/*<button type="button" className="btn btn-default"> Print </button>*/}
                                            <ExportSheet
                                                header={head}
                                                fileName={"booking-request"}
                                                dataSource={this.state.dataXlsx}
                                                xlsx={XLSX}
                                                className="btn btn-default add_option"
                                            >
                                                <button className="btn btn-default add_option">Print</button>
                                            </ExportSheet>
                                        </div>
                                    </div>
                                    <div className="table-scroll mt-4">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{minWidth:"200px"}}>Event Name </th>
                                                    <th scope="col" >Kits Required</th>
                                                    <th scope="col">Important Dates </th>
                                                    <th scope="col">Rep </th>
                                                    <th scope="col" className="text-right"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               
                                                { bookingList && bookingList.map((item) => (
                                                    <tr key={item._id}>
                                                        <td width="200" data-th="Event Name">
                                                            <div className="">                   
                                                                <Link to={"/admin/booking/details/"+item.bookings._id}>
                                                                    <span><strong><span style={{"display": "block"}} className="theme-text">{item.bookings.booking_id}</span>
                                                                        {item.bookings.event_name}</strong></span><br />
                                                                    <small>Requested on {dateFormat(item.bookings.createdAt, "dS mmm yy")}</small>                          
                                                                </Link>
                                                            </div>
                                                        </td>                                                        
                                                        <td data-th="Kits Required">
                                                            <small>
                                                                { item.bookingkits && item.bookingkits.map((kit,ind)=>(
                                                                    <React.Fragment key={ind}>
                                                                        <div><span>{kit.kits[0].name} </span></div>
                                                                    </React.Fragment>
                                                                ))}   
                                                                {/*{ item.bookingKitSetId && item.bookingKitSetId.map((kset,ind)=>(
                                                                    <React.Fragment key={ind}>
                                                                        <div><span>{kset.setId[0].set_name} </span></div>
                                                                    </React.Fragment>
                                                                ))}*/}
                                                               
                                                            </small>                        
                                                        </td>
                                                        <td data-th="Important Dates">
                                                            <small>
                                                            Delivery Date: <strong>{dateFormat(item.bookings.delivery_date, "mmm dS, yyyy")} {item.bookings.delivery_date_slot}</strong><br />
                                                            Meeting Date: <strong>{dateFormat(item.bookings.operation_date, "mmm dS, yyyy")} {item.bookings.operation_date_slot}</strong><br />
                                                            Collection Date: <strong>{dateFormat(item.bookings.collection_date, "mmm dS, yyyy")}</strong>
                                                            </small>
                                                        </td> 
                                                        <td data-th="Rep">{item.assigned_rep.first_name} {item.assigned_rep.last_name}</td>
                                                        
                                                        <td className="text-right">                                 
                                                            <a href="javascript:;" onClick={()=>this.cancelModalOpen(item.bookings._id)} className="btn btn-secondary">Reject</a>
                                                            <Link className="btn btn-primary ml-2" to={"/admin/booking/edit/"+item.bookings._id}>Accept</Link>
                                                        </td> 
                                                    </tr>
                                                ))}
                                                { bookingList && bookingList.length==0 && loading==false && 
                                                    <tr className="text-center">
                                                        <td colSpan="5">No Record Found</td>
                                                    </tr>
                                                } 
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                {bookingList && bookingList.length >0 &&
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                    />
                                }

                             

                                <Modal size="md" backdrop="static" isOpen={this.state.cancelModal} toggle={() => this.cancelModalClose()}>
                                    <ModalHeader className="" toggle={() => this.cancelModalClose()}>
                                        Reject Booking
                                    </ModalHeader>
                                    <ModalBody>
                                        {/* <div className="my-2">Please confirm if you want to reject this booking?</div> */}
                                        <textarea 
                                            className="form-control my-2" 
                                            placeholder="Comment"
                                            value={this.state.rejectComment}
                                            onChange={(e) => this.handleRejectComment(e.target.value)}
                                        ></textarea>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="">
                                            <button onClick={() => this.cancelModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                                            { loading==false && 
                                                <button className="btn btn-primary" onClick={() =>this.handleCancle()}>Ok</button>
                                            }
                                            {loading==true && 
                                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                            }
                                        </div>
                                    </ModalFooter>
                                </Modal>

                                {/* Reject Modal */}

                                <Modal size="ml" backdrop="static" isOpen={this.state.acceptModal} toggle={() => this.acceptModalClose()}>
                                    <ModalHeader className="" toggle={() => this.acceptModalClose()}>
                                        Accept Booking
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className="my-2">Please confirm if you want to accept this booking?</div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="">
                                            <button onClick={() => this.acceptModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                                            <button className="btn btn-primary" onClick={() =>this.handleAccept()}>Ok</button>
                                        </div>
                                    </ModalFooter>
                                </Modal>
                                {/* Accept Modal*/}

                                <Modal size="lg" backdrop="static" isOpen={this.state.kitSetDetailModal} toggle={() => this.kitSetDetailModalClose()}>
                                    <ModalHeader className="" toggle={() => this.kitSetDetailModalClose()}>
                                        Set Details
                                    </ModalHeader>
                                    <ModalBody>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Item Code</th>
                                                    <th>Item Name</th>
                                                    <th>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.kitDetails.setProductModules && this.state.kitDetails.setProductModules.map((product,ind)=>(
                                                    <tr key={ind}>
                                                        <td>{product.productID.code}</td>
                                                        <td>{product.productID.name}</td>
                                                        <td>{product.quantity}</td>
                                                    </tr>
                                                ))}
                                                
                                            </tbody>
                                        </table>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="">
                                            <button className="btn btn-secondary" onClick={() =>this.kitSetDetailModalClose()}>Close</button>
                                        </div>
                                    </ModalFooter>
                                </Modal>

                                {/* Set Details Modal */}
                            </div>
                        </div>{/*<!--/row-->*/}
                    </div>
                </main>

          
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { repsList } = state.rootReducer.bookings;
    const { bookingList, activePage, totalItemsCount, limit, loading, redirect } = state.rootReducer.bookingRequests;
    // const { error, refreshList  } = state.rootReducer.hospitals;
    const { editSet,set } = state.rootReducer.sets;
    return {
        bookingList,
        // // error,
        // refreshList,
        loading,
        activePage,
        totalItemsCount,
        limit,
        redirect,
        editSet,
        set,
        // hospitalsList,
        repsList
    };
}

export default connect(mapStateToProps)(BookingRequest);