import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import moment from "moment";
import { repsBookingActions, setActions } from "../../../../actions";
import dateFormat from "dateformat";
// import {DatePicker} from "reactstrap-date-picker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import DatePicker from "react-datepicker";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");

class Index extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            newBookingList: [],
            todayDate: "",
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 1,
            kitSetDetailModal: false,
            kitSetData: [],
            kitDetailsClickFlag: 0,
            kitDetails: [],
            cancelModal: false,
            cancelId: 0,
            //searchOperationDate:""
            searchOperationFromDate: "",
            filterOperationFromDate: "",

            searchDeliveryFromDate: "",
            searchDeliveryToDate: "",
            filterDeliveryFromDate: "",
            filterDeliveryToDate: "",
            delete_comment: "",
            error: false,
        };

        //fromSearchDate
        //toSearchDate

        this.getList = this.getList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.cancelModalClose = this.cancelModalClose.bind(this);
        this.arrangeBookingList = this.arrangeBookingList.bind(this);
        this.handleclearToDate = this.handleclearToDate.bind(this);
        this.handleclearFromDate = this.handleclearFromDate.bind(this);
    }

    handleclearToDate(event){
        if (event.key === "Backspace") {
            this.setState(
                {
                    searchDeliveryToDate: "",
                    filterDeliveryToDate:""
                });
        }
    }

    handleclearFromDate(event) {
        if (event.key === "Backspace") {
            this.setState(
                {
                    searchDeliveryFromDate: "",
                    filterDeliveryFromDate:""
                });
        }
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getList();
            },
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        this.setState({
            activePage: activePage,
            totalItemsCount: totalItemsCount,
            limit: limit,
        });
    }

    getList() {
        this.props.dispatch(
            repsBookingActions.getList({
                working_delivery_from_date: this.state.filterDeliveryFromDate,
                working_delivery_to_date: this.state.filterDeliveryToDate,
                type: ["loan"],
                reps_id: this.state.user.data._id,
                operation_from_date: this.state.filterOperationFromDate,
                status: ["pending", "booked", "dispatch", "return"],
                search: this.state.search,
                page: this.state.activePage,
                limit: this.state.limit,
            }),
        );
    }

    handleSearch(val) {
        this.setState(
            {
                search: val,
                activePage: 1,
            },
            () => {
                this.getList();
            },
        );
    }

    // handleSearchOperationDate(val){
    //     this.setState({
    //         searchOperationDate:val,
    //         activePage:1
    //     },()=>{this.getList();});
    // }

    handleSearchOperationFromDate(val) {
        this.setState(
            {
                searchOperationFromDate: val != null ? new Date(val) : "",
                filterOperationFromDate:
                    val != null ? moment(val).format("YYYY-MM-DD") : "",
                activePage: 1,
            },
            () => {
                this.getList();
            },
        );
    }

    handleSearchDeliveryFromDate(val) {
        this.setState(
            {
                searchDeliveryFromDate: val != null ? new Date(val) : "",
                filterDeliveryFromDate:
                    val != null ? moment(val).format("YYYY-MM-DD") : "",
                activePage: 1,
            },
            () => {
                this.getList();
            },
        );
    }

    handleSearchDeliveryToDate(val) {
        this.setState(
            {
                searchDeliveryToDate: val != null ? new Date(val) : "",
                filterDeliveryToDate:
                    val != null ? moment(val).format("YYYY-MM-DD") : "",
                activePage: 1,
            },
            () => {
                this.getList();
            },
        );
    }

    handleSearchDeliveryDate() {
        // var date = moment().toISOString();
        this.setState(
            {
                search: "",
                searchDeliveryFromDate: "",
                searchDeliveryToDate: "",
                filterDeliveryFromDate: "",
                filterDeliveryToDate: "",
                searchOperationFromDate: "",
                filterOperationFromDate: "",

                activePage: 1,
            },
            () => {
                this.getList();
            },
        );
    }

    kitSetDetailModalClose() {
        this.setState({
            kitSetDetailModal: false,
            kitSetData: [],
        });
    }

    showKitDetails(kitDetails) {
        if (this.state.kitDetailsClickFlag == 1) {
            this.setState({
                kitDetails: kitDetails,
                kitSetDetailModal: true,
                kitDetailsClickFlag: 0,
            });
        }
    }

    handleKitDetails(id) {
        this.setState({
            kitDetailsClickFlag: 1,
        });

        this.props.dispatch(setActions.getById(id));
    }

    cancelModalOpen(id) {
        this.setState({
            cancelModal: true,
            error: false,
            cancelId: id,
        });
    }

    cancelModalClose() {
        this.setState({
            cancelModal: false,
        });
    }

    handleCancle() {
        this.setState({ error: true });
        this.props.dispatch(
            repsBookingActions.updateStatus({
                id: this.state.cancelId,
                delete_comment: this.state.delete_comment,
                status: "cancel",
                log_by_id: this.state.user.data._id,
            }),
        );
    }

    capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    arrangeBookingList(bookingList) {
        var newBookingList = [];
        var prevDate = "";
        var prevIndex = 0;
        //var todayIndex = "";
        var dateArray = [];
        var todayDate = "";

        bookingList.map((bookingItem, index) => {
            var currDate = bookingItem.bookings.working_delivery_date;

            if (currDate !== prevDate) {
                if (index != 0) {
                    prevIndex = prevIndex + 1;
                }
                prevDate = bookingItem.bookings.working_delivery_date;
                newBookingList[prevIndex] = [];
            }

            todayDate = moment().format("YYYY-MM-DD");
            var dateToCheck = moment(currDate).format("YYYY-MM-DD");

            // if( moment(dateToCheck).isSame(todayDate)) {
            //     todayIndex = prevIndex;
            // }

            if (moment(dateToCheck).isSameOrAfter(todayDate)) {
                if (!dateArray.includes(prevIndex)) {
                    dateArray.push(prevIndex);
                }
            }

            newBookingList[prevIndex].push(bookingItem);
        });

        // for(var i=0; i<dateArray.length; i++) {
        //     var booking = newBookingList[dateArray[i]];
        //     newBookingList.splice(dateArray[i], 1);
        //     newBookingList.unshift(booking);
        // }

        // console.log(newBookingList);
        // if(todayIndex !== "") {
        //     var todayBooking = newBookingList[todayIndex];
        //     newBookingList.splice(todayIndex, 1);
        //     newBookingList.unshift(todayBooking);
        // }

        this.setState({
            newBookingList: newBookingList,
            todayDate: todayDate,
        });
    }

    deleteCommentHandler(val) {
        this.setState({
            delete_comment: val,
        });
    }

    componentDidMount() {
        // this.getList();
        setTimeout(() => {
            this.handleSearchDeliveryFromDate(moment().toISOString());
        }, 1000);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if (nextProps.refreshList == true || nextProps.redirect == true) {
            this.setState(
                {
                    activePage: 1,
                    cancelModal: false,
                },
                () => {
                    this.getList();
                },
            );
        }

        if (
            nextProps.activePage != "" &&
            nextProps.totalItemsCount != "" &&
            nextProps.limit != ""
        ) {
            this.handlePageStates(
                nextProps.activePage,
                nextProps.totalItemsCount,
                this.state.limit,
            );
        }

        if (nextProps.bookingList != undefined) {
            this.arrangeBookingList(nextProps.bookingList);
        }

        if (nextProps.editSet == true) {
            this.showKitDetails(nextProps.set);
        }
    }

    render() {
        const { loading, error } = this.props;
        console.log("item", this.state.newBookingList);
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "reps") {
            return <Redirect to="/" />;
        }
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
            }${separator}${date}`;
        console.log(todayDate);
        return (
            <div>
                <Navbar activePage="bookings" />
                <div className="container-fluid pt-1">
                    <div className="row pt-4 align-items-center">
                        <div className="col-md-4">
                            <ul className="sub-menu">
                                <li>
                                    <Link
                                        to="/reps/open-booking"
                                        className="active">
                                        Open
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/reps/reject-booking">
                                        Rejected
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/reps/closed-booking">
                                        Closed
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/reps/cancel-booking">
                                        Cancelled
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <form className="form-search">
                                <Input
                                    className="form-control mr-sm-2"
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={e =>
                                        this.handleSearch(e.target.value)
                                    }
                                />
                            </form>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="label_adjust">
                                <label className="small mb-0">
                                    Delivery Date{" "}
                                </label>

                                <div className="row no-gutters">
                                    <div className="col">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            showClearButton={false}
                                            placeholderText="From"
                                            selected={
                                                this.state
                                                    .searchDeliveryFromDate
                                            }
                                            onChange={e =>
                                                this.handleSearchDeliveryFromDate(
                                                    e,
                                                )
                                            }
                                            onKeyDown={this.handleclearFromDate}
                                            className="form-control"
                                            customInput={
                                                <MaskedInput
                                                    pipe={autoCorrectedDatePipe}
                                                    mask={[
                                                        /\d/,
                                                        /\d/,
                                                        "/",
                                                        /\d/,
                                                        /\d/,
                                                        "/",
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                    keepCharPositions={true}
                                                    guide={true}
                                                    showMask={false}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="mx-3">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                showClearButton={false}
                                                placeholderText="To"
                                                selected={
                                                    this.state
                                                        .searchDeliveryToDate
                                                }
                                                onChange={e =>
                                                    this.handleSearchDeliveryToDate(
                                                        e,
                                                    )
                                                }
                                                className="form-control"
                                                onKeyDown={this.handleclearToDate}
                                                customInput={
                                                    <MaskedInput
                                                        pipe={
                                                            autoCorrectedDatePipe
                                                        }
                                                        mask={[
                                                            /\d/,
                                                            /\d/,
                                                            "/",
                                                            /\d/,
                                                            /\d/,
                                                            "/",
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                        ]}
                                                        keepCharPositions={true}
                                                        guide={true}
                                                        showMask={false}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="label_adjust">
                                            <label className="small mb-0">
                                                Meeting Date{" "}
                                            </label>

                                            <span className="small ml-2 btn-clear">
                                                <a
                                                    href="javascript:;"
                                                    onClick={() =>
                                                        this.handleSearchDeliveryDate()
                                                    }>
                                                    Clear
                                                </a>
                                            </span>
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                showClearButton={false}
                                                selected={
                                                    this.state
                                                        .searchOperationFromDate
                                                }
                                                onChange={e =>
                                                    this.handleSearchOperationFromDate(
                                                        e,
                                                    )
                                                }
                                                className="form-control"
                                                customInput={
                                                    <MaskedInput
                                                        pipe={
                                                            autoCorrectedDatePipe
                                                        }
                                                        mask={[
                                                            /\d/,
                                                            /\d/,
                                                            "/",
                                                            /\d/,
                                                            /\d/,
                                                            "/",
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                        ]}
                                                        keepCharPositions={true}
                                                        guide={true}
                                                        showMask={false}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-1 text-right">
                            <Link
                                style={{ marginLeft: "-7px" }}
                                className="btn btn-primary add_option"
                                to="/reps/booking/add">
                                <i className="fa fa-plus"></i> <span>Add</span>
                            </Link>
                        </div>
                    </div>

                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>
                                    {/* <th scope="col" width="200">
                                        Hospital Name
                                    </th>
                                    <th scope="col" className="mobilehide">
                                        Contact Person
                                    </th> */}


                                    <th scope="col" width="300">
                                        Event Name
                                    </th>
                                    <th scope="col" width="300">
                                        Event Address
                                    </th>


                                    <th scope="col" className="mobilehide">
                                        Kits Required
                                    </th>
                                    <th
                                        scope="col"
                                        style={{ minWidth: "100px" }}
                                        className="mobilehide">
                                        Sets
                                    </th>
                                    {/* <th scope="col">Operation Name</th> */}
                                    <th scope="col" width="200">
                                        Important Dates
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-right"
                                        width="175">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.newBookingList &&
                                    this.state.newBookingList.map(item =>
                                        item.map((innerItem, index) => (
                                            <React.Fragment
                                                key={innerItem.bookings._id}>
                                                {index == 0 && (
                                                    <tr
                                                        className="data_date sss"
                                                        key={
                                                            innerItem.bookings
                                                                ._id
                                                        }>
                                                        <th
                                                            colSpan="7"
                                                            className="text-center">
                                                            {moment(
                                                                moment(
                                                                    innerItem
                                                                        .bookings
                                                                        .working_delivery_date,
                                                                ).format(
                                                                    "YYYY-MM-DD",
                                                                ),
                                                            ).isSame(
                                                                this.state
                                                                    .todayDate,
                                                            )
                                                                ? "Today"
                                                                : moment(
                                                                    innerItem
                                                                        .bookings
                                                                        .working_delivery_date,
                                                                ).format(
                                                                    "ddd, MMM Do, YYYY",
                                                                )}
                                                        </th>
                                                    </tr>
                                                )}
                                                <tr>
                                                    {/* <td
                                                        width="200"
                                                        data-th="Hospital Name"
                                                        className="mobilehide_heading">
                                                        <div className="">
                                                            <Link
                                                                to={
                                                                    "/reps/booking/details/" +
                                                                    innerItem
                                                                        .bookings
                                                                        ._id
                                                                }>
                                                                <span>
                                                                    <strong>
                                                                        <span
                                                                            style={{
                                                                                display:
                                                                                    "block",
                                                                            }}
                                                                            className="theme-text">
                                                                            {
                                                                                innerItem
                                                                                    .bookings
                                                                                    .booking_id
                                                                            }
                                                                        </span>
                                                                        {
                                                                            innerItem
                                                                                .hospitalId
                                                                                .name
                                                                        }
                                                                    </strong>
                                                                </span>
                                                                <br />
                                                                {innerItem
                                                                    .bookingSurgeonData
                                                                    .length >
                                                                    0 && (
                                                                    <React.Fragment>
                                                                        <span>
                                                                            <small>
                                                                                Surgeon:
                                                                            </small>{" "}
                                                                            <strong>
                                                                                {innerItem
                                                                                    .bookingSurgeonData[0]
                                                                                    .SurgeonDetail[0]
                                                                                    .first_name +
                                                                                    " " +
                                                                                    innerItem
                                                                                        .bookingSurgeonData[0]
                                                                                        .SurgeonDetail[0]
                                                                                        .last_name}
                                                                            </strong>
                                                                        </span>
                                                                        <br />
                                                                    </React.Fragment>
                                                                )}
                                                                <span>
                                                                            <small>
                                                                                Assigned Reps:
                                                                            </small>{" "}
                                                                            <strong>
                                                                                {innerItem
                                                                                    .assigned_rep
                                                                                    .first_name +
                                                                                    " " +
                                                                                    innerItem
                                                                                    .assigned_rep
                                                                                        .last_name}
                                                                            </strong>
                                                                        </span>
                                                                        <br />
                                                                <small className="mobilehide">
                                                                    Booked on{" "}
                                                                    {dateFormat(
                                                                        innerItem
                                                                            .bookings
                                                                            .createdAt,
                                                                        "mmm dS, yyyy",
                                                                    )}
                                                                </small>
                                                                {innerItem
                                                                    .bookings
                                                                    .po_number !==
                                                                    "" && (
                                                                    <React.Fragment>
                                                                        <br />
                                                                        <small className="mobilehide">
                                                                            PO
                                                                            Number:{" "}
                                                                            {
                                                                                innerItem
                                                                                    .bookings
                                                                                    .po_number
                                                                            }
                                                                        </small>
                                                                    </React.Fragment>
                                                                )}
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td
                                                        data-th="Contact Person"
                                                        className="mobilehide">
                                                        <div className="info-left">
                                                            <strong>
                                                                {
                                                                    innerItem
                                                                        .hospitalId
                                                                        .contact_person_name
                                                                }{" "}
                                                            </strong>
                                                            <br />
                                                            <small>
                                                                Location:{" "}
                                                                {
                                                                    innerItem
                                                                        .bookings
                                                                        .hospital_address
                                                                }{" "}
                                                            </small>
                                                            <br />
                                                            <small>
                                                                Email:{" "}
                                                                {
                                                                    innerItem
                                                                        .hospitalId
                                                                        .email
                                                                }
                                                            </small>
                                                            <br />
                                                            <small>
                                                                Phone:{" "}
                                                                {
                                                                    innerItem
                                                                        .hospitalId
                                                                        .contact_person_phone_number
                                                                }
                                                            </small><br />
                                                            <small>
                                                                Department:
                                                                {
                                                                    innerItem
                                                                        .bookings
                                                                        .hospital_department
                                                                }
                                                            </small>
                                                        </div>
                                                    </td> */}
                                                    <td
                                                        width="200"
                                                        data-th="Event Name"
                                                        className="mobilehide_heading">
                                                        <div className="">
                                                            <Link
                                                                to={
                                                                    "/reps/booking/details/" +
                                                                    innerItem
                                                                        .bookings
                                                                        ._id
                                                                }>
                                                                <span>
                                                                    <strong>
                                                                        <span
                                                                            style={{
                                                                                display:
                                                                                    "block",
                                                                            }}
                                                                            className="theme-text">
                                                                            {
                                                                                innerItem
                                                                                    .bookings
                                                                                    .booking_id
                                                                            }
                                                                        </span>
                                                                        {
                                                                            innerItem
                                                                                .bookings
                                                                                .event_name
                                                                        }
                                                                    </strong>
                                                                </span>
                                                                <br />

                                                                <small className="mobilehide">
                                                                    Booked on{" "}
                                                                    {dateFormat(
                                                                        innerItem
                                                                            .bookings
                                                                            .createdAt,
                                                                        "mmm dS, yyyy",
                                                                    )}
                                                                </small>

                                                            </Link>
                                                        </div>
                                                    </td>


                                                    <td
                                                        width="200"
                                                        data-th="Event Address"
                                                        className="mobilehide_heading">
                                                        <div className="">
                                                            <Link
                                                                to={
                                                                    "/reps/booking/details/" +
                                                                    innerItem
                                                                        .bookings
                                                                        ._id
                                                                }>
                                                                {/* <span>
                                                                    <strong>
                                                             <span
                                                                            style={{
                                                                                display:
                                                                                    "block",
                                                                            }}
                                                                            className="theme-text">
                                                                            {
                                                                                innerItem
                                                                                    .bookings
                                                                                    .booking_id
                                                                            }
                                                                        </span> 
                                                                        {
                                                                            innerItem
                                                                            .bookings
                                                                            .name_of_location
                                                                        }
                                                                     </strong>
                                                                </span>  
                                                                <br />  */}

                                                                <React.Fragment>
                                                                    <span>
                                                                        <small>
                                                                            Name of Location:
                                                                        </small>{" "}
                                                                        <strong>
                                                                            {innerItem
                                                                                .bookings
                                                                                .name_of_location}
                                                                        </strong>
                                                                    </span>
                                                                    <br />
                                                                </React.Fragment>
                                                                <React.Fragment>
                                                                    <span>
                                                                        <small>
                                                                            Address:
                                                                        </small>{" "}
                                                                        <strong>
                                                                            {innerItem
                                                                                .bookings
                                                                                .address}
                                                                        </strong>
                                                                    </span>
                                                                    <br />
                                                                </React.Fragment>
                                                                <React.Fragment>
                                                                    <span>
                                                                        <small>
                                                                            Postcode:
                                                                        </small>{" "}
                                                                        <strong>
                                                                            {innerItem
                                                                                .bookings
                                                                                .postcode}
                                                                        </strong>
                                                                    </span>
                                                                    <br />
                                                                </React.Fragment>
                                                                <React.Fragment>
                                                                    <span>
                                                                        <small>
                                                                            Phone Number:
                                                                        </small>{" "}
                                                                        <strong>
                                                                            {innerItem
                                                                                .bookings
                                                                                .phone_number}
                                                                        </strong>
                                                                    </span>
                                                                    <br />
                                                                </React.Fragment>

                                                                {/* <small className="mobilehide">
                                                                    Booked on{" "}
                                                                    {dateFormat(
                                                                        innerItem
                                                                            .bookings
                                                                            .createdAt,
                                                                        "mmm dS, yyyy",
                                                                    )}
                                                                </small> */}

                                                            </Link>
                                                        </div>
                                                    </td>

                                                    <td
                                                        data-th="Kits Required"
                                                        className="mobilehide">
                                                        <small>
                                                            {innerItem.bookingkits &&
                                                                innerItem.bookingkits.map(
                                                                    (
                                                                        kit,
                                                                        ind,
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={
                                                                                ind
                                                                            }>
                                                                            <div>
                                                                                <span>
                                                                                    {
                                                                                        kit
                                                                                            .kits[0]
                                                                                            .name
                                                                                    }{" "}
                                                                                </span>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                        </small>
                                                    </td>
                                                    <td
                                                        data-th="Sets"
                                                        className="mobilehide">
                                                        <small>
                                                            {innerItem.bookingKitSetId &&
                                                                innerItem.bookingKitSetId.map(
                                                                    (
                                                                        kset,
                                                                        ind,
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={
                                                                                ind
                                                                            }>
                                                                            {/* <div><a href="javascript:;" onClick={()=>this.handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div> */}
                                                                            <div>
                                                                                <span>
                                                                                    {
                                                                                        kset
                                                                                            .setId[0]
                                                                                            .set_name
                                                                                    }{" "}
                                                                                </span>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                        </small>
                                                    </td>
                                                    {/* <td data-th="Operation Name">
                                                        {innerItem.bookings
                                                            .operation_name ===
                                                        "Other" ? (
                                                            <span>
                                                                {" "}
                                                                {
                                                                    innerItem
                                                                        .bookings
                                                                        .other_opration
                                                                }{" "}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {" "}
                                                                {
                                                                    innerItem
                                                                        .bookings
                                                                        .operation_name
                                                                }{" "}
                                                            </span>
                                                        )}
                                                    </td> */}
                                                    <td
                                                        data-th="Important Dates"
                                                        className="mobilehide_heading">
                                                        <small>
                                                            <span className="mobilehide">
                                                                Delivery Date:{" "}
                                                                <strong>
                                                                    {dateFormat(
                                                                        innerItem
                                                                            .bookings
                                                                            .delivery_date,
                                                                        "mmm dS, yyyy",
                                                                    )}{" "}
                                                                    {
                                                                        innerItem
                                                                            .bookings
                                                                            .delivery_date_slot
                                                                    }
                                                                </strong>
                                                                <br />
                                                            </span>
                                                            Meeting Date:{" "}
                                                            <strong>
                                                                {dateFormat(
                                                                    innerItem
                                                                        .bookings
                                                                        .operation_date,
                                                                    "ddd, mmm dS, yyyy",
                                                                )}{" "}
                                                                {
                                                                    innerItem
                                                                        .bookings
                                                                        .operation_date_slot
                                                                }
                                                            </strong>
                                                            <br />
                                                            <span className="mobilehide">
                                                                Collection Date:{" "}
                                                                <strong>
                                                                    {dateFormat(
                                                                        innerItem
                                                                            .bookings
                                                                            .collection_date,
                                                                        "mmm dS, yyyy",
                                                                    )}
                                                                </strong>
                                                            </span>
                                                        </small>
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="action-area dropdown">
                                                            <span
                                                                className={
                                                                    "capsule " +
                                                                    (innerItem
                                                                        .bookings
                                                                        .status ==
                                                                        "booked"
                                                                        ? "green-dim "
                                                                        : "red-dim ") +
                                                                    " mr-2 mb-1"
                                                                }>
                                                                {innerItem
                                                                    .bookings
                                                                    .status ==
                                                                    "dispatch" && (
                                                                        <div>
                                                                            Dispatched
                                                                        </div>
                                                                    )}
                                                                {innerItem
                                                                    .bookings
                                                                    .status !=
                                                                    "dispatch" &&
                                                                    this.capitalize(
                                                                        innerItem
                                                                            .bookings
                                                                            .status,
                                                                    )}
                                                            </span>

                                                            <span>
                                                                <Link
                                                                    className="btn btn-outline-primary btn-sm1"
                                                                    to={
                                                                        "/reps/booking/details/" +
                                                                        innerItem
                                                                            .bookings
                                                                            ._id
                                                                    }>
                                                                    More Details
                                                                </Link>
                                                            </span>

                                                            {(innerItem.bookings
                                                                .status ===
                                                                "pending" ||
                                                                innerItem
                                                                    .bookings
                                                                    .status ===
                                                                "booked") && (
                                                                    <span>
                                                                        <Link
                                                                            className="btn btn-sm1 btn-outline-primary"
                                                                            to={
                                                                                "/reps/booking/edit/" +
                                                                                innerItem
                                                                                    .bookings
                                                                                    ._id
                                                                            }>
                                                                            Edit
                                                                        </Link>
                                                                    </span>
                                                                )}
                                                            {innerItem.bookings
                                                                .status ===
                                                                "pending" && (
                                                                    <span>
                                                                        <a
                                                                            href="javascript:;"
                                                                            onClick={() =>
                                                                                this.cancelModalOpen(
                                                                                    innerItem
                                                                                        .bookings
                                                                                        ._id,
                                                                                )
                                                                            }
                                                                            className="btn btn-outline-primary btn-sm1">
                                                                            Cancel
                                                                        </a>
                                                                    </span>
                                                                )}
                                                            {innerItem.bookings
                                                                .status ===
                                                                "booked" &&
                                                                dateFormat(
                                                                    innerItem
                                                                        .bookings
                                                                        .delivery_date,
                                                                    "yyyy-mm-dd",
                                                                ) >
                                                                dateFormat(
                                                                    new Date(),
                                                                    "yyyy-mm-dd",
                                                                ) && (
                                                                    <span>
                                                                        <a
                                                                            href="javascript:;"
                                                                            onClick={() =>
                                                                                this.cancelModalOpen(
                                                                                    innerItem
                                                                                        .bookings
                                                                                        ._id,
                                                                                )
                                                                            }
                                                                            className="btn btn-outline-primary btn-sm1">
                                                                            Cancel
                                                                        </a>
                                                                    </span>
                                                                )}
                                                            {innerItem.bookings
                                                                .status ==
                                                                "dispatch" && (
                                                                    <span>
                                                                        <Link
                                                                            className="btn btn-outline-primary btn-sm1"
                                                                            to={
                                                                                "/reps/booking/item-used/o/" +
                                                                                innerItem
                                                                                    .bookings
                                                                                    ._id
                                                                            }>
                                                                            Item
                                                                            Used
                                                                        </Link>
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )),
                                    )}
                                {this.state.newBookingList &&
                                    this.state.newBookingList.length == 0 &&
                                    loading == false && (
                                        <tr className="text-center">
                                            <td colSpan="7">No Record Found</td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {this.state.newBookingList &&
                    this.state.newBookingList.length > 0 && (
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                            activeLinkClass="active"
                            nextPageText="Next"
                            prevPageText="Previous"
                        />
                    )}

                <Modal
                    size="md"
                    backdrop="static"
                    isOpen={this.state.cancelModal}
                    toggle={() => this.cancelModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.cancelModalClose()}>
                        Cancel Booking
                    </ModalHeader>
                    <ModalBody>
                        <div className="col">
                            <label>
                                Reason Of Cancellation{" "}
                                <strong className="text-danger">*</strong>
                            </label>
                            <textarea
                                type="text"
                                className="form-control"
                                value={this.state.delete_comment}
                                onChange={e =>
                                    this.deleteCommentHandler(e.target.value)
                                }
                            />
                            {this.state.error == true && error ? (
                                <span className="text-danger">
                                    {error.delete_comment
                                        ? error.delete_comment
                                        : ""}
                                </span>
                            ) : (
                                ""
                            )}
                            {this.state.error && error ? (
                                <span className="text-danger">
                                    {error.common ? error.common : ""}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                onClick={() => this.cancelModalClose()}
                                className="btn btn-secondary mr-2"
                                data-dismiss="modal">
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => this.handleCancle()}>
                                Ok
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Delete Modal */}

                <Modal
                    size="ml"
                    backdrop="static"
                    isOpen={this.state.dispatchModal}
                    toggle={() => this.dispatchModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.dispatchModalClose()}>
                        Dispatch
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">
                            Please confirm if you want to dispatch this booking?
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                onClick={() => this.dispatchModalClose()}
                                className="btn btn-secondary mr-2"
                                data-dismiss="modal">
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => this.handleDispatch()}>
                                Ok
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Cancle Modal*/}

                <Modal
                    size="lg"
                    backdrop="static"
                    isOpen={this.state.kitSetDetailModal}
                    toggle={() => this.kitSetDetailModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.kitSetDetailModalClose()}>
                        Set Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.kitDetails.setProductModules &&
                                    this.state.kitDetails.setProductModules.map(
                                        (product, ind) => (
                                            <tr key={ind}>
                                                <td>
                                                    {product.productID.code}
                                                </td>
                                                <td>
                                                    {product.productID.name}
                                                </td>
                                                <td>{product.quantity}</td>
                                            </tr>
                                        ),
                                    )}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                className="btn btn-secondary"
                                onClick={() => this.kitSetDetailModalClose()}>
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Set Details Modal */}
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        bookingList,
        activePage,
        totalItemsCount,
        limit,
        loading,
        redirect,
        error,
    } = state.rootReducer.repsBookings;
    // const { refreshList } = state.rootReducer.hospitals;
    const { editSet, set } = state.rootReducer.sets;
    return {
        bookingList,
        error,
        // refreshList,
        loading,
        activePage,
        totalItemsCount,
        limit,
        redirect,
        editSet,
        set,
    };
}

export default connect(mapStateToProps)(Index);
