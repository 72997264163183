import { bookingRequestConstants } from "../constants";
import { bookingRequestService } from "../services";

export const bookingRequestActions = {
    getList,
    updateAcceptStatus,
    updateRejectStatus
};

function getList(search) {
    return dispatch => {
        dispatch(request());

        bookingRequestService.getList(search)
            .then(
                bookingList => dispatch(success(bookingList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingRequestConstants.GETALLBOOKINGS_REQUEST }; }
    function success(bookingList) { return { type: bookingRequestConstants.GETALLBOOKINGS_SUCCESS, bookingList }; }
    function failure(error) { return { type: bookingRequestConstants.GETALLBOOKINGS_FAILURE, error }; }
}

function updateAcceptStatus(info) {
    return dispatch => {
        dispatch(request());

        bookingRequestService.updateAcceptStatus(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingRequestConstants.BOOKINGACCEPTSTATUSUPDATE_REQUEST }; }
    function success(info) { return { type: bookingRequestConstants.BOOKINGACCEPTSTATUSUPDATE_SUCCESS, info }; }
    function failure(error) { return { type: bookingRequestConstants.BOOKINGACCEPTSTATUSUPDATE_FAILURE, error }; }
}

function updateRejectStatus(info) {
    return dispatch => {
        dispatch(request());

        bookingRequestService.updateRejectStatus(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingRequestConstants.BOOKINGREJECTSTATUSUPDATE_REQUEST }; }
    function success(info) { return { type: bookingRequestConstants.BOOKINGREJECTSTATUSUPDATE_SUCCESS, info }; }
    function failure(error) { return { type: bookingRequestConstants.BOOKINGREJECTSTATUSUPDATE_FAILURE, error }; }
}