export const operationService = {
    getOperationsList,
    getOperationsListBYLimit,
    addOperationsList,
    delete: _delete,
    update,
};

function getOperationsList(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "operations/getOperationList",
        requestOptions
    )
        .then(handleResponse)
        .then((operationList) => {
            return operationList;
        });
}


function getOperationsListBYLimit(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "operations/getOperations",
        requestOptions
    )
        .then(handleResponse)
        .then((operationList) => {
            return operationList;
        });
}

function addOperationsList(operationName) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(operationName),
    };
    console.log("---service", operationName);
    return fetch(
        process.env.REACT_APP_APIURL + "operations/addOperation",
        requestOptions
        
    ).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(id),
    };
    console.log(id);
    return fetch(
        process.env.REACT_APP_APIURL + "operations/deleteOperation",
        requestOptions
    ).then(handleResponse);
}

function update(operationName) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(operationName),
    };
    console.log(operationName);
    return fetch(
        process.env.REACT_APP_APIURL + "operations/updateOperation",
        requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        // const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}
