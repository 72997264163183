import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./DashboardSidebar";
import Collection from "../Booking/Collection";
import moment from "moment";
import dateFormat from "dateformat";
import { collectionBookingActions, setActions } from "../../../../actions";
import * as excelJs from "exceljs";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";

const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
  }${separator}${date < 10 ? `0${date}` : `${date}`}`;

const generateTemplate = async (data) => {
  const workbook = new excelJs.Workbook();
  const ws = workbook.addWorksheet("Test Worksheet");
  const options = ["Normal", "High"];
  const headers = [
    "Collection Number",
    "Event Name",
    "Address",
    // "Contact Name",
    "Contact Number",
    "No. of Boxes",
    "Sets Name & No.",
    "Priority Level",
    "Comments",
  ];

  ws.addRow(headers);

  data.forEach((item, index) => {
    const kitset = item.bookingKitSetId.map(kit => kit.setId[0].set_name).join("\n");
    const boxesSet = item.bookingdispatches[0].bookingdispatchboxes.map(box => {
      return `${box.box_type} - ${box.number_of_box} ${box.number_of_box > 1 ? "Boxes" : "Box"}`;
    }).join("\n");
console.log("dwdd23e",item);

    const rowData = [
      index + 1,
      item.bookings.event_name,
      item.bookings.address,
      // item.hospitalId.contact_person_name,
      item.bookings.phone_number,
      boxesSet,
      kitset,
      item.bookingcollections[0] ? item.bookingcollections[0].priority_level : "",
      item.bookingcollections[0] ? item.bookingcollections[0].comment : "",
    ];

    ws.addRow(rowData);
  });

  ws.columns.forEach((col) => (col.width = 18));

  // ws.getColumn("H").eachCell((cell) => {
  //   if (cell.value === "High") {
  //     cell.font = {
  //       color: { argb: "FFDC143C" }
  //     };
  //     cell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "FFFFC0CB" },
  //     };
  //   } else if (cell.value === "Normal") {
  //     cell.font = {
  //       color: { argb: "000000" },
  //     };
  //     cell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "FFFFFFFF" },
  //     };
  //   }
  // });

  ws.dataValidations.add("G2:G99999", {
    type: "list",
    allowBlank: false,
    formulae: [`"${options.join(",")}"`],
    showDropDown: false,
    showDropDownInCell: true,
  });

  ws.addConditionalFormatting({
    ref: "G2:G99999",
    rules: [
      {
        type: "expression",
        formulae: ["High"],
        style: { fill: { type: "pattern", pattern: "solid", width: 100, fgColor: { argb: "FFFFC0CB" } } },
      }
    ]
  });


  ws.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = { horizontal: "center" };
      });
    }
    if (rowNumber === 1) {
      row.eachCell((cell) => {
        cell.font = {
          bold: true,
          size: 11,
        };
      });
    }
  });

  ws.getRow(1).eachCell((cell) => {
    cell.font = {
      bold: true,
      size: 12,
    };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFFFF" },
    };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    cell.alignment = { horizontal: "center" };
  });


  const excelBlob = await workbook.xlsx.writeBuffer();
  const excelUrl = URL.createObjectURL(
    new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
  );

  const link = document.createElement("a");
  link.href = excelUrl;
  link.download = "booking-collection";
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(excelUrl);
  document.body.removeChild(link);
};

class BookingCollection extends Component {
  constructor(props) {
    super(props);
    // reset login status
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      //bookingList: false
      search: "",
      activePage: 1,
      limit: 50,
      totalItemsCount: 0,
      kitSetDetailModal: false,
      kitSetData: [],
      kitDetailsClickFlag: 0,
      kitDetails: [],
      acceptModal: false,
      bookingId: 0,
      users: [],
      selectedUser: [{ label: "Booked By", value: "" }],
      selectedUserId: "",
      // hospitals: [],
      // selectedHospital: [{ label: "All Hospital", value: "" }],
      // selectedHospitalId: "",
      requestCount: 0,
      rejectComment: "",
      trackers: [
        { label: "A", value: "A" },
        { label: "B", value: "B" },
      ],
      selectedTrackers: [],
      date: todayDate,
      todate: todayDate,
      dayActive: "today",
      collectionModal: false,
      dataXlsx: [],
      // exportData:[],
      detailsModalResult: [],
      detailsModal: false,
      generationInProgress: false
    };

    this.getList = this.getList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleKitDetails = this.handleKitDetails.bind(this);
    this.showKitDetails = this.showKitDetails.bind(this);
    this.collectionModalOpen = this.collectionModalOpen.bind(this);
    this.collectionModalClose = this.collectionModalClose.bind(this);
    this.collectionSuccess = this.collectionSuccess.bind(this);
    this.detailsModalOpen = this.detailsModalOpen.bind(this);
    this.detailsModalClose = this.detailsModalClose.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
  }

  handleExportClick(data) {
    this.setState(
      {
        generationInProgress: true,
      });

    console.log("datatataa", data, generateTemplate);

    generateTemplate(data)
      .then(() => {
        this.setState(
          {
            generationInProgress: false,
          });
      })
      .catch((error) => {
        console.error("Error generating Excel:", error);
        this.setState(
          {
            generationInProgress: false,
          });
      });
  }

  handlePageChange(pageNumber) {
    //console.log(`active page is ${pageNumber}`);
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getList();
      },
    );
  }

  toDay() {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";
    const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date < 10 ? `0${date}` : `${date}`}`;

    this.setState(
      {
        dayActive: "today",
        date: todayDate,
        todate: todayDate,
      },
      () => {
        this.getList();
      },
    );
  }

  nextDays() {
    let newDate = new Date();
    let date = newDate.getDate() + 1;
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";
    const tomorrowDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date < 10 ? `0${date}` : `${date}`}`;

    this.setState(
      {
        dayActive: "tomorrow",
        date: tomorrowDate,
        todate: tomorrowDate,
      },
      () => {
        this.getList();
      },
    );
  }

  pastBookings() {
    var pastYearDate = moment().subtract(2, "years").format("YYYY-MM-DD");
    var yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");

    this.setState(
      {
        dayActive: "past",
        date: pastYearDate,
        todate: yesterdayDate,
      },
      () => {
        this.getList();
      },
    );
  }

  handlePageStates(activePage, totalItemsCount, limit) {
    if (totalItemsCount == undefined) {
      totalItemsCount = 0;
    }

    this.setState({
      activePage: activePage,
      totalItemsCount: totalItemsCount,
      limit: limit,
    });
  }

  getList() {
    this.props.dispatch(
      collectionBookingActions.getList({
        bookedByFilter: this.state.selectedUserId,
        hospitalFilter: "",
        type: ["loan"],
        status: ["dispatch", "close"],
        collectionDateFilter: this.state.date,
        toCollectionDateFilter: this.state.todate,
        search: this.state.search,
        page: this.state.activePage,
        limit: this.state.limit,
      }),
    );
  }

  handleSearch(val) {
    this.setState(
      {
        search: val,
        activePage: 1,
      },
      () => {
        this.getList();
      },
    );
  }

  kitSetDetailModalClose() {
    this.setState({
      kitSetDetailModal: false,
      kitSetData: [],
    });
  }

  showKitDetails(kitDetails) {
    if (this.state.kitDetailsClickFlag == 1) {
      this.setState({
        kitDetails: kitDetails,
        kitSetDetailModal: true,
        kitDetailsClickFlag: 0,
      });
    }
  }

  handleKitDetails(id) {
    this.setState({
      kitDetailsClickFlag: 1,
    });

    this.props.dispatch(setActions.getById(id));
  }

  collectionModalOpen(id) {
    this.setState({
      collectionModal: true,
      bookingId: id,
    });
  }

  collectionModalClose() {
    this.setState({
      bookingId: 0,
      collectionModal: false,
    });
  }

  collectionSuccess() {
    this.setState(
      {
        bookingId: 0,
        collectionModal: false,
        activePage: 1,
      },
      () => {
        this.getList();
      },
    );
  }

  detailsModalOpen(item) {
    console.log(item.length);
    this.setState({
      detailsModal: true,
      detailsModalResult: item,
    });
  }

  detailsModalClose() {
    this.setState({
      detailsModal: false,
    });
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  componentDidMount() {
    this.toDay();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("sss", nextProps);
    if (nextProps.refreshList == true || nextProps.redirect == true) {
      this.setState(
        {
          activePage: 1,
        },
        () => {
          this.getList();
        },
      );
    }

    if (
      nextProps.activePage != "" &&
      nextProps.totalItemsCount != "" &&
      nextProps.limit != ""
    ) {
      this.handlePageStates(
        nextProps.activePage,
        nextProps.totalItemsCount,
        this.state.limit,
      );
    }

    if (nextProps.editSet == true) {
      this.showKitDetails(nextProps.set);
    }
  }

  render() {
    const { collectionList, loading } = this.props;
    if (!this.state.user) {
      return <Redirect to="/" />;
    }
    if (this.state.user.data.user_role !== "admin") {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <div>
        </div>
        <Navbar activePage="dashboard" />
        <main className="offset">
          <div className="container-fluid">
            <div className="row pt-4">
              <div className="col-lg-2 side-filters">
                <span className="side-heading">Dashboard</span>
                <DashboardSidebar
                  activePage="collection"
                  collectionCount={this.state.totalItemsCount}
                />
              </div>

              <div className="col-lg-10">
                <div className="ground">
                  <div className="row">
                    <div className="col-md-4 col-sm-6">
                      <div className="sub-menu">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example">
                          <button
                            type="button"
                            className={
                              "btn btn-secondary " +
                              (this.state
                                .dayActive ==
                                "today"
                                ? "active "
                                : "")
                            }
                            onClick={() =>
                              this.toDay()
                            }>
                            Today
                          </button>
                          <button
                            type="button"
                            className={
                              "btn btn-secondary mx-1 " +
                              (this.state
                                .dayActive ==
                                "tomorrow"
                                ? "active "
                                : "")
                            }
                            onClick={() =>
                              this.nextDays()
                            }>
                            Tomorrow
                          </button>
                          <button
                            type="button"
                            className={
                              "btn btn-secondary " +
                              (this.state
                                .dayActive ==
                                "past"
                                ? "active "
                                : "")
                            }
                            onClick={() =>
                              this.pastBookings()
                            }>
                            Past Bookings
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <form className="form-search">
                        <Input
                          className="form-control mr-sm-2"
                          type="text"
                          placeholder="Search.."
                          value={this.state.search}
                          onChange={e =>
                            this.handleSearch(
                              e.target.value,
                            )
                          }
                        />
                      </form>
                    </div>
                    <div className="col-md-4 text-right">
                      {this.state.generationInProgress ? (
                        <p>Loading...</p>
                      ) : (
                        <button type="button" onClick={() => generateTemplate(collectionList)} className="btn btn-default"> Print </button>
                      )}
                    </div>
                  </div>
                  <div className="table-scroll mt-4">
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              minWidth: "200px",
                            }}>
                            Booking #
                          </th>
                          <th scope="col">
Event Name{" "}
</th>
                          <th scope="col">Set</th>
                          <th scope="col">Date</th>
                          <th scope="col">
                            Dispatched Boxes
                          </th>
                          <th
                            scope="col"
                            className="text-right"
                            style={{
                              maxWidth: "210px",
                            }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {collectionList &&
                          collectionList.map(item => (
                            <tr key={item._id}>
                              <td
                                width="200"
                                data-th="Booking #"
                                className="align-top">
                                <Link
                                  to={
                                    "/admin/booking/details/" +
                                    item
                                      .bookings
                                      ._id
                                  }>
                                  <span>
                                    <strong>
                                      {" "}
                                      {
                                        item
                                          .bookings
                                          .booking_id
                                      }{" "}
                                    </strong>
                                  </span>
                                </Link>
                              </td>
                              <td
                                data-th="Event Name"
                                className="align-top">
                                <div className="">
                                  {
                                    item
                                      .bookings
                                      .event_name
                                  }
                                </div>
                              </td>
                              <td
                                data-th="Set"
                                className="align-top">
                                <ul className="list">
                                  {item.bookingKitSetId &&
                                    item.bookingKitSetId.map(
                                      (
                                        kset,
                                        ind,
                                      ) => (
                                        <React.Fragment
                                          key={
                                            ind
                                          }>
                                          <li>
                                            <span
                                              className={
                                                "status circle mr-1 " +
                                                (kset.status ==
                                                  "close"
                                                  ? "green "
                                                  : "red ")
                                              }
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title=""
                                              data-original-title="Retuned"></span>
                                            <span>
                                              {
                                                kset
                                                  .setId[0]
                                                  .set_name
                                              }{" "}
                                            </span>
                                          </li>
                                        </React.Fragment>
                                      ),
                                    )}
                                </ul>
                              </td>
                              <td className="align-top">
                                {dateFormat(
                                  item
                                    .bookings
                                    .collection_date,
                                  "mmm dS, yyyy",
                                )}
                              </td>
                              <td
                                data-th="Dispatched Boxes"
                                className="align-top">
                                {item.bookingdispatches &&
                                  item.bookingdispatches[0].bookingdispatchboxes.map(
                                    (
                                      box,
                                      ind,
                                    ) => (
                                      <React.Fragment
                                        key={
                                          ind
                                        }>
                                        <div>
                                          {
                                            box.box_type
                                          }{" "}
                                          -{" "}
                                          {
                                            box.number_of_box
                                          }{" "}
                                          {box.number_of_box >
                                            1
                                            ? "Boxes"
                                            : "Box"}
                                        </div>
                                      </React.Fragment>
                                    ),
                                  )}
                              </td>

                              <td className="text-right align-top">
                                {item.bookings
                                  .status ==
                                  "dispatch" &&
                                  (this.state
                                    .dayActive ==
                                    "today" ||
                                    this
                                      .state
                                      .dayActive ==
                                    "past") &&
                                  item
                                    .bookingcollections
                                    .length ==
                                  0 && (
                                    <a
                                      href="javascript:;"
                                      onClick={() =>
                                        this.collectionModalOpen(
                                          item
                                            .bookings
                                            ._id,
                                        )
                                      }
                                      className="btn btn-primary ml-2">
                                      Collect
                                    </a>
                                  )}
                                {item.bookings
                                  .status ==
                                  "dispatch" &&
                                  (this.state
                                    .dayActive ==
                                    "today" ||
                                    this
                                      .state
                                      .dayActive ==
                                    "past") &&
                                  item
                                    .bookingcollections
                                    .length >
                                  0 && (
                                    <React.Fragment>
                                      <span className="capsule green-dim">
                                        Collected
                                      </span>
                                      <a
                                        href="javascript:;"
                                        onClick={() =>
                                          this.detailsModalOpen(
                                            item.bookingcollections,
                                          )
                                        }
                                        className="btn ml-2">
                                        Details
                                      </a>
                                    </React.Fragment>
                                  )}
                              </td>
                            </tr>
                          ))}
                        {collectionList &&
                          collectionList.length ==
                          0 &&
                          loading == false && (
                            <tr className="text-center">
                              <td colSpan="5">
                                No Record Found
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {collectionList &&
                  collectionList.length > 0 && (
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={
                        this.state.totalItemsCount
                      }
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(
                        this,
                      )}
                      itemClass="page-item"
                      linkClass="page-link"
                      innerClass="pagination justify-content-center"
                      activeLinkClass="active"
                      nextPageText="Next"
                      prevPageText="Previous"
                    />
                  )}

                <Modal
                  size="md"
                  backdrop="static"
                  isOpen={this.state.detailsModal}
                  toggle={() => this.detailsModalClose()}>
                  <ModalHeader
                    className=""
                    toggle={() => this.detailsModalClose()}>
                    Collected Details
                  </ModalHeader>
                  <ModalBody>
                    {this.state.detailsModalResult.map(
                      (details, index) => (
                        <React.Fragment key={index}>
                          <div className="form-group">
                            <strong>Courier</strong>
                            <div>
                              {details.courier}
                            </div>
                          </div>

                          <div className="form-group">
                            <strong>
                              Number of Boxes
                            </strong>
                            {details.bookingcollectionboxes &&
                              details.bookingcollectionboxes.map(
                                (box, ind) => (
                                  <React.Fragment
                                    key={
                                      ind
                                    }>
                                    <div className="form-group row">
                                      <div className="col">
                                        {
                                          box.box_type
                                        }{" "}
                                        -{" "}
                                        {
                                          box.number_of_box
                                        }{" "}
                                        {box.number_of_box >
                                          1
                                          ? "Boxes"
                                          : "Box"}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ),
                              )}
                          </div>

                          <div className="form-group">
                              <strong>Priority Level</strong>
                              <div>
                                  {details.priority_level}
                              </div>
                          </div>
                          
                          <div className="form-group">
                              <strong>Comment</strong>
                              <div>
                                  {details.comment}
                              </div>
                          </div>

                        </React.Fragment>
                      ),
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <div className="">
                      <button
                        className="btn btn-secondary"
                        onClick={() =>
                          this.detailsModalClose()
                        }>
                        Close
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>
                {/* Dispatch Details Modal */}

                <Modal
                  size="lg"
                  backdrop="static"
                  isOpen={this.state.kitSetDetailModal}
                  toggle={() =>
                    this.kitSetDetailModalClose()
                  }>
                  <ModalHeader
                    className=""
                    toggle={() =>
                      this.kitSetDetailModalClose()
                    }>
                    Set Details
                  </ModalHeader>
                  <ModalBody>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Item Code</th>
                          <th>Item Name</th>
                          <th>Qty</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.kitDetails
                          .setProductModules &&
                          this.state.kitDetails.setProductModules.map(
                            (product, ind) => (
                              <tr key={ind}>
                                <td>
                                  {
                                    product
                                      .productID
                                      .code
                                  }
                                </td>
                                <td>
                                  {
                                    product
                                      .productID
                                      .name
                                  }
                                </td>
                                <td>
                                  {
                                    product.quantity
                                  }
                                </td>
                              </tr>
                            ),
                          )}
                      </tbody>
                    </table>
                  </ModalBody>
                  <ModalFooter>
                    <div className="">
                      <button
                        className="btn btn-secondary"
                        onClick={() =>
                          this.kitSetDetailModalClose()
                        }>
                        Close
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>

                {/* Set Details Modal */}

                <Collection
                  bookingId={this.state.bookingId}
                  collectionModal={this.state.collectionModal}
                  collectionModalOpen={
                    this.collectionModalOpen
                  }
                  collectionModalClose={
                    this.collectionModalClose
                  }
                  collectionSuccess={this.collectionSuccess}
                />
              </div>
            </div>
            {/*<!--/row-->*/}
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    collectionList,
    activePage,
    totalItemsCount,
    limit,
    loading,
    redirect,
  } = state.rootReducer.collectionBookings;
  const { editSet, set } = state.rootReducer.sets;
  return {
    collectionList,
    loading,
    activePage,
    totalItemsCount,
    limit,
    redirect,
    editSet,
    set,
  };
}

export default connect(mapStateToProps)(BookingCollection);




















