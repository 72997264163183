import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { repsBookingActions, kitActions, bookingActions, setActions } from "../../../../actions";
// import { repsBookingService } from "../../../../services";
import dateFormat from "dateformat";



class Details extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            updateBookingInfo: {
                pickup_location_text:"",
                id: "",
                // hospitalId: "",
                // hospital_address: "",
                // po_number: "",
                // operation_name: "",
                shipping_charge: "",
                additional_notes: "",
                assigned_rep: "",
                additional_reps: [],
                delivery_date: "",
                delivery_date_slot: "",
                operation_date: "",
                operation_date_slot: "",
                collection_date: "",
                booking_type: "",
                kitData: [],
                kitSetData: [],
                status: ""
            },
            bookingId: "",
            // surgeonName:"",
            // operationName:"",
            // operationOther:"",
            // hospitalName:"",
            repName: "",
            repEmail: "",
            bookingInfo: {},
            selectedRep: [],
            selectedAdditionalRep: [],
            reps: [],
            additionalReps: [],
            // hospitals:[],
            // hospitalDetails:[],
            repsDetails: [],
            // hospitalAddress:[],
            // hospitalContactPerson: "",
            // hospitalContactPersonEmail: "",
            // hospitalContactPersonPhone: "",
            // searchHospital: "",
            searchReps: "",
            kits: [],
            kitListFlag: 0,
            selectedKit: [],
            kitWithKitSet: [],
            kitSetDetailModal: false,
            kitDetails: [],
            kitDetailsClickFlag: 0,
            dispatchModal: false,
            logList: [],
            error: "",
            isEditable: false,
            editablePoNumber: ""
        };

        this.getList = this.getList.bind(this);
        this.getSingleBooking = this.getSingleBooking.bind(this);
        // this.getHospitalAddressList = this.getHospitalAddressList.bind(this);
        // this.showHospitalsList = this.showHospitalsList.bind(this);

        // this.handleBookingAddress = this.handleBookingAddress.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.kitSetDetailModalClose = this.kitSetDetailModalClose.bind(this);
        this.handleReturn = this.handleReturn.bind(this);
        this.handleDispatch = this.handleDispatch.bind(this);
        this.dispatchModalOpen = this.dispatchModalOpen.bind(this);
        this.handlePoNumber = this.handlePoNumber.bind(this);


    }

    getSingleBooking(id) {
        this.props.dispatch(repsBookingActions.getById(id));
        this.props.dispatch(bookingActions.getLog({ bookingId: id }));
    }

    getList() {
        // this.props.dispatch(repsBookingActions.getHospitalsList({search: this.state.searchHospital}));
        this.props.dispatch(repsBookingActions.getRepsList({ search: this.state.searchReps }));
        this.props.dispatch(kitActions.kitListDropdown({ search: "" }));

    }

    // getHospitalAddressList(id) {
    //     this.props.dispatch(repsBookingActions.getHospitalDetailsById(id));
    // }

    setBookingData(data) {
        // var  hospitalName="";
        var defaultRepsLable = "";
        // var defaultHospital = this.state.hospitals.find(val => val.value === data.hospitalId);
        // if(defaultHospital)
        // {
        //     hospitalName=defaultHospital.label;
        // }
        var defaultReps = this.state.reps.find(val => val.value === data.assigned_rep);

        if (defaultReps) {
            defaultRepsLable = defaultReps.label;
        }
        var defaultAdditionalReps = [];
        data.additional_reps.map((repValue) => {
            defaultAdditionalReps.push(this.state.additionalReps.find(val => val.value === repValue));
        });

        const { updateBookingInfo } = this.state;
        updateBookingInfo["id"] = data["_id"];
        updateBookingInfo["event_name"]=data["event_name"];
        updateBookingInfo["name_of_location"]=data["name_of_location"];
        updateBookingInfo["address"]=data["address"];
        updateBookingInfo["postcode"]=data["postcode"];
        updateBookingInfo["phone_number"]=data["phone_number"];
        updateBookingInfo["operation_date_slot"] = data["operation_date_slot"];
        updateBookingInfo["po_number"]=data["po_number"];
        updateBookingInfo["shipping_charge"] = data["shipping_charge"];
        updateBookingInfo["delivery_date_slot"] = data["delivery_date_slot"];
        updateBookingInfo["assigned_rep"] = data["assigned_rep"];
        updateBookingInfo["delivery_date"] = dateFormat(data["delivery_date"], "yyyy-mm-dd");
        updateBookingInfo["operation_date"] = dateFormat(data["operation_date"], "yyyy-mm-dd");
        updateBookingInfo["collection_date"] = dateFormat(data["collection_date"], "yyyy-mm-dd");
        updateBookingInfo["additional_notes"] = data["additional_notes"];
        updateBookingInfo["booking_type"] = data["booking_type"];
        updateBookingInfo["pickup_location_text"]=data["pickup_location_text"]; 
        updateBookingInfo["status"] = data["status"];
        var tempAdditionalReps = [];
        data.additional_reps.map((item) => {
            tempAdditionalReps.push({ id: item });
        });
        updateBookingInfo["additional_reps"] = tempAdditionalReps;

        // this.setState({
        //     editablePoNumber: data["po_number"]
        // });

        //additional_reps
        // var surgeonName = "";
        // if(data.bookingSurgeonId.length > 0) {
        //     surgeonName = data.bookingSurgeonId[0].surgeonId[0].title+" "+data.bookingSurgeonId[0].surgeonId[0].first_name+" "+data.bookingSurgeonId[0].surgeonId[0].last_name;
        // }

        this.setState({
            updateBookingInfo,
            bookingInfo: data,
            bookingId: data.booking_id,
            // operationName: data.operation_name,
            // operationOther:data.other_opration,
            // surgeonName:  surgeonName,
            // hospitalName: hospitalName,
            repName: defaultRepsLable,
            repEmail: "",
            selectedAdditionalRep: defaultAdditionalReps
            // }, 
            // () => {
            //     this.getHospitalAddressList(data.hospitalId);
        });

        console.log("booking ", data);

        data.bookingKitId.map((item) => {

            this.props.dispatch(repsBookingActions.getKitSetByKit({ kitId: item.kitId }));
        });
    }

    // showHospitalsList(hospitalsList) {
    //     var tempHospitals = [];
    //     hospitalsList.map((item)=> {
    //         var option = {value: item._id, label: item.name};
    //         tempHospitals.push(option);
    //     });
    //     this.setState({
    //         hospitalDetails: hospitalsList,
    //         hospitals: tempHospitals,
    //     });
    // }

    // showAddressList(address) {
    //     var hospitalAddress = [];
    //     address.addresses.map((item) => {
    //         if(this.state.bookingInfo.hospital_address === item.name) {
    //             hospitalAddress.push({_id:item._id, name:item.name, checked: true});
    //         }

    //     });

    //     this.setState({
    //         hospitalAddress: hospitalAddress,
    //         hospitalContactPerson: address.contact_person_name,
    //         hospitalContactPersonEmail: address.email,
    //         hospitalContactPersonPhone: address.contact_person_phone_number
    //     }, () => {
    //         console.log(this.state.hospitalAddress);
    //     });
    // }

    showRepsList(repsList) {
        var tempRepsList = [];
        repsList.map((item) => {
            var option = { value: item._id, label: item.first_name + " " + item.last_name };
            tempRepsList.push(option);
        });
        this.setState({
            repsDetails: repsList,
            reps: tempRepsList,
            additionalReps: tempRepsList
        });

    }



    showKitsList(kitList) {
        var tempKitList = [];
        kitList.map((item) => {
            var option = { value: item._id, label: item.name };
            tempKitList.push(option);
        });
        this.setState({
            kits: tempKitList,
            kitListFlag: 1
        });
    }



    showKitsSetList(data) {
        const { kitWithKitSet, updateBookingInfo, bookingInfo } = this.state;
        var subKitSet = [];
        var lnt = 0;
        var hire_charge = "";
        var status = "";

        data[0]["kitsets"].map((kset) => {
            hire_charge = "";
            status = "";
            lnt = data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == updateBookingInfo.id).length;
            if (lnt > 0) {
                data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == updateBookingInfo.id).map((result) => {
                    hire_charge = result.hire_charge;
                    status = result.status;
                });

            }
            subKitSet.push({ set_name: kset.set_name, _id: kset._id, hire_charge: hire_charge, added: lnt, status: status });

        });


        var kitquantity = "";
        var kithire_charge = "";

        bookingInfo.bookingKitId.filter(item => item.kitId == data[0]._id).map((maini) => {
            kithire_charge = maini.hire_charge;
            kitquantity = maini.quantity;

        });
        kitWithKitSet.push({ name: data[0].name, _id: data[0]._id, hire_charge: kithire_charge, quantity: kitquantity, sets: subKitSet });

        var kits = this.state.kits;
        var slectedVal = data[0]._id;
        kits = kits.filter(rs => rs.value !== slectedVal);

        this.setState({
            kitWithKitSet,
            selectedKit: [],
            kits: kits
        });

    }

    isEditable() {
        this.setState({
            isEditable: true
        });
    }

    handlePoNumber(val) {
        this.setState({
            editablePoNumber: val
        });
    }

    // updatePONumber(bookingId) {
    //     repsBookingService.updatePoNumber({ id: bookingId, po_number: this.state.editablePoNumber}).then((res) => {
    //         if(res.status == 200) {
    //             this.setState({
    //                 isEditable: false,
    //                 kitWithKitSet: []
    //             });
    //             this.getSingleBooking(this.props.match.params.id);
    //         }
    //     }).catch((error) => {
    //         this.setState({
    //             error: error.common[0]
    //         });
    //     });
    // }



    // handleBookingAddress(value,checked,ind) {
    //     const { updateBookingInfo,hospitalAddress } = this.state;
    //     hospitalAddress.map((item)=> {
    //         item.checked=false;
    //     });
    //     hospitalAddress[ind].checked=true;
    //     this.setState({
    //         hospitalAddress
    //     });

    //     updateBookingInfo["hospital_address"] = value;
    //     this.setState({
    //         updateBookingInfo
    //     });

    // }


    kitSetDetailModalClose() {
        this.setState({
            kitSetDetailModal: false,
            kitSetData: []
        });
    }

    showKitDetails(kitDetails) {
        if (this.state.kitDetailsClickFlag == 1) {
            this.setState({
                kitDetails: kitDetails,
                kitSetDetailModal: true,
                kitDetailsClickFlag: 0
            });
        }

    }

    handleKitDetails(id) {
        this.setState({
            kitDetailsClickFlag: 1
        });

        this.props.dispatch(setActions.getById(id));

    }

    dispatchModalOpen() {
        this.setState({
            dispatchModal: true
        });
    }

    handleDispatch() {

        this.props.dispatch(repsBookingActions.updateStatus({ id: this.state.updateBookingInfo.id, status: "dispatch", log_by_id: this.state.user.data._id }));
    }

    dispatchModalClose() {
        this.setState({
            dispatchModal: false
        });
    }

    handleReturn() {

    }

    logListShow(logList) {

        this.setState({
            logList: logList,
        });

    }

    componentDidMount() {
        this.getList();
        const timer = setTimeout(() => {
            this.getSingleBooking(this.props.match.params.id);
        }, 1500);
        return () => clearTimeout(timer);

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);

        // if(nextProps.hospitalsList !== undefined) {
        //     this.showHospitalsList(nextProps.hospitalsList);
        // }

        if (nextProps.bookingInfo !== undefined) {
            this.setBookingData(nextProps.bookingInfo);
        }

        // if(nextProps.hospitalDetails !== undefined) {
        //     this.showAddressList(nextProps.hospitalDetails);
        // }

        if (nextProps.repsList !== undefined) {
            this.showRepsList(nextProps.repsList);
        }

        if (nextProps.kitListDropdown !== undefined && this.state.kitListFlag == 0) {
            this.showKitsList(nextProps.kitListDropdown);
        }

        if (nextProps.kitSetList !== undefined) {
            this.showKitsSetList(nextProps.kitSetList);
        }

        if (nextProps.editSet == true) {
            this.showKitDetails(nextProps.set);
        }

        if (nextProps.logList !== undefined) {

            this.logListShow(nextProps.logList);
        }



    }

    render() {
        const { redirect } = this.props;
        if (redirect) {
            return <Redirect to="/reps/open-booking" />;
        }
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "reps") {
            return <Redirect to="/" />;
        }



        return (
            <div>
                <Navbar activePage="bookings" />
                <div className="container">
                    <div className="d-flex  align-items-center justify-content-between">
                        <h1 className="date-scroll pt-4">
                            <Link to="/reps/open-booking" >
                                <i className="fa fa-long-arrow-left smbtn"></i> <strong>Review Booking</strong>
                            </Link>
                        </h1>

                    </div>
                    <div className="row pt-4 mobile_nocard">
                        <div className="col-md-8">
                            <div className="d-card">
                                <h4 className="mb-3">Event Details</h4>
                                <h4 className="mb-3">{this.state.bookingId}</h4>
                                <h5>{this.state.updateBookingInfo.event_name}</h5>
                                <div className="form-group">
                                    <div className="info">
                                        <div className="info-left">
                                            {/* { this.state.hospitalAddress.length > 0 && this.state.hospitalAddress.map((item) => ( */}
                                            <div className="info-left">
                                                {/* <strong> {this.state.hospitalContactPerson}</strong>  */}
                                                <div><small>Name of Location: {this.state.updateBookingInfo.name_of_location}</small></div>
                                                <div><small>Address: {this.state.updateBookingInfo.address}</small></div>
                                                <div><small>Postcode: {this.state.updateBookingInfo.postcode}</small></div>
                                                <div><small>Phone Number: {this.state.updateBookingInfo.phone_number}</small></div>
                                                {this.state.updateBookingInfo.pickup_location_text?
                                                    <div className="mt-3"><strong>Pickup Location:</strong> {this.state.updateBookingInfo.pickup_location_text}</div>
                                               :""
                                                }
                                            </div>
                                            {/* ))} */}

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="form-group">
                                    <span>Surgeon Name: <strong>{this.state.surgeonName}</strong> </span>
                                </div> */}
                                {/* <div className="form-group">
                                    <span>Operation Name: <strong>{ this.state.operationName == "Other"? this.state.operationOther :this.state.operationName}</strong> </span>
                                </div> */}
                            </div>

                            <div className="d-card mt-4">
                                <h4>Purchase/Hire Details</h4>


                                {/* <div className="form-group">
                                    <span>PO Number: <strong>{
                                        this.state.isEditable ?
                                            <React.Fragment>
                                                <Input
                                                    type="text"
                                                    style={{"width": "fit-content", "display": "inline-block"}}
                                                    value= {this.state.editablePoNumber}
                                                    onChange={(e) => this.handlePoNumber(e.target.value)}
                                                />
                                                <span className="small ml-2"><a href="javascript:;" onClick={()=>this.updatePONumber(this.state.updateBookingInfo.id)}>Save</a></span>
                                            </React.Fragment> :
                                            this.state.updateBookingInfo.po_number
                                    }</strong> </span>
                                    { this.state.updateBookingInfo.status != "cancel" && this.state.updateBookingInfo.status != "pending" && !this.state.isEditable &&
                                        <a href="javascript:" onClick={() => this.isEditable()} className="ml-1">
                                            <i className="fa fa-pencil"></i>
                                        </a>
                                    }
                                </div> */}

                                <div className="mt-4">
                                    <table className="table">
                                        <tbody>
                                            {this.state.kitWithKitSet && this.state.kitWithKitSet.map((kit, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <th colSpan="2">
                                                            <span className="mr-4">{kit.name}</span>
                                                            <span className="small">
                                                                {(kit.hire_charge > 0 || kit.quantity > 0) &&
                                                                    <React.Fragment>
                                                                        <span>Requested Quantity : {kit.quantity}</span> &nbsp;
                                                                        {/* <span>Hire Charge : {kit.hire_charge}</span> */}
                                                                    </React.Fragment>
                                                                }
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <h4 className="mt-5" style={{ "font-size": "16px" }}>Booking Timeline</h4>
                            <ul className="timeline mt-4 sml-text">
                                {this.state.logList && this.state.logList.map((item, index) => (
                                    <li key={index} data-dd={item}>
                                        <strong>{dateFormat(item.createdAt, "dS mmm , yyyy h:MM:ss")}</strong>
                                        {item.status == "updated" &&
                                            <p>Booking information is updated by {item.created_by.first_name} {item.created_by.last_name}</p>
                                        }

                                        {item.status == "pending" &&
                                            <p>{item.created_by.first_name} {item.created_by.last_name} requested for Booking </p>
                                        }

                                        {item.status == "confirm" &&
                                            <p>Booking is confirmed by  {item.created_by.first_name} {item.created_by.last_name}</p>
                                        }

                                        {item.status == "booked" &&
                                            <p>Booking added by {item.created_by.first_name} {item.created_by.last_name}</p>
                                        }

                                        {item.status == "returned" &&
                                            <p>Booking returned by {item.created_by.first_name} {item.created_by.last_name}</p>
                                        }

                                        {item.status == "rejected" &&
                                            <p>Booking rejected by {item.created_by.first_name} {item.created_by.last_name}</p>
                                        }

                                        {item.status == "dispatched" &&
                                            <p>Booking dispatched by {item.created_by.first_name} {item.created_by.last_name}</p>
                                        }

                                        {item.status == "collected" &&
                                            <p>Booking collected by {item.created_by.first_name} {item.created_by.last_name}</p>
                                        }

                                        {item.status == "repsUpdate" &&
                                            <p>Booking reps updated by {item.created_by.first_name} {item.created_by.last_name}</p>
                                        } 

                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="col-md-4">


                            <div className="d-card">
                                <h4>Important Dates</h4>
                                {this.state.updateBookingInfo.booking_type == "loan" &&
                                    <div className="form-group">
                                        <label>Delivery date</label>
                                        <div><strong>{dateFormat(this.state.updateBookingInfo.delivery_date, "mmm dS, yyyy")}  {this.state.updateBookingInfo.delivery_date_slot}</strong></div>
                                    </div>
                                }
                                {this.state.updateBookingInfo.booking_type != "loan" &&
                                    <div className="form-group">
                                        <label>Delivery date</label>
                                        <div><strong>{dateFormat(this.state.updateBookingInfo.delivery_date, "mmm dS, yyyy")} </strong></div>
                                    </div>
                                }
                                <div className="form-group">
                                    <label>Meeting date</label>
                                    <div><strong>{dateFormat(this.state.updateBookingInfo.operation_date, "mmm dS, yyyy")} {this.state.updateBookingInfo.operation_date_slot}</strong></div>
                                </div>

                                <div className="form-group">
                                    <label>Collection date</label>
                                    <div><strong>{dateFormat(this.state.updateBookingInfo.collection_date, "mmm dS, yyyy")} AM</strong></div>
                                </div>
                            </div>
                            {/* <div className="d-card mt-4">
                                <h4>Charges</h4>
                                <div className="form-group">
                                    <label>Shipping Charge</label>
                                    <strong> £  {this.state.updateBookingInfo.shipping_charge}</strong>
                                </div>
                            </div> */}

                            <div className="d-card mt-4">
                                <h4>Booked By</h4>
                                <div className="form-group">
                                    <div><strong> {this.state.repName}</strong></div>
                                    <div>{this.state.repEmail}</div>
                                </div>
                            </div>

                            <div className="d-card my-4">
                                <h4>Additional Notes</h4>
                                <div className="form-group">
                                    <small>
                                        {this.state.updateBookingInfo.additional_notes}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ADD FORM */}
                </div>

                <Modal size="ml" backdrop="static" isOpen={this.state.dispatchModal} toggle={() => this.dispatchModalClose()}>
                    <ModalHeader className="" toggle={() => this.dispatchModalClose()}>
                        Dispatch
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">Please confirm if you want to dispatch this booking?</div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.dispatchModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary" onClick={() => this.handleDispatch()}>Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* dispatch Details Modal */}


                <Modal size="lg" backdrop="static" isOpen={this.state.kitSetDetailModal} toggle={() => this.kitSetDetailModalClose()}>
                    <ModalHeader className="" toggle={() => this.kitSetDetailModalClose()}>
                        Set Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.kitDetails.setProductModules && this.state.kitDetails.setProductModules.map((product, ind) => (
                                    <tr key={ind}>
                                        <td>{product.productID.code}</td>
                                        <td>{product.productID.name}</td>
                                        <td>{product.quantity}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button className="btn btn-secondary" onClick={() => this.kitSetDetailModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Set Details Modal */}

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { bookingInfo, repsList, error, redirect, kitSetList } = state.rootReducer.repsBookings;
    const { kitListDropdown } = state.rootReducer.kits;
    const { editSet, set } = state.rootReducer.sets;
    const { logList } = state.rootReducer.bookings;
    return {
        // hospitalsList,
        bookingInfo,
        // hospitalDetails,      
        repsList,
        error,
        redirect,
        kitListDropdown,
        kitSetList,
        editSet,
        set,
        logList
    };
}

export default connect(mapStateToProps)(Details);