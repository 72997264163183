import { productConstants } from "../constants";

const initialState =  { loading: null, productsList: null, } || {};

export function products(state = initialState, action) {
    switch (action.type) {
    case productConstants.GETALLPRODUCTS_REQUEST:
        return {
            loading: true
        };
    case productConstants.GETALLPRODUCTS_SUCCESS:
        return {
            loading: false,
            productsList: action.productsList.data,
            activePage: action.productsList.page,
            totalItemsCount: action.productsList.total_count,
            limit: action.productsList.page_count
        };
    case productConstants.GETALLPRODUCTS_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case productConstants.PRODUCTADD_REQUEST:
        return {
            loading: true,
            productsList: state.productsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case productConstants.PRODUCTADD_SUCCESS:
        return {
            loading: false,
            productsList: action.data,
            modal: false,
            refreshList:true
        };
    case productConstants.PRODUCTADD_FAILURE:
        return { 
            loading: false,
            error: action.error,
            productsList: state.productsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case productConstants.PRODUCTEDIT_REQUEST:
        return {
            loading: true,
            productsList: state.productsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case productConstants.PRODUCTEDIT_SUCCESS:
        return {
            loading: false,
            productsList: state.productsList, 
            product: action.product.data,
            editModal: true,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case productConstants.PRODUCTEDIT_FAILURE:
        return { 
            loading: false,
            error: action.error,
            productsList: state.productsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case productConstants.PRODUCTUPDATE_REQUEST:
        return {
            loading: true,
            productsList: state.productsList,
            product: state.product,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case productConstants.PRODUCTUPDATE_SUCCESS:
        return {
            loading: false,
            productsList: state.productsList, 
            editModal: false,
            refreshList: true,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case productConstants.PRODUCTUPDATE_FAILURE:
        return { 
            loading: false,
            error: action.error,
            productsList: state.productsList,
            product: state.product,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case productConstants.DELETE_REQUEST:
        // add 'deleting:true' property to user being deleted
        return {
            ...state,
            loading: true,
            productsList: state.productsList.map(product =>
                product._id === action.id
                    ? { ...product, deleting: true }
                    : product
            ),
            editModal: false
        };
    case productConstants.DELETE_SUCCESS:
        // remove deleted user from state
        return {
            loading: false,
            deleteModal:false,
            refreshList:true,
            editModal: false
        };
    case productConstants.DELETE_FAILURE:
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        return {
            ...state,
            loading: false,
            productsList: state.productsList.map(product => {
                if (product._id === action.id) {
                    // make copy of user without 'deleting:true' property
                    // const { deleting, ...userCopy } = user;
                    const { ...productCopy } = product;
                    // return copy of user with 'deleteError:[error]' property
                    return { ...productCopy, deleteError: action.error };
                }

                return product;
            }),
            editModal: false,
            error: action.error
        };
    default:
        return state;
    }
}