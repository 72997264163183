import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import dateFormat from "dateformat";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DatePicker from "react-datepicker";
import { bookingService } from "../../../../services/booking.services";

import {
    bookingActions,
    kitActions,
    setActions,
} from "../../../../actions";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import KitModal from "../Kits/loanCharge";



const deliverySlots = [
    { value: "Pre 9AM", label: "Pre 9AM" },
    { value: "Pre 10AM", label: "Pre 10AM" },
    { value: "Pre 12", label: "Pre 12" },
    { value: "Same Day", label: "Same Day" },
];

const operationSlots = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
    { value: "TBD", label: "TBD" },
];

const dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class Add extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            kitSetErrorFlag: 0,
            bookingInfo: {
                booking_type: "loan",
                // po_number: "",
                event_name: "",
                name_of_location: "",
                address: "",
                postcode: "",
                phone_number: "",
                delivery_address: "",
                collection_address: "",
                contact_detail: "",
                shipping_charge: 100,
                additional_notes: "",
                assigned_rep: "",
                additional_reps: [],
                delivery_date: "",
                delivery_date_slot: "Pre 12",
                operation_date: "",
                operation_date_slot: "AM",
                collection_date: "",
                return_date: "",
                kitData: [],
                kitSetData: [],
                log_status: "booked",
                log_by_id: "",
                mail_sent: false,
                created_by: "",
                // po_check: false,
                pickup_location_checkbox: false,
                pickup_location_text: "",
                isOpen: false,
            },
            selectedDeliverySlot: [{ value: "Pre 12", label: "Pre 12" }],
            selectedOperationSlots: [{ value: "AM", label: "AM" }],
            selectedRep: [],
            selectedAdditionalRep: [],
            reps: [],
            additionalReps: [],
            repsDetails: [],
            searchReps: "",
            searchOps: "",
            kits: [],
            kitListFlag: 0,
            selectedKit: [],
            kitWithKitSet: [],
            kitSetDetailModal: false,
            kitDetails: [],
            kitDetailsClickFlag: 0,
            // bookingType: [
            //     { value: "loan", label: "Loan" },
            //     { value: "consignment", label: "Consignment" },
            // ],
            // selectedBookingType: [{ value: "loan", label: "Loan" }],
            boookingType: "loan",
            singleSelectedKitId: 0,
            Allkits: [],
            cartFlag: false,
            cartItemCount: 0,
            quantityError: false,
        };

        this.getList = this.getList.bind(this);
        this.handleBookingInfo = this.handleBookingInfo.bind(this);
        this.setSelectedOptionDeliverySlot =
            this.setSelectedOptionDeliverySlot.bind(this);
        this.setSelectedOptionOperationSlots =
            this.setSelectedOptionOperationSlots.bind(this);
        this.setSelectedOptionRep = this.setSelectedOptionRep.bind(this);
        this.setSelectedOptionAdditionalRep =
            this.setSelectedOptionAdditionalRep.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setSelectedOptionKit = this.setSelectedOptionKit.bind(this);
        this.handleAddKit = this.handleAddKit.bind(this);
        this.handelAddRemovSet = this.handelAddRemovSet.bind(this);
        this.handleHireCharge = this.handleHireCharge.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.kitSetDetailModalClose = this.kitSetDetailModalClose.bind(this);
        // this.setSelectedOptionBookingType =
        //     this.setSelectedOptionBookingType.bind(this);
        this.checkNextAvalability = this.checkNextAvalability.bind(this);
    }

    openModal = () => {
        this.setState({ isOpen: true });
    };

    closeModal = () => {
        this.setState({ isOpen: false });
    };

    getList() {

        this.props.dispatch(
            bookingActions.getRepsList({ search: this.state.searchReps }),
        );
        this.props.dispatch(kitActions.kitListDropdown({ search: "" }));
    }

    cartToggle() {
        this.setState({
            cartFlag: !this.state.cartFlag,
        });
    }

    showRepsList(repsList) {
        var tempRepsList = [];
        repsList.map(item => {
            var option = {
                value: item._id,
                label: item.first_name + " " + item.last_name,
            };
            tempRepsList.push(option);
        });
        this.setState({
            repsDetails: repsList,
            reps: tempRepsList,
            additionalReps: tempRepsList,
        });
    }

    // setSelectedOptionBookingType(e) {
    //     var selectedBookingType = [{ value: e.value, label: e.label }];
    //     console.log(selectedBookingType);
    //     this.setState({
    //          selectedBookingType: selectedBookingType,
    //         bookingInfo: {
    //             ...this.state.bookingInfo,
    //             booking_type: e.value,
    //         },
    //     });
    // }


    setSelectedOptionRep(e) {
        var selectedRep = [{ value: e.value, label: e.label }];
        this.setState({
            selectedRep: selectedRep,
            bookingInfo: {
                ...this.state.bookingInfo,
                assigned_rep: e.value,
            },
        });
    }

    setSelectedOptionAdditionalRep = selectedAdditionalRep => {
        var tempAdditionalReps = [];
        selectedAdditionalRep.map(item => {
            tempAdditionalReps.push({ id: item.value });
        });
        this.setState(
            {
                selectedAdditionalRep: selectedAdditionalRep,
                bookingInfo: {
                    ...this.state.bookingInfo,
                    additional_reps: tempAdditionalReps,
                },
            },
            () => {
                console.log(this.state.bookingInfo.additional_reps);
            },
        );
    };

    setSelectedOptionKit(e) {
        console.log("kit", e);
        var selectedKit = [{ value: e.value, label: e.label }];
        this.setState(
            {
                selectedKit: selectedKit,
                cartFlag: false,
            },
            () => {
                this.handleAddKit();
            },
        );
    }

    showKitsList(kitList) {
        var tempKitList = [];
        kitList.map(item => {
            var option = { value: item._id, label: item.name };
            tempKitList.push(option);
        });
        this.setState({
            kits: tempKitList,
            Allkits: tempKitList,
            kitListFlag: 1,
        });
    }

    handleAddKit() {

        if (this.state.selectedKit.length > 0) {
            this.setState({
                kitSetErrorFlag: 0,
            });
            this.props.dispatch(
                bookingActions.getKitSetByKit({
                    kitId: this.state.selectedKit[0].value,
                }),
            );
            this.setState({
                singleSelectedKitId: this.state.selectedKit[0].value,
            });
        }
        else {

            this.props.dispatch(
                bookingActions.getKitSetByKit({
                    kitId: this.state.singleSelectedKitId
                }),
            );

        }
    }

    async showKitsSetList(data) {
        const { kitWithKitSet, singleSelectedKitId } = this.state;
        let loan_charge = 695;

        var subKitSet = [];
        data[0]["kitsets"].map(kset => {
            subKitSet.push({
                bookingKitSets: data[0]["bookingKitSets"],
                set_name: kset.set_name,
                _id: kset._id,
                hire_charge: loan_charge,
                added: 0,
                availability: [],
                setDoc_urlpath:kset?.setDoc_urlpath,
                setDocument:kset?.setDocuments
            });
        });

        console.log("subKitSet 1", subKitSet);

        if (kitWithKitSet.filter(item => item._id == data[0]._id) == 0) {
            kitWithKitSet.push({
                kitDocument: data[0].kitDocument,
                cartAdded: 0,
                name: data[0].name,
                _id: data[0]._id,
                sets: subKitSet,
            });
        }
        else {
            kitWithKitSet.map(function (kits) {
                if (kits._id == data[0]._id) {

                    kits.sets.map(function (subSets) {
                        if (subSets.added == 0) {
                            subSets.hire_charge = loan_charge;
                        }

                    });

                }

            });

        }

        //var kits=this.state.kits;
        //var slectedVal=data[0]._id;
        //kits=kits.filter(rs => rs.value !== slectedVal);
        var kits = this.state.Allkits;
        kits = kits.filter(rs => rs.value !== singleSelectedKitId);

        this.setState({
            kitWithKitSet,
            selectedKit: [],
            kits: kits,
        });
    }

    handelRemovKitFromCart(kiIndex, kit_id) {
        const { kitWithKitSet } = this.state;
        kitWithKitSet.map(item => {
            if (item._id == kit_id) {
                item.sets.map(subItem => {
                    subItem.added = 0;
                });
                item.cartAdded = 0;
            }
        });

        this.setState(
            {
                kitWithKitSet,
            },
            () => {
                this.calculateItemCount();
            },
        );
    }

    calculateItemCount() {
        const { kitWithKitSet } = this.state;
        let cartItemCount = 0;

        cartItemCount = kitWithKitSet
            .map(datum => datum.cartAdded)
            .reduce((a, b) => a + b);
        this.setState({
            cartItemCount: cartItemCount,
        });
    }

    handelRemovKit(kiIndex, kit_id) {
        console.log(kiIndex, kit_id);

        //const { kitWithKitSet,kits } =this.state;
        //const kitValue=kitWithKitSet[kiIndex]._id;
        //const kitLable=kitWithKitSet[kiIndex].name;
        //kitWithKitSet.splice(kiIndex, 1);

        //kits.push({value:kitValue,label:kitLable});

        /*this.setState({
            kitWithKitSet,
            kits
        });*/
        this.setState({
            singleSelectedKitId: 0,
            kits: this.state.Allkits,
        });
    }

    handelAddRemovSet(kitId, setId, flag) {
        /*const { kitWithKitSet } =this.state;
        kitWithKitSet[kiIndex].sets[setIndex].added=flag;

        */
        const { kitWithKitSet } = this.state;
        kitWithKitSet.map(item => {
            if (item._id == kitId) {
                item.sets.map(subItem => {
                    if (subItem._id == setId) {
                        subItem.added = flag;
                    }
                });
                if (item.sets.filter(rs => rs.added === 1).length) {
                    item.cartAdded = item.sets.filter(
                        rs => rs.added === 1,
                    ).length;
                } else {
                    item.cartAdded = 0;
                }
            }
        });

        this.setState(
            {
                kitWithKitSet,
                kitSetErrorFlag: 0,
            },
            () => {
                this.calculateItemCount();
            },
        );
    }

    handleHireCharge(kitId, setId, val) {
        console.log(kitId, setId);

        const { kitWithKitSet } = this.state;
        if (val != "" && val < 0) {
            val = "";
        }
        //kitWithKitSet[kiIndex].sets[setIndex].hire_charge=val;

        kitWithKitSet.map(item => {
            if (item._id == kitId) {
                item.sets.map(subItem => {
                    if (subItem._id == setId) {
                        subItem.hire_charge = val;
                    }
                });
            }
        });

        this.setState({
            kitWithKitSet,
        });
    }

    handleBookingInfo(name, value) {
        const { bookingInfo } = this.state;
        if (
            name == "delivery_date" ||
            name == "collection_date" ||
            name == "operation_date"
        ) {
            let date = dateFormat(value, "yyyy-mm-dd");
            bookingService
                .checkHolidayDate({ date: date })
                .then(response => {
                    //console.log("checkholiday", response);
                    if (response.data && response.data.length !== 0) {
                        if (name === "delivery_date") {
                            this.setState(prevState => ({
                                deliveryDateError:
                                    "Delivery Date falls on a holiday.",
                                operationDateError:
                                    prevState.operationDateError,
                                collectionDateError:
                                    prevState.collectionDateError,
                            }));
                        } else if (name === "collection_date") {
                            this.setState(prevState => ({
                                deliveryDateError: prevState.deliveryDateError,
                                collectionDateError:
                                    "Collection Date falls on a holiday.",
                                operationDateError:
                                    prevState.operationDateError,
                            }));
                        }
                        else if (name === "operation_date") {
                            this.setState(prevState => ({
                                deliveryDateError: prevState.deliveryDateError,
                                collectionDateError:
                                    prevState.collectionDateError,
                                operationDateError:
                                    "Meeting Date falls on a holiday.",
                            }));
                        }
                    } else {
                        this.setState(prevState => ({
                            deliveryDateError:
                                name === "delivery_date"
                                    ? ""
                                    : prevState.deliveryDateError,
                            operationDateError:
                                name === "operation_date"
                                    ? ""
                                    : prevState.operationDateError,
                            collectionDateError:
                                name === "collection_date"
                                    ? ""
                                    : prevState.collectionDateError,
                        }));
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }

        if (name == "shipping_charge" && value != "" && value < 0) {
            value = "";
        }


        bookingInfo[name] = value;
        if (name == "event_name") {
            bookingInfo["event_name"] = value;
        }
        if (name == "name_of_location") {
            bookingInfo["name_of_location"] = value;
        }
        if (name == "address") {
            bookingInfo["address"] = value;
        }
        if (name == "postcode") {
            bookingInfo["postcode"] = value;
        }
        if (name == "phone_number") {
            bookingInfo["phone_number"] = value;
        }
        if (name == "delivery_address") {
            bookingInfo["delivery_address"] = value;
        }
        if (name == "collection_address") {
            bookingInfo["collection_address"] = value;
        }
        if (name == "contact_detail") {
            bookingInfo["contact_detail"] = value;
        }


        // if (name == "po_check" && value == true) {
        //     bookingInfo["po_number"] = "";
        // }
        // if (name == "po_number" && value == "") {
        //     bookingInfo["po_check"] = true;
        // }
        // else if (name == "po_number" && value != "") {
        //     bookingInfo["po_check"] = false;
        // }

        this.setState(
            {
                bookingInfo,
            },
            () => {
                console.log(this.state.bookingInfo);
            },
        );
    }


    setSelectedOptionDeliverySlot(e) {
        var selectedDeliverySlot = [{ value: e.value, label: e.label }];
        this.setState({
            selectedDeliverySlot: selectedDeliverySlot,
            bookingInfo: {
                ...this.state.bookingInfo,
                delivery_date_slot: e.value,
            },
        });
    }

    setSelectedOptionOperationSlots(e) {
        var selectedOperationSlots = [{ value: e.value, label: e.label }];
        this.setState({
            selectedOperationSlots: selectedOperationSlots,
            bookingInfo: {
                ...this.state.bookingInfo,
                operation_date_slot: e.value,
            },
        });
    }


    sendEmailConfirmHandle() {
        const { bookingInfo } = this.state;
        bookingInfo["mail_sent"] = true;
        this.setState(
            {
                bookingInfo,
            },
            () => {
                this.handleSubmit();
            },
        );
    }

    saveChangeHandle() {
        const { bookingInfo } = this.state;
        bookingInfo["mail_sent"] = false;
        this.setState(
            {
                bookingInfo,
            },
            () => {
                this.handleSubmit();
            },
        );
    }

    handleSubmit() {
        const {
            bookingInfo,
            kitWithKitSet,
        } = this.state;
        var kitError = 0;
        var kitData = [];
        var kitSetData = [];
        let kitSetFlag = 0;
        var kitSetErrorFlag = 0;

        kitWithKitSet.map(item => {
            //kitData.push(item._id);

            item.sets
                .filter(rs => rs.added === 1)
                .map(sub => {
                    kitSetFlag = 1;
                    kitError = 1;
                    kitSetData.push({
                        id: sub._id,
                        hire_charge: sub.hire_charge,
                        kitId: item._id,
                    });
                });
            if (item.sets.filter(rs => rs.added === 1).length) {
                kitData.push(item._id);
            }
        });

        bookingInfo["return_date"] = "";

        if (bookingInfo["collection_date"] != "") {
            var return_date = new Date(bookingInfo["collection_date"]);
            return_date = return_date.setDate(return_date.getDate() + 1);
            bookingInfo["return_date"] = dateFormat(return_date, "yyyy-mm-dd");
        }
        if (bookingInfo["booking_type"] == "consignment") {
            bookingInfo["operation_date"] = bookingInfo["collection_date"];
        }

        if( bookingInfo["pickup_location_checkbox"] == false){
            bookingInfo["pickup_location_text"] = "";
         }

        bookingInfo["kitData"] = kitData;
        bookingInfo["kitSetData"] = kitSetData;

        // console.log(bookingInfo["kitData"].length);
        console.log(kitSetFlag);

        if (
           bookingInfo["name_of_location"] != "" &&
            bookingInfo["address"] != "" &&
            bookingInfo["postcode"] != "" &&
            bookingInfo["phone_number"] != "" &&
            bookingInfo["delivery_address"] != "" &&
            bookingInfo["event_name"] != "" &&
            bookingInfo["collection_address"] != "" &&
            bookingInfo["contact_detail"] != "" &&
            bookingInfo["booking_type"]!= "" &&
            // bookingInfo["po_number"] != "" &&
            bookingInfo["delivery_date"] != "" &&
            bookingInfo["operation_date"] != "" &&
            bookingInfo["collection_date"] != "" &&
            bookingInfo["kitData"].length != 0 &&
            bookingInfo["kitSetData"].length != 0
        ) {
            kitSetErrorFlag = 1;
            this.setState({
                kitSetErrorFlag: 0,
            });
        } else {
            kitSetErrorFlag = 0;
            if (kitError == 1) {
                kitSetErrorFlag = 1;
                this.setState({
                    kitSetErrorFlag: 0,
                });
            } else {
                this.setState({
                    kitSetErrorFlag: 1,
                });
            }
        }

        bookingInfo["log_by_id"] = this.state.user.data._id;
        if (kitSetErrorFlag == 1) {
            var datass = {
                additional_notes: bookingInfo["additional_notes"],
                additional_reps: bookingInfo["additional_reps"],
                assigned_rep: bookingInfo["assigned_rep"],
                booking_type: bookingInfo["booking_type"],
                collection_date: "",
                delivery_date: "",
                delivery_date_slot: bookingInfo["delivery_date_slot"],
                kitData: bookingInfo["kitData"],
                kitSetData: bookingInfo["kitSetData"],
                log_by_id: bookingInfo["log_by_id"],
                log_status: bookingInfo["log_status"],
                operation_date: "",
                operation_date_slot: bookingInfo["operation_date_slot"],
                // po_number: bookingInfo["po_number"],

                event_name: bookingInfo["event_name"],
                name_of_location:  bookingInfo["name_of_location"],
                address: bookingInfo["address"],
                postcode:  bookingInfo["postcode"],
                phone_number:  bookingInfo["phone_number"],
                delivery_address: bookingInfo["delivery_address"],
                collection_address: bookingInfo["collection_address"],
                contact_detail: bookingInfo["contact_detail"],

                return_date: bookingInfo["return_date"],
                shipping_charge: bookingInfo["shipping_charge"],
                mail_sent: bookingInfo["mail_sent"],
                created_by: this.state.user.data._id,
                pickup_location_checkbox: bookingInfo["pickup_location_checkbox"],
                pickup_location_text: bookingInfo["pickup_location_text"],
                // po_check: bookingInfo["po_check"],
            };

            if (bookingInfo["collection_date"] != "") {
                datass["collection_date"] = dateFormat(
                    bookingInfo["collection_date"],
                    "yyyy-mm-dd",
                );
            }
            if (bookingInfo["delivery_date"] != "") {
                datass["delivery_date"] = dateFormat(
                    bookingInfo["delivery_date"],
                    "yyyy-mm-dd",
                );
            }
            if (bookingInfo["operation_date"] != "") {
                datass["operation_date"] = dateFormat(
                    bookingInfo["operation_date"],
                    "yyyy-mm-dd",
                );
            }
            console.log("dataaaaassssssssssa", datass);
            this.props.dispatch(bookingActions.add(datass));
        }
    }

    kitSetDetailModalClose() {
        this.setState({
            kitSetDetailModal: false,
            kitSetData: [],
        });
    }

    showKitDetails(kitDetails) {
        if (this.state.kitDetailsClickFlag == 1) {
            this.setState({
                kitDetails: kitDetails,
                kitSetDetailModal: true,
                kitDetailsClickFlag: 0,
            });
        }
    }

    handleKitDetails(id) {
        this.setState({
            kitDetailsClickFlag: 1,
        });

        this.props.dispatch(setActions.getById(id));
    }

    checkAvalability(
        bookingKitSets,
        setd,
        setId,
        index,
        ind,
        kitId,
        showItems,
    ) {
        const { bookingInfo } = this.state;
        var htm = [];

        var currentDate = bookingInfo.delivery_date;
        var delivery_date = bookingInfo.delivery_date;
        var endDate = bookingInfo.collection_date;
        var falgForHtm = 0;
        var date = "";
        var separator = "-";
        var fulldate = "";
        var nextDate = [];
        var holiday = "No";
        var isConsinment = 0;

        if (currentDate != "" && endDate != "") {
            currentDate = new Date(currentDate);
            endDate = new Date(endDate);
            var dates = [];
            while (currentDate <= endDate) {
                date = `${currentDate.getFullYear()}${separator}${currentDate.getMonth() + 1 < 10
                    ? `0${currentDate.getMonth() + 1}`
                    : `${currentDate.getMonth() + 1}`
                    }${separator}${currentDate.getDate() < 10
                        ? `0${currentDate.getDate()}`
                        : `${currentDate.getDate()}`
                    }`;

                dates.push({ fulldate: date });
                currentDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 1, // Will increase month if over range
                );
            }

            if (bookingKitSets.length == 0) {
                htm = <span className="capsule green-dim">Available</span>;

                currentDate = new Date(delivery_date);
                endDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 6,
                );

                while (currentDate <= endDate) {
                    holiday = "No";
                    if (
                        currentDate.getDay() == 6 ||
                        currentDate.getDay() == 0
                    ) {
                        holiday = "holiday";
                    }

                    nextDate.push({
                        date:
                            currentDate.getDate() +
                            " " +
                            dayName[currentDate.getDay()],
                        holiday: holiday,
                        status: "",
                    });

                    currentDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() + 1, // Will increase month if over range
                    );
                }
            } else {
                dates &&
                    dates.map(diffdate => {
                        fulldate = diffdate.fulldate;

                        bookingKitSets
                            .filter(rs => rs.setId == setId)
                            .map(bookingSt => {
                                if (
                                    bookingSt.bookings &&
                                    bookingSt.bookings.filter(
                                        rs =>
                                            rs.delivery_date <= fulldate &&
                                            rs.collection_date >= fulldate,
                                    ).length
                                ) {
                                    falgForHtm += 1;
                                }
                                if (
                                    bookingSt.bookings &&
                                    bookingSt.bookings.filter(
                                        rs =>
                                            rs.booking_type != "loan" &&
                                            rs.delivery_date <= fulldate &&
                                            rs.collection_date >= fulldate,
                                    ).length
                                ) {
                                    isConsinment = 1;
                                }
                            });
                    });

                if (falgForHtm > 0) {
                    htm = (
                        <span className="capsule red-dim">Not Available</span>
                    );
                }

                if (falgForHtm == 0) {
                    htm = <span className="capsule green-dim">Available</span>;
                }

                currentDate = new Date(delivery_date);

                endDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 6,
                );

                while (currentDate <= endDate) {
                    holiday = "No";
                    falgForHtm = 0;
                    if (
                        currentDate.getDay() == 6 ||
                        currentDate.getDay() == 0
                    ) {
                        holiday = "holiday";
                    }

                    fulldate = `${currentDate.getFullYear()}${separator}${currentDate.getMonth() + 1 < 10
                        ? `0${currentDate.getMonth() + 1}`
                        : `${currentDate.getMonth() + 1}`
                        }${separator}${currentDate.getDate() < 10
                            ? `0${currentDate.getDate()}`
                            : `${currentDate.getDate()}`
                        }`;

                    bookingKitSets
                        .filter(rs => rs.setId == setId)
                        .map(bookingSt => {
                            if (
                                bookingSt.bookings &&
                                bookingSt.bookings.filter(
                                    rs =>
                                        rs.delivery_date <= fulldate &&
                                        rs.collection_date >= fulldate,
                                ).length
                            ) {
                                falgForHtm += 1;
                            }
                        });

                    if (falgForHtm > 0) {
                        nextDate.push({
                            date:
                                currentDate.getDate() +
                                " " +
                                dayName[currentDate.getDay()],
                            holiday: "na",
                            status: " Not Available",
                        });
                    }

                    if (falgForHtm == 0) {
                        nextDate.push({
                            date:
                                currentDate.getDate() +
                                " " +
                                dayName[currentDate.getDay()],
                            holiday: holiday,
                            status: "Available",
                        });
                    }
                    currentDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() + 1, // Will increase month if over range
                    );
                }
            }
        } else {
            htm = <span className="capsule green-dim">Available</span>;
        }

        if (showItems == 2) {
            showItems = setd.added;
        }

        if (isConsinment == 0 && setd.added == showItems) {
            return (
                <React.Fragment>
                    <td>
                        <div className="mb-2">
                            {/* <a href="javascript:;" onClick={()=>this.handleKitDetails(setd._id)} data-toggle="modal" data-target="#kitDetails">{setd.set_name}</a> */}
                            <span>{setd.set_name}</span>
                        </div>
                        {htm}
                        <span className="mr-4 kitname">
                            {setd?.setDoc_urlpath && (
                                    <span className="ml-1">
                                        <a
                                            id={
                                                "UncontrolledTooltipPdf1" +
                                                index
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            className="download-info badge1 badge-info1"
                                            href={
                                                process
                                                    .env
                                                    .REACT_APP_APIURL + setd?.setDoc_urlpath
                                            }>
                                            Download Set Pdf
                                        </a>

                                    </span>
                                )}
                        </span>
                        <div key="2">
                            <div
                                className="table-chart"
                                style={{
                                    width: "auto",
                                    display: "inline-block",
                                }}>
                                <table className="mt-2 main-table table-sm">
                                    <thead>
                                        <tr
                                            style={{
                                                borderTop: "1px solid #DDD",
                                            }}>
                                            {nextDate &&
                                                nextDate.map((next, indxx) => (
                                                    <th
                                                        key={indxx}
                                                        in={indxx}
                                                        className={
                                                            (indxx == 0
                                                                ? " first_col "
                                                                : " ") +
                                                            next.holiday
                                                        }
                                                        style={{
                                                            width: "30px",
                                                        }}>
                                                        {next.date}
                                                    </th>
                                                ))}
                                            {/*<th className="first_col" style={{width:"30px"}}>24 Tue</th>
                                            <th>25 Tue</th>
                                            <th className="holiday">26 Tue</th>
                                            <th className="tdy">27 Tue</th>
                                            <th>28 Tue</th>
                                            <th>29 Tue</th>
                                            <th>30 Tue</th>*/}
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </td>
                     <td style={{ width: "120px", "vertical-align": "bottom" }}>
                        {/*<label>Hire Charges (Per Set) </label>
                        <Input
                            type="number"
                            min="0"
                            className="form-control"
                            value={setd.hire_charge}
                            onChange={e =>
                                this.handleHireCharge(
                                    kitId,
                                    setId,
                                    e.target.value,
                                )
                            }
                            name=""
                            placeholder="£"
                        />*/}
                    </td> 

                    <td className="text-right align-bottom" width="120">
                        {setd.added == 0 && (
                            <a
                                href="javascript:;"
                                onClick={() =>
                                    this.handelAddRemovSet(kitId, setId, 1)
                                }
                                className="btn btn-primary addnow addnow1">
                                <i className="fa fa-plus"></i>{" "}
                                <span className="d-none d-md-block">Add</span>
                            </a>
                        )}

                        {setd.added == 1 && (
                            <div
                                id="added"
                                className="mb-3 position-relative"
                                style={{ width: "110px" }}>
                                <span className="addmsg">
                                    <i className="fa fa-check"></i> Added{" "}
                                </span>
                                <a
                                    href="javascript:"
                                    onClick={() =>
                                        this.handelAddRemovSet(kitId, setId, 0)
                                    }
                                    className="ml-1">
                                    <i className="fa fa-trash"></i>
                                </a>
                            </div>
                        )}
                    </td>
                </React.Fragment>
            );
        }
    }

    checkAvalabilityForConnsignment(kit, index) {
        const { bookingInfo } = this.state;

        var currentDate = bookingInfo.delivery_date;
        var endDate = bookingInfo.collection_date;
        var date = "";
        var dates = [];
        var separator = "-";
        var fulldate = "";
        var isConsinment = 0;
        var totalConsinment = 0;

        kit.sets.map((setd, ind) => {
            console.log(ind, "test ", index);

            currentDate = bookingInfo.delivery_date;
            endDate = bookingInfo.collection_date;
            date = "";
            dates = [];
            separator = "-";
            fulldate = "";
            isConsinment = 0;

            if (currentDate != "" && endDate != "") {
                currentDate = new Date(currentDate);
                endDate = new Date(endDate);

                while (currentDate <= endDate) {
                    date = `${currentDate.getFullYear()}${separator}${currentDate.getMonth() + 1 < 10
                        ? `0${currentDate.getMonth() + 1}`
                        : `${currentDate.getMonth() + 1}`
                        }${separator}${currentDate.getDate() < 10
                            ? `0${currentDate.getDate()}`
                            : `${currentDate.getDate()}`
                        }`;

                    dates.push({ fulldate: date });
                    currentDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() + 1, // Will increase month if over range
                    );
                }
                console.log(setd.setId, "ln");
                if (setd.bookingKitSets.length > 0) {
                    dates &&
                        dates.map(diffdate => {
                            fulldate = diffdate.fulldate;

                            setd.bookingKitSets &&
                                setd.bookingKitSets
                                    .filter(rs => rs.setId == setd._id)
                                    .map(bookingSt => {
                                        if (
                                            bookingSt.bookings &&
                                            bookingSt.bookings.filter(
                                                rs =>
                                                    rs.booking_type != "loan" &&
                                                    rs.delivery_date <=
                                                    fulldate &&
                                                    rs.collection_date >=
                                                    fulldate,
                                            ).length
                                        ) {
                                            isConsinment = 1;
                                        }
                                    });
                        });
                    totalConsinment += isConsinment;
                }
            }
        });

        if (totalConsinment == kit.sets.length && kit.sets.length > 0) {
            return (
                <React.Fragment>
                    <tr>
                        <td colSpan="3" className=" red-dim">
                            Consignment already booked for this duration
                        </td>
                    </tr>
                </React.Fragment>
            );
        }
    }

    checkNextAvalability() {
        var test = [];
        test.push({ date: 5 });

        return test;
    }


    componentDidMount() {
        this.getList();
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.repsList !== undefined) {
            this.showRepsList(nextProps.repsList);
        }

        if (
            nextProps.kitListDropdown !== undefined &&
            this.state.kitListFlag == 0
        ) {
            this.showKitsList(nextProps.kitListDropdown);
        }

        if (nextProps.kitSetList !== undefined) {
            this.showKitsSetList(nextProps.kitSetList);
        }

        if (nextProps.editSet == true) {
            this.showKitDetails(nextProps.set);
        }

    }

    render() {
        const { redirect, loading } = this.props;

        if (redirect && this.state.bookingInfo.booking_type != "consignment") {
            return <Redirect to="/admin/bookings" />;
        }
        if (redirect && this.state.bookingInfo.booking_type == "consignment") {
            return <Redirect to="/admin/bookings/consignment" />;
        }
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        const { error } = this.props;
        return (
            <div>
                <Navbar activePage="bookings" />
                <div className="container">
                    <h1 className="date-scroll pt-4">
                        <Link to="/admin/bookings">
                            <i className="fa fa-long-arrow-left smbtn"></i>{" "}
                            <strong>Back</strong>
                        </Link>
                    </h1>
                    <div className="row pt-4 mobile_nocard">
                        <div className="col-md-8">
                            <div className="d-card">
                                <h4>Basic Details</h4>
                                {/* <div className="form-group">
                                    <label>
                                        Booking Type{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <div
                                        className="position_up"
                                        style={{ zIndex: 9 }}>
                                        <Select
                                            value={
                                                this.state.selectedBookingType
                                            }
                                            onChange={
                                                this
                                                    .setSelectedOptionBookingType
                                            }
                                            options={this.state.bookingType}
                                        />
                                        {error ? (
                                            <span className="text-danger">
                                                {error.type ? error.type : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div> */}
                                <div className="form-group">
                                    <label>Event Name</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.event_name}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "event_name",
                                                e.target.value,
                                            )
                                        }
                                    />

                                </div>

                                {/* <div className="form-group">
                                    <label>Event Address</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.event_address}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "event_address",
                                                e.target.value,
                                            )
                                        }
                                    />
                                  </div>   */}


                                <label>Event Address</label>
                                <div className="row purchase_date">
                                    <div
                                        className={
                                            this.state.bookingInfo
                                                .booking_type != "consignment"
                                                ? "col-md-6"
                                                : "col-md-12"
                                        }>
                                        <div className="form-group">
                                            <label className="opaq">
                                                Name of Location{" "}
                                                {/* <span className="text-danger">
                                                    *
                                                </span> */}
                                            </label>
                                            {/* <div className="inputdata">
                                                <DatePicker
                                                    selected={
                                                        this.state.bookingInfo
                                                            .delivery_date
                                                    }
                                                    onChange={date =>
                                                        this.handleBookingInfo(
                                                            "delivery_date",
                                                            date,
                                                        )
                                                    }
                                                    dateFormat="dd-MM-yyyy EEE"
                                                    className="small"
                                                    id="datepicker"
                                                    autoComplete="off"
                                                />
                                            </div> */}
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={this.state.bookingInfo.name_of_location}

                                                onChange={e =>
                                                    this.handleBookingInfo(
                                                        "name_of_location",
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            
                                            {/* {this.state.bookingInfo.delivery_date!="" &&  ((new Date(this.state.bookingInfo.delivery_date).getDate() < new Date().getDate()) || ((new Date(this.state.bookingInfo.delivery_date).getMonth()+1) < (new Date().getMonth()+1)) || (new Date(this.state.bookingInfo.delivery_date).getFullYear() < new Date().getFullYear())) && 
                                                <div><span className="text-danger">The dispatch date you booked is in the past</span></div>
                                            } */}
                                        </div>
                                    </div>
                                    {this.state.bookingInfo.booking_type !=
                                        "consignment" && (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="opaq">
                                                        Address{" "}
                                                        {/* <span className="text-danger">
                                                            *
                                                        </span> */}
                                                    </label>
                                                    {/* <div className="mt-2 position_up">
                                                        <Select
                                                            defaultValue={
                                                                this.state
                                                                    .selectedDeliverySlot
                                                            }
                                                            onChange={
                                                                this
                                                                    .setSelectedOptionDeliverySlot
                                                            }
                                                            options={deliverySlots}
                                                            className="small"
                                                        />
                                                    </div> */}
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.bookingInfo.address}

                                                        onChange={e =>
                                                            this.handleBookingInfo(
                                                                "address",
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                    {this.state.bookingInfo.booking_type !=
                                        "consignment" && (
                                            <React.Fragment>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="opaq">
                                                            Postcode{" "}
                                                            {/* <span className="text-danger">
                                                                *
                                                            </span> */}
                                                        </label>
                                                        {/* <DatePicker
                                                            selected={
                                                                this.state
                                                                    .bookingInfo
                                                                    .operation_date
                                                            }
                                                            onChange={date =>
                                                                this.handleBookingInfo(
                                                                    "operation_date",
                                                                    date,
                                                                )
                                                            }
                                                            dateFormat="dd-MM-yyyy EEE"
                                                            id="datepicker2"
                                                            className="small"
                                                            autoComplete="off"
                                                        /> */}
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.bookingInfo.postcode}

                                                            onChange={e =>
                                                                this.handleBookingInfo(
                                                                    "postcode",
                                                                    e.target.value,
                                                                )
                                                            }
                                                        />
                                                        {this.state
                                                            .operationDateError && (
                                                                <div className="error-message">
                                                                    <span className="text-danger">
                                                                        {
                                                                            this.state
                                                                                .operationDateError
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )}
                                                        {error ? (
                                                            <span className="text-danger">
                                                                {error.operation_date
                                                                    ? error.operation_date
                                                                    : ""}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group" >
                                                        <label className="opaq">
                                                            Phone Number{" "}
                                                            {/* <span className="text-danger">
                                                                *
                                                            </span> */}
                                                        </label>
                                                        {/* <div className="mt-2">
                                                            <Select
                                                                defaultValue={
                                                                    this.state
                                                                        .selectedOperationSlots
                                                                }
                                                                onChange={
                                                                    this
                                                                        .setSelectedOptionOperationSlots
                                                                }
                                                                options={
                                                                    operationSlots
                                                                }
                                                                className="small"
                                                            />
                                                        </div> */}
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.bookingInfo.phone_number}

                                                            onChange={e =>
                                                                this.handleBookingInfo(
                                                                    "phone_number",
                                                                    e.target.value,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                         <div className="form-group mt-3">
                                            <span className="">
                                                <Input
                                                    type="checkbox"
                                                    className="ml-0 form-check-input"
                                                    value={
                                                        this.state.bookingInfo.pickup_location_checkbox
                                                    }
                                                    checked={
                                                        this.state.bookingInfo.pickup_location_checkbox
                                                    }
                                                    onChange={e =>
                                                        this.handleBookingInfo(
                                                            "pickup_location_checkbox",
                                                            e.target.checked,
                                                        )
                                                    }
                                                />
                                            </span>
                                            <label className="ml-4 ">
                                            Is Pickup Location And Contact Details Different
                                            </label>
                                            {this.state.bookingInfo.pickup_location_checkbox?
                                            <Input
                                            type="text"
                                            className="form-control"
                                           onChange={e =>
                                            this.handleBookingInfo(
                                                "pickup_location_text",
                                                e.target.value,
                                            )
                                        }
                                        
                                        />
                                        :""
                                            }
                                </div>
</div>

                                            </React.Fragment>
                                        )}
                                </div>

                                <div className="form-group">
                                    <label>Delivery Address</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.delivery_address}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "delivery_address",
                                                e.target.value,
                                            )
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Collection Address</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.collection_address}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "collection_address",
                                                e.target.value,
                                            )
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Contact Detail</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.contact_detail}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "contact_detail",
                                                e.target.value,
                                            )
                                        }
                                    />
                                </div>


                                {/* <div className="form-group">
                                    <label>PO Number </label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.bookingInfo.po_number}

                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "po_number",
                                                e.target.value,
                                            )
                                        }
                                    />
                                    {error ? (
                                        <span className="text-danger">
                                            {error.po_number
                                                ? error.po_number
                                                : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div> */}
                                {/* <div className="form-group">
                                    <span className="mr-1">
                                        <Input
                                            type="checkbox"
                                            className="ml-1"
                                            value={
                                                this.state.bookingInfo.po_check
                                            }
                                            checked={
                                                this.state.bookingInfo.po_check
                                            }
                                            onChange={e =>
                                                this.handleBookingInfo(
                                                    "po_check",
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                    </span>
                                    <label className="ml-4">
                                        Awaiting PO
                                    </label>
                                </div> */}
                            </div>
                            <div className="d-card my-4">
                                <h4>Meeting Delivery/ Collection</h4>
                                <div className="row purchase_date">
                                    <div
                                        className={
                                            this.state.bookingInfo
                                                .booking_type != "consignment"
                                                ? "col-md-6"
                                                : "col-md-12"
                                        }>
                                        <div className="form-group">
                                            <label>
                                                Delivery Date{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="inputdata">
                                                <DatePicker
                                                    selected={
                                                        this.state.bookingInfo
                                                            .delivery_date
                                                    }
                                                    onChange={date =>
                                                        this.handleBookingInfo(
                                                            "delivery_date",
                                                            date,
                                                        )
                                                    }
                                                    dateFormat="dd-MM-yyyy EEE"
                                                    className="small"
                                                    id="datepicker"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            {this.state.deliveryDateError && (
                                                <div className="error-message">
                                                    <span className="text-danger">
                                                        {
                                                            this.state
                                                                .deliveryDateError
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {error ? (
                                                <div>
                                                    <span className="text-danger">
                                                        {error.delivery_date
                                                            ? error.delivery_date
                                                            : ""}
                                                    </span>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.bookingInfo
                                                .delivery_date != "" &&
                                                (new Date(
                                                    this.state.bookingInfo.delivery_date,
                                                ).getDay() == 6 ||
                                                    new Date(
                                                        this.state.bookingInfo.delivery_date,
                                                    ).getDay() == 0) && (
                                                    <div>
                                                        <span className="text-danger">
                                                            Selected date is a
                                                            weekend/holiday but
                                                            you can still make a
                                                            booking
                                                        </span>
                                                    </div>
                                                )}
                                            {/* {this.state.bookingInfo.delivery_date!="" &&  ((new Date(this.state.bookingInfo.delivery_date).getDate() < new Date().getDate()) || ((new Date(this.state.bookingInfo.delivery_date).getMonth()+1) < (new Date().getMonth()+1)) || (new Date(this.state.bookingInfo.delivery_date).getFullYear() < new Date().getFullYear())) && 
                                                <div><span className="text-danger">The dispatch date you booked is in the past</span></div>
                                            } */}
                                        </div>
                                    </div>
                                    {this.state.bookingInfo.booking_type !=
                                        "consignment" && (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label></label>
                                                    <div className="mt-2 position_up">
                                                        <Select
                                                            defaultValue={
                                                                this.state
                                                                    .selectedDeliverySlot
                                                            }
                                                            onChange={
                                                                this
                                                                    .setSelectedOptionDeliverySlot
                                                            }
                                                            options={deliverySlots}
                                                            className="small"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    {this.state.bookingInfo.booking_type !=
                                        "consignment" && (
                                            <React.Fragment>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            Meeting Date{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <DatePicker
                                                            selected={
                                                                this.state
                                                                    .bookingInfo
                                                                    .operation_date
                                                            }
                                                            onChange={date =>
                                                                this.handleBookingInfo(
                                                                    "operation_date",
                                                                    date,
                                                                )
                                                            }
                                                            dateFormat="dd-MM-yyyy EEE"
                                                            id="datepicker2"
                                                            className="small"
                                                            autoComplete="off"
                                                        />
                                                        {this.state
                                                            .operationDateError && (
                                                                <div className="error-message">
                                                                    <span className="text-danger">
                                                                        {
                                                                            this.state
                                                                                .operationDateError
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )}
                                                        {error ? (
                                                            <span className="text-danger">
                                                                {error.operation_date
                                                                    ? error.operation_date
                                                                    : ""}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label></label>
                                                        <div className="mt-2">
                                                            <Select
                                                                defaultValue={
                                                                    this.state
                                                                        .selectedOperationSlots
                                                                }
                                                                onChange={
                                                                    this
                                                                        .setSelectedOptionOperationSlots
                                                                }
                                                                options={
                                                                    operationSlots
                                                                }
                                                                className="small"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>
                                                Collection Date{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            {/*<Input 
                                                type="date" 
                                                value={this.state.bookingInfo.collection_date}
                                                onChange = {(e) => this.handleBookingInfo("collection_date", e.target.value)}
                                                className="small"
                                            />*/}
                                            <DatePicker
                                                selected={
                                                    this.state.bookingInfo
                                                        .collection_date
                                                }
                                                onChange={date =>
                                                    this.handleBookingInfo(
                                                        "collection_date",
                                                        date,
                                                    )
                                                }
                                                dateFormat="dd-MM-yyyy EEE"
                                                id="datepicker3"
                                                className="small"
                                                autoComplete="off"
                                            />
                                            {this.state.collectionDateError && (
                                                <div className="error-message">
                                                    <span className="text-danger">
                                                        {
                                                            this.state
                                                                .collectionDateError
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {error ? (
                                                <div>
                                                    <span className="text-danger">
                                                        {error.collection_date
                                                            ? error.collection_date
                                                            : ""}
                                                    </span>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.bookingInfo
                                                .collection_date != "" &&
                                                (new Date(
                                                    this.state.bookingInfo.collection_date,
                                                ).getDay() == 6 ||
                                                    new Date(
                                                        this.state.bookingInfo.collection_date,
                                                    ).getDay() == 0) && (
                                                    <div>
                                                        <span className="text-danger mt-2">
                                                            Selected date is a
                                                            weekend/holiday but
                                                            you can still make a
                                                            booking
                                                        </span>
                                                        Edit Hospital
                                                    </div>
                                                )}

                                            {/* {this.state.bookingInfo.collection_date!="" &&  (new Date(this.state.bookingInfo.collection_date).getDay() == 5 || new Date(this.state.bookingInfo.collection_date).getDay() == 6)&& 
                                                // <div><span className="text-danger mt-2"> Return Date is a non-working day</span></div>
                                            } */}

                                            {/* {this.state.bookingInfo.collection_date!="" &&  ((new Date(this.state.bookingInfo.collection_date).getDate() < new Date().getDate()) || ((new Date(this.state.bookingInfo.collection_date).getMonth()+1) < (new Date().getMonth()+1)) || (new Date(this.state.bookingInfo.collection_date).getFullYear() < new Date().getFullYear())) && 
                                                <div><span className="text-danger">The collection date you booked is in the past</span></div>
                                            } */}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <table className="table">
                                        <tbody>
                                            {this.state.kitWithKitSet &&
                                                this.state.kitWithKitSet
                                                    .filter(
                                                        item =>
                                                            item.cartAdded != 0,
                                                    )
                                                    .map((kit, index) => (
                                                        <React.Fragment
                                                            key={index}>
                                                            <tr>
                                                                <th colSpan="2">
                                                                    <span className="mr-4 kitname">
                                                                        {
                                                                            kit.name
                                                                        }
                                                                        {kit
                                                                            .kitDocument
                                                                            .length >
                                                                            0 && (
                                                                                <span className="ml-1">
                                                                                    <a
                                                                                        id={
                                                                                            "UncontrolledTooltipPdf1" +
                                                                                            index
                                                                                        }
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                        className="download-info badge1 badge-info1"
                                                                                        href={
                                                                                            process
                                                                                                .env
                                                                                                .REACT_APP_APIURL +
                                                                                            "assets/uploads/kitDocuments/" +
                                                                                            kit
                                                                                                .kitDocument[0]
                                                                                                .name
                                                                                        }>
                                                                                        Download Tray List
                                                                                    </a>

                                                                                </span>
                                                                            )}
                                                                    </span>
                                                                    {/* <span
                                                                        style={{
                                                                            "vertical-align":
                                                                                "bottom",
                                                                        }}
                                                                        className="small">
                                                                        {(kit.hire_charge >
                                                                            0 ||
                                                                            kit.quantity >
                                                                            0) && (
                                                                                <React.Fragment>
                                                                                    <span>
                                                                                        Requested
                                                                                        Quantity
                                                                                        :{" "}
                                                                                        {
                                                                                            kit.quantity
                                                                                        }
                                                                                    </span>{" "}
                                                                                    &nbsp;
                                                                                    <span>
                                                                                        Hire
                                                                                        Charges
                                                                                        (Per
                                                                                        Set)
                                                                                        :{" "}
                                                                                        {
                                                                                            kit.hire_charge
                                                                                        }
                                                                                    </span>
                                                                                </React.Fragment>
                                                                            )}
                                                                    </span> */}

                                                                    <div className="mt-2">
                                                                        {kit.sets
                                                                            .filter(
                                                                                item =>
                                                                                    item.added ==
                                                                                    1,
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    setd,
                                                                                    ind,
                                                                                ) => (
                                                                                    <span
                                                                                        className="d-inline-block capsul_kit"
                                                                                        key={
                                                                                            ind
                                                                                        }>
                                                                                        {
                                                                                            setd.set_name
                                                                                        }{" "}
                                                                                    </span>
                                                                                ),
                                                                            )}
                                                                    </div>
                                                                </th>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row align-items-end">
                                    <div className="col-12">
                                        <label>
                                            Select Kit{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div
                                                className="form-group mb-0"
                                                style={{
                                                    width: "calc(100% - 80px)",
                                                    zIndex:"999"
                                                }}>
                                                <Select
                                                    value={
                                                        this.state.selectedKit
                                                    }
                                                    onChange={
                                                        this
                                                            .setSelectedOptionKit
                                                    }
                                                    options={this.state.kits}
                                                    className="small"
                                                />
                                            </div>
                                            <div>
                                                <a
                                                    className="btn btn-primary small"
                                                    href="javascript:;"
                                                    onClick={() =>
                                                        this.cartToggle()
                                                    }
                                                    id="UncontrolledTooltipExample">
                                                    <i className="fa fa-shopping-cart"></i>{" "}
                                                    {this.state.cartItemCount}
                                                </a>
                                                <UncontrolledTooltip target="UncontrolledTooltipExample">
                                                    {" "}
                                                    Add to Cart{" "}
                                                </UncontrolledTooltip>
                                            </div>
                                        </div>
                                        {this.state.kitSetErrorFlag == 1 ? (
                                            <span className="text-danger">
                                                Please add at least one kit set
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div
                                            className={
                                                this.state.cartFlag === true
                                                    ? "collapse1 show1"
                                                    : " collapse1"
                                            }
                                            id="collapseExample">
                                            <div className="card card-body">
                                                <table className="table mb-0 mobile_full">
                                                    <tbody>
                                                        {this.state
                                                            .kitWithKitSet &&
                                                            this.state.kitWithKitSet
                                                                .filter(
                                                                    item =>
                                                                        item.cartAdded !=
                                                                        0,
                                                                )
                                                                .map(
                                                                    (
                                                                        kit,
                                                                        index,
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={
                                                                                index
                                                                            }>
                                                                            <tr className="mobileFullView">
                                                                                <th
                                                                                    colSpan="2"
                                                                                    className="w-100">
                                                                                    <span className="mr-4">
                                                                                        {
                                                                                            kit.name
                                                                                        }
                                                                                        {kit
                                                                                            .kitDocument
                                                                                            .length >
                                                                                            0 && (
                                                                                                <span className="ml-1">
                                                                                                    <a
                                                                                                        id={
                                                                                                            "UncontrolledTooltipPdf2" +
                                                                                                            index
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                        rel="noreferrer"
                                                                                                        className="download-info badge1 badge-info1"
                                                                                                        href={
                                                                                                            process
                                                                                                                .env
                                                                                                                .REACT_APP_APIURL +
                                                                                                            "assets/uploads/kitDocuments/" +
                                                                                                            kit
                                                                                                                .kitDocument[0]
                                                                                                                .name
                                                                                                        }>
                                                                                                        Download Tray List
                                                                                                    </a>

                                                                                                </span>
                                                                                            )}
                                                                                    </span>
                                                                                </th>
                                                                                <td className="text-right">
                                                                                    <a
                                                                                        href="javascript:;"
                                                                                        onClick={() =>
                                                                                            this.handelRemovKitFromCart(
                                                                                                index,
                                                                                                kit._id,
                                                                                            )
                                                                                        }>
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            {this.checkAvalabilityForConnsignment(
                                                                                kit,
                                                                                index,
                                                                            )}
                                                                            {kit.sets.map(
                                                                                (
                                                                                    setd,
                                                                                    ind,
                                                                                ) => (
                                                                                    <tr
                                                                                        key={
                                                                                            ind
                                                                                        }>
                                                                                        {this.checkAvalability(
                                                                                            setd.bookingKitSets,
                                                                                            setd,
                                                                                            setd._id,
                                                                                            index,
                                                                                            ind,
                                                                                            kit._id,
                                                                                            1,
                                                                                        )}
                                                                                    </tr>
                                                                                ),
                                                                            )}
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="col-sm-3 col-4">
                                        <Button className="btn btn-primary btn-block" onClick={this.handleAddKit} ><i className="fa fa-plus"></i> Add </Button>
                                    </div>*/}
                                </div>
                                <div className="mt-4">
                                    <table className="table mb-0 mobile_full">
                                        <tbody>
                                            {this.state.kitWithKitSet &&
                                                this.state.cartFlag == false &&
                                                this.state.kitWithKitSet
                                                    .filter(
                                                        item =>
                                                            item._id ==
                                                            this.state
                                                                .singleSelectedKitId,
                                                    )
                                                    .map((kit, index) => (
                                                        <React.Fragment
                                                            key={index}>
                                                            <tr className="mobileFullView">
                                                                <th
                                                                    colSpan="2"
                                                                    className="w-100">
                                                                    <span className="mr-4" 
                                                                    //onClick={this.openModal}
                                                                    >
                                                                        {
                                                                            kit.name
                                                                        }
                                                                        {kit
                                                                            .kitDocument
                                                                            .length >
                                                                            0 && (
                                                                                <span className="ml-1">
                                                                                    <a
                                                                                        id={
                                                                                            "UncontrolledTooltipPdf3" +
                                                                                            index
                                                                                        }
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                        className="download-info badge1 badge-info1"
                                                                                        href={
                                                                                            process
                                                                                                .env
                                                                                                .REACT_APP_APIURL +
                                                                                            "assets/uploads/kitDocuments/" +
                                                                                            kit
                                                                                                .kitDocument[0]
                                                                                                .name
                                                                                        }>
                                                                                        Download Tray List
                                                                                    </a>

                                                                                </span>
                                                                            )}
                                                                    </span>
                                                                </th>
                                                                <td className="text-right">
                                                                    <a
                                                                        href="javascript:;"
                                                                        onClick={() =>
                                                                            this.handelRemovKit(
                                                                                index,
                                                                                kit._id,
                                                                            )
                                                                        }>
                                                                        <i className="fa fa-trash"></i>{" "}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            {this.checkAvalabilityForConnsignment(
                                                                kit,
                                                                index,
                                                            )}
                                                            {kit.sets.map(
                                                                (setd, ind) => (
                                                                    <tr
                                                                        key={
                                                                            ind
                                                                        }>
                                                                        {this.checkAvalability(
                                                                            setd.bookingKitSets,
                                                                            setd,
                                                                            setd._id,
                                                                            index,
                                                                            ind,
                                                                            kit._id,
                                                                            2,
                                                                        )}
                                                                    </tr>
                                                                ),
                                                            )}
                                                            <KitModal isOpen={this.state.isOpen} toggle={this.closeModal} item={kit} />
                                                        </React.Fragment>
                                                    ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            {/* <div className="d-card">
                                <h4>Charges</h4>
                                <div className="form-group">
                                    <label>
                                        Shipping Charge{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        value={
                                            this.state.bookingInfo
                                                .shipping_charge
                                        }
                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "shipping_charge",
                                                e.target.value,
                                            )
                                        }
                                    />
                                    {error ? (
                                        <span className="text-danger">
                                            {error.shipping_charge
                                                ? error.shipping_charge
                                                : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div> */}

                            <div className="d-card">
                                <h4>
                                    Booked By{" "}
                                    <span className="text-danger">*</span>
                                </h4>
                                <div className="form-group">
                                    <label>
                                        Assigned Rep{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <div className="position_up">
                                        <Select
                                            defaultValue={
                                                this.state.selectedRep
                                            }
                                            onChange={this.setSelectedOptionRep}
                                            options={this.state.reps}
                                        />
                                        {error ? (
                                            <span className="text-danger">
                                                {error.assigned_rep
                                                    ? error.assigned_rep
                                                    : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Additional Reps</label>
                                    <Select
                                        defaultValue={
                                            this.state.selectedAdditionalRep
                                        }
                                        onChange={
                                            this.setSelectedOptionAdditionalRep
                                        }
                                        options={this.state.additionalReps}
                                        isMulti={true}
                                    />
                                </div>
                            </div>

                            <div className="d-card mt-4">
                                <h4>Additional Notes</h4>
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        value={
                                            this.state.bookingInfo
                                                .additional_notes
                                        }
                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "additional_notes",
                                                e.target.value,
                                            )
                                        }></textarea>
                                </div>
                            </div>

                            <div className="mt-5 row">
                                {loading == false && (
                                    <React.Fragment>
                                        <div className="col-6">
                                            <Link
                                                to="/admin/bookings"
                                                className="btn btn-block btn-secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="col-6">
                                            <a
                                                href="javascript:;"
                                                onClick={() =>
                                                    this.saveChangeHandle()
                                                }
                                                className="btn btn-block btn-primary">
                                                Save Changes
                                            </a>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <a
                                                href="javascript:;"
                                                onClick={() =>
                                                    this.sendEmailConfirmHandle()
                                                }
                                                className="btn btn-block btn-primary">
                                                Save and send email
                                            </a>
                                        </div>
                                    </React.Fragment>
                                )}
                                {loading == true && (
                                    <React.Fragment>
                                        <div className="col-6">
                                            <Link
                                                to="/admin/bookings"
                                                className="btn btn-secondary btn-block">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="col-6">
                                            <button
                                                className="btn btn-primary loader-icon btn-block"
                                                disabled>
                                                <Spinner className="img-svg"></Spinner>
                                            </button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* ADD FORM */}
                </div>

                <Modal
                    size="lg"
                    backdrop="static"
                    isOpen={this.state.kitSetDetailModal}
                    toggle={() => this.kitSetDetailModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.kitSetDetailModalClose()}>
                        Kit Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.kitDetails.setProductModules &&
                                    this.state.kitDetails.setProductModules.map(
                                        (product, ind) => (
                                            <tr key={ind}>
                                                <td>
                                                    {product.productID.code}
                                                </td>
                                                <td>
                                                    {product.productID.name}
                                                </td>
                                                <td>{product.quantity}</td>
                                            </tr>
                                        ),
                                    )}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                className="btn btn-secondary"
                                onClick={() => this.kitSetDetailModalClose()}>
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Set Details Modal */}

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        repsList,
        error,
        kitSetList,
        redirect,
        loading,
    } = state.rootReducer.bookings;
    const { kitListDropdown } = state.rootReducer.kits;
    const { editSet, set } = state.rootReducer.sets;

    return {
        repsList,
        error,
        kitListDropdown,
        kitSetList,
        redirect,
        editSet,
        set,
        loading,
    };
}

export default connect(mapStateToProps)(Add);
