import React ,{ useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter} from "reactstrap";
import { bookingService } from "../../../../services";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

const Detials = ( props ) => {
    const [bookingData, setBookingData] = useState([]);
    const [dobbuleBookingData, setDobbuleBookingData] = useState([]);

    const getBooking = () => {
        bookingService.getById(props.detailsBopokingId)
        .then(response => {
            setBData(response.data);
        });
    };

    const setBData = (data) => {
        console.log("datadata",data);
        setBookingData(data);
    };


    const getDubbleBooking = () => {
        bookingService.getById(props.detailsDBopokingId)
        .then(response => {
            setDBData(response.data);
        });
    };

    const setDBData = (data) => {
        console.log("ddatadata",data);
        setDobbuleBookingData(data);
    };

    useEffect(() => {
       
        if(props.bookingDetailModal==true){
            setBookingData([]);
            setDobbuleBookingData([]);
            getBooking();
            if(props.detailsDBopokingId!=0){
                getDubbleBooking();
            }
        }
    }, [props.bookingDetailModal]);
    return (
        <> 
            <div>
                <Modal size={props.detailsDBopokingId!=0?"lg":"bg"} backdrop="static" isOpen={props.bookingDetailModal}>
                    {bookingData._id &&
                        <React.Fragment>
                            <div className="row">
                                <div className={props.detailsDBopokingId!=0?"col-md-6 border-right":"col-md-12"}>
                                    <ModalHeader className=""  toggle={props.detailsDBopokingId==0 ? props.bookingDetailModalClose :false} >
                                    Event Details 
                                        { bookingData.status=="booked" &&
                                            <Link  className="text-muted ml-2 small" to={"/admin/booking/edit/"+bookingData._id}><i className="fa fa-pencil" /></Link>
                                        }
                                      
                                    </ModalHeader>
                                    <ModalBody className="">
                                        <h4 className="lined-h">Event Details </h4>
                                        {console.log("booking data", bookingData)}
                                        <h5>{bookingData.booking_id}</h5>
                                      
                                        <div className="form-group">
                                            <div className="info">
                                                <div className="info-left">
                                                    <strong>{bookingData?.assigned_reps_data?.[0].first_name} {bookingData?.assigned_reps_data?.[0].last_name}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <span>Event Name: <strong>{bookingData.event_name}</strong> </span>                    
                                        </div>
                                        {/* <div className="form-group">
                                            <span>PO Number: <strong>{bookingData.po_number}</strong> </span>
                                        </div> */}

                                        <h4 className="lined-h mt-5">Important Dates</h4>
                                        {bookingData.booking_type!="loan" && 
                                            <div className="form-group">
                                                <label>Delivery date</label>
                                                <div><strong>{dateFormat(bookingData.delivery_date, "mmm dS, yyyy")} </strong></div>
                                            </div>
                                        }
                                        {bookingData.booking_type=="loan" && 
                                            <div className="form-group">
                                                <label>Delivery date</label>
                                                <div><strong>{dateFormat(bookingData.delivery_date, "mmm dS, yyyy")}  {bookingData.delivery_date_slot}</strong></div>
                                            </div>
                                        }
                                        {bookingData.booking_type=="loan" && 
                                            <div className="form-group">
                                                <label>Meeting date</label>
                                                <div><strong>{dateFormat(bookingData.operation_date, "mmm dS, yyyy")} {bookingData.operation_date_slot}</strong></div>
                                            </div>
                                        }

                                        <div className="form-group">
                                            <label>Collection date</label>
                                            <div><strong>{dateFormat(bookingData.collection_date, "mmm dS, yyyy")} AM</strong></div>
                                        </div> 


                                        <h4 className="mt-5 lined-h">Purchase/Hire Details</h4> 
                                        <table className="table">
                                            <tbody>
                                                {bookingData?.bookingKitSetId?.length>0 && bookingData.bookingKitSetId.map((bkit,ind)=>
                                                    <React.Fragment  key={ind}>
                                                        {ind==0 && 
                                                            <tr>
                                                                <th colSpan="2">{bkit?.kitId[0]?.name}</th>
                                                            </tr>
                                                        }
                                                        {ind>0 && bookingData.bookingKitSetId[ind-1]?.kitId[0]?._id != bookingData.bookingKitSetId[ind]?.kitId[0]?._id &&
                                                            <tr>
                                                                <th colSpan="2">{bkit?.kitId[0]?.name}</th>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td>
                                                                <div className="mb-2">
                                                                    <a href="javascript:;" data-toggle="modal" data-target="#kitDetails">{bkit?.setId[0]?.set_name}</a>
                                                                </div>
                                                                                    
                                                            </td>
                                                            {/* <td>
                                                                <div>{bkit.hire_charge}</div>
                                                            </td> */}
                                                             
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                            </tbody>
                                        </table>
                                    </ModalBody>
                                    
                                </div>
                                {props.detailsDBopokingId!=0 &&
                                    <div className="col-md-6">
                                        <ModalHeader className="" toggle={props.bookingDetailModalClose}>
                                            Event Details 
                                            { dobbuleBookingData.status=="booked" &&
                                                <Link  className="text-muted ml-2 small" to={"/admin/booking/edit/"+dobbuleBookingData._id}><i className="fa fa-pencil" /></Link>
                                            }
                                          
                                        </ModalHeader>
                                        <ModalBody className="">
                                            <h4 className="lined-h">Event Details </h4>
                                            <h5>{dobbuleBookingData.booking_id}</h5>
                                            
                                            <div className="form-group">
                                                <div className="info">
                                                    <div className="info-left">
                                                        <strong>{dobbuleBookingData?.assigned_reps_data?.[0].first_name} {dobbuleBookingData?.assigned_reps_data?.[0].last_name}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <span>Event Name: <strong>{dobbuleBookingData.event_name}</strong> </span>                    
                                            </div>
                                            {/* <div className="form-group">
                                                <span>PO Number: <strong>{dobbuleBookingData.po_number}</strong> </span>
                                            </div> */}

                                            <h4 className="lined-h mt-5">Important Dates</h4>
                                            {dobbuleBookingData.booking_type!="loan" && 
                                                <div className="form-group">
                                                    <label>Delivery date</label>
                                                    <div><strong>{dateFormat(dobbuleBookingData.delivery_date, "mmm dS, yyyy")} </strong></div>
                                                </div>
                                            }
                                            {dobbuleBookingData.booking_type=="loan" && 
                                                <div className="form-group">
                                                    <label>Delivery date</label>
                                                    <div><strong>{dateFormat(dobbuleBookingData.delivery_date, "mmm dS, yyyy")}  {dobbuleBookingData.delivery_date_slot}</strong></div>
                                                </div>
                                            }
                                            {dobbuleBookingData.booking_type=="loan" && 
                                                <div className="form-group">
                                                    <label>Meeting date</label>
                                                    <div><strong>{dateFormat(dobbuleBookingData.operation_date, "mmm dS, yyyy")} {dobbuleBookingData.operation_date_slot}</strong></div>
                                                </div>
                                            }

                                            <div className="form-group">
                                                <label>Collection date</label>
                                                <div><strong>{dateFormat(dobbuleBookingData.collection_date, "mmm dS, yyyy")} AM</strong></div>
                                            </div> 


                                            <h4 className="mt-5 lined-h">Purchase/Hire Details</h4> 
                                            <table className="table">
                                            <tbody>
                                                {dobbuleBookingData?.bookingKitSetId?.length>0 && dobbuleBookingData.bookingKitSetId.map((bkit,ind)=>
                                                    <React.Fragment  key={ind}>
                                                        {ind==0 && 
                                                            <tr>
                                                                <th colSpan="2">{bkit?.kitId[0]?.name}</th>
                                                            </tr>
                                                        }
                                                        {ind>0 && dobbuleBookingData.bookingKitSetId[ind-1]?.kitId[0]?._id != dobbuleBookingData.bookingKitSetId[ind]?.kitId[0]?._id &&
                                                            <tr>
                                                                <th colSpan="2">{bkit?.kitId[0]?.name}</th>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td>
                                                                <div className="mb-2">
                                                                    <a href="javascript:;" data-toggle="modal" data-target="#kitDetails">{bkit?.setId[0]?.set_name}</a>
                                                                </div>
                                                                                    
                                                            </td>
                                                            {/* <td>
                                                                <div>{bkit.hire_charge}</div>
                                                            </td> */}
                                                             
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                            </tbody>
                                        </table>
                                        </ModalBody>
                                        
                                    </div>
                                }
                             
                            </div>
                            <ModalFooter>
                                <div className="">
                                    <button className="btn btn-secondary"   onClick={props.bookingDetailModalClose}>Close</button>
                                </div>
                            </ModalFooter>
                        </React.Fragment>
                    }
                </Modal>
            </div>
        </>
    );
};

export default Detials;
