import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./DashboardSidebar";
import {
    bookingRequestActions,
    bookingActions,
    setActions,
} from "../../../../actions";
import { bookingService } from "../../../../services/booking.services";
import dateFormat from "dateformat";
import Select from "react-select";
import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import moment from "moment";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

import jsPDF from "jspdf";
import "jspdf-autotable";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
}${separator}${date < 10 ? `0${date}` : `${date}`}`;

const head = [
    { title: "BOOKING ID", dataIndex: "booking" },
    { title: "EVENT NAME", dataIndex: "event_name" },
    { title: "SET", dataIndex: "set" },
];

class BookingDispatch extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            //bookingList: false
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 0,
            kitSetDetailModal: false,
            kitSetData: [],
            kitDetailsClickFlag: 0,
            kitDetails: [],
            dispatchModal: false,
            acceptModal: false,
            bookingId: 0,
            users: [],
            selectedUser: [{ label: "Booked By", value: "" }],
            selectedUserId: "",
            requestCount: 0,
            rejectComment: "",
            date: todayDate,
            todate: todayDate,
            dispatch: {
                courier: "",
                dispatchBoxesData: [{ box_type: "", number_of_box: "", numberOfBoxFlag: false }],
                tracker: "",
                bookingId: "",
                log_status: "dispatched",
                log_by_id: "",
                notes: "",
                dispatched_by:"Lily Rose Winters",
                other_dispatched_by:"" 
            },
            boxes: [{ box_type: "", number_of_box: "", numberOfBoxFlag: false }],
            submitFlag: 0,
            dayActive: "today",
            dataXlsx: [],
            detailsModalResult: [],
            detailsModal: false,
            sendMailLoader:false,
            options: [
                { label: "All Aces", value: "All Aces" },
                { label: "APC", value: "APC" },
                { label: "Sameday (HBC)", value: "Sameday (HBC)" },
                { label: "Other", value: "Other" },
            ],
            selectedOption: [{ label: "All Aces", value: "All Aces" }],
            boxOptions: [
                { label: "Black Box", value: "Black Box" },
                { label: "Blue Box", value: "Blue Box" },
                { label: "Grey case", value: "Grey case" },
                { label: "Cardboard Box", value: "Cardboard Box" },
            ],
            selectedNumOfBox: [{ label: "Black Box", value: "Black Box" }],
            dispatchedByOptions: [
                { label: "Lily Rose Winters", value: "Lily Rose Winters" },
                { label: "Ekaterina Karateodorova", value: "Ekaterina Karateodorova" },
                { label: "George Viziteu", value: "George Viziteu" },
                { label: "Ana Marie Sendrea", value: "Ana Marie Sendrea" },
                { label: "Jeff Allen", value: "Jeff Allen" },
                { label: "Others", value: "Other" }
            ],
            selectedDispatchedByValue:"",
            otherDispatchedFlag:false,
            selectedDispatchedByOptions: [{ label: "Lily Rose Winters", value: "Lily Rose Winters" }],
        };

        this.getList = this.getList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.handleRejectComment = this.handleRejectComment.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.dispatchModalClose = this.dispatchModalClose.bind(this);
        this.setSelectedOptionUser = this.setSelectedOptionUser.bind(this);
           this.detailsModalOpen = this.detailsModalOpen.bind(this);
        this.detailsModalClose = this.detailsModalClose.bind(this);
        this.manageBookingEmail = this.manageBookingEmail.bind(this);
        this.handleGeneratePdf = this.handleGeneratePdf.bind(this);
        this.formatSets = this.formatSets.bind(this);
        this.formatLines = this.formatLines.bind(this);
        this.didDrawPageCallback = this.didDrawPageCallback.bind(this);
    }

    handleTrackerChange = (e) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            dispatch: {
                ...prevState.dispatch,
                tracker: value
            }
        }));
    };

    handleNotesChange = (e) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            dispatch: {
                ...prevState.dispatch,
                notes: value
            }
        }));
    };

    handlePageChange(pageNumber) {
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getList();
            },
        );
    }

    toDay() {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const todayDate = `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date < 10 ? `0${date}` : `${date}`}`;

        this.setState(
            {
                dayActive: "today",
                date: todayDate,
                todate: todayDate,
            },
            () => {
                this.getList();
            },
        );
    }

    nextDays() {
        let newDate = new Date();
        let date = newDate.getDate() + 1;
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const tomorrowDate = `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date < 10 ? `0${date}` : `${date}`}`;

        this.setState(
            {
                dayActive: "tomorrow",
                date: tomorrowDate,
                todate: tomorrowDate,
            },
            () => {
                this.getList();
            },
        );
    }

    pastBookings() {
        var pastYearDate = moment().subtract(2, "years").format("YYYY-MM-DD");
        var yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");

        this.setState(
            {
                dayActive: "past",
                date: pastYearDate,
                todate: yesterdayDate,
            },
            () => {
                this.getList();
            },
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        if (totalItemsCount == undefined) {
            totalItemsCount = 0;
        }
        this.setState({
            activePage: activePage,
            totalItemsCount: totalItemsCount,
            limit: limit,
        });
    }

    getList() {
        this.props.dispatch(
            bookingRequestActions.getList({
                bookedByFilter: this.state.selectedUserId,
                hospitalFilter: "",
                type: ["loan"],
                status: ["booked", "dispatch"],
                deliveryDateFiter: this.state.date,
                toDeliveryDateFiter: this.state.todate,
                search: this.state.search,
                page: this.state.activePage,
                limit: this.state.limit,
            }),
        );
    }

    getDropdown() {
        // this.props.dispatch(bookingActions.getHospitalsList({ search: "" }));
        this.props.dispatch(bookingActions.getRepsList({ search: "" }));
    }

    handleSearch(val) {
        this.setState(
            {
                search: val,
                activePage: 1,
            },
            () => {
                this.getList();
            },
        );
    }

    kitSetDetailModalClose() {
        this.setState({
            kitSetDetailModal: false,
            kitSetData: [],
        });
    }

    showKitDetails(kitDetails) {
        if (this.state.kitDetailsClickFlag == 1) {
            this.setState({
                kitDetails: kitDetails,
                kitSetDetailModal: true,
                kitDetailsClickFlag: 0,
            });
        }
    }

    handleKitDetails(id) {
        this.setState({
            kitDetailsClickFlag: 1,
        });

        this.props.dispatch(setActions.getById(id));
    }

    setSelectedOptionUser(e) {
        var selectedUser = [{ value: e.value, label: e.label }];
        this.setState(
            {
                selectedUser,
                selectedUserId: e.value,
            },
            () => {
                this.getList();
            },
        );
    }

     handleSelectChange = (e) => {

        var selectedCourier = [{ value: e.value, label: e.label }];
        var dispatch = this.state.dispatch;
        dispatch.tracker = e.value;
        this.setState({
            selectedCourier,
            dispatch: dispatch,
        });
    };

    handleCourier(val) {
        let value = val.value;
        var dispatch = this.state.dispatch;
        dispatch.courier = value;
        this.setState({
            dispatch: dispatch,
        });
        this.setState({ val });
    }

    handleBoxType(val, index) {
        var { boxes } = this.state;
        boxes[index].box_type = val.value;

        var dispatch = this.state.dispatch;
        dispatch.dispatchBoxesData = boxes;

        this.setState({
            boxes: boxes,
            dispatch: dispatch,
        });
    }

    handleBoxNumber(val, index) {
        var { boxes } = this.state;
        if (val != "" && val < 1) {
            val = "";
        }
        boxes[index].number_of_box = val;
        boxes[index].numberOfBoxFlag = false;
        var dispatch = this.state.dispatch;
        dispatch.dispatchBoxesData = boxes;

        this.setState({
            boxes: boxes,
            dispatch: dispatch,
        });

        console.log(this.state.dispatch);
    }

    addMoreBox() {
        var { boxes } = this.state;
        boxes.push({ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false });
        var dispatch = this.state.dispatch;
        dispatch.dispatchBoxesData = boxes;
        this.setState({
            boxes: boxes,
            dispatch: dispatch,
        });
    }

    handleBoxRemove(ind) {
        const { boxes } = this.state;
        boxes.splice(ind, 1);
        var dispatch = this.state.dispatch;
        dispatch.dispatchBoxesData = boxes;
        this.setState({
            boxes: boxes,
            dispatch: dispatch,
        });
    }

    dispatchModalOpen(id) {
        this.setState({
            dispatchModal: true,
            bookingId: id,
            dispatch: {
                courier: "All Aces",
                dispatchBoxesData: [{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }],
                tracker: "",
                bookingId: id,
                log_status: "dispatched",
                log_by_id: this.state.user.data._id,
                notes: "",
                dispatched_by:"Lily Rose Winters",
                other_dispatched_by:""
            },
            boxes: [{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }],
            submitFlag: 0,
        });     
    }

    dispatchModalClose() {
        this.setState({
            dispatchModal: false,
            submitFlag: 0,
        });
    }

    detailsModalOpen(item) {
        this.setState({
            detailsModal: true,
            detailsModalResult: item,
        });
    }

    detailsModalClose() {
        this.setState({
            detailsModal: false,
        });
    }

    handleRejectComment(comment) {
        this.setState({
            rejectComment: comment,
        });
    }

    handleDispatchedBy(val) {
        let value = val.value;
        var dispatch = this.state.dispatch;
        dispatch.dispatched_by = value;
        dispatch.other_dispatched_by = "";
        this.setState({
            dispatch: dispatch,
            otherDispatchedFlag:false
        });
        this.setState({ selectedDispatchedByValue: value });
    }

    handleOtherDispatchedName(val) {
        var dispatch = this.state.dispatch;
        dispatch.other_dispatched_by = val;
        this.setState({
            dispatch: dispatch,
            otherDispatchedFlag:false
        });
    }


    handleDispatch() {
        this.setState({
            submitFlag: 1,
        });
        var flag = 0;

        this.state.boxes.map(item => {
            if (item.box_type == "" || item.number_of_box == "") {
                flag = 1;
            }
            if (item.number_of_box == "") {
                item.numberOfBoxFlag = true;
            }
        });

        if (
            this.state.dispatch.courier == "" ||
            this.state.dispatch.tracker == ""
        ) {
            this.props.dispatch(
                bookingActions.saveDispatchs(this.state.dispatch),
            );
        } else if (flag == 0) {
            this.props.dispatch(
                bookingActions.saveDispatchs(this.state.dispatch),
            );
        }
    }

    showRepsList(repsList) {
        var tempRepsList = [{ label: "Booked By", value: "" }];
        repsList.map(item => {
            var option = {
                value: item._id,
                label: item.first_name + " " + item.last_name,
            };
            tempRepsList.push(option);
        });
        this.setState({
            users: tempRepsList,
        });
    }

    showXlsData(data) {
        var dataXlsx = [];
        var kitset = "";
        data &&
            data.map(item => {
                kitset = "";

                item.bookingKitSetId &&
                    item.bookingKitSetId.map(kset => {
                        if (kitset != "") {
                            kitset += "\n" + kset.setId[0].set_name;
                        } else {
                            kitset += kset.setId[0].set_name;
                        }
                    });
                dataXlsx.push({
                    // booking: item.bookings.po_number,
                    booking: item.bookings.booking_id,
                    event_name: item.bookings.event_name,
                    set: kitset,
                    boxes: "",
                });
            });

        this.setState({
            dataXlsx: dataXlsx,
        });
    }

    capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    manageBookingEmail(id) {
        this.setState({sendMailLoader:id});
        bookingService
            .sendMailBeforeBookingDispatch({ bookingId: id })
            .then(res => {
                this.setState({sendMailLoader:false});
                console.log("serverices :>>>>>>>>>>", res);
            })
            .catch(error => {
                this.setState({sendMailLoader:false});
                console.error("Error:", error);
            });
    }

    componentDidMount() {
        this.getList();
        this.getDropdown();
    }

    formatSets = (sets) => {
        // Join set names with line breaks
        return sets.map(set => `${set}`).join("\n");
    };

    formatLines = (lines) => {
        // Join line names with line breaks
        return lines.map(line => `${line}`).join("\n");
    };

     handleGeneratePdf = async ()=> {
        const { bookingList }= this.props;
        let mainItem = bookingList.length ? bookingList.length : 0;

      const pdfData = mainItem ? bookingList.map((item)=> {
        let obj = {};
        let setArray = [];
        let checkedLineArray = [];
        obj.booking_id = item?.bookings?.booking_id;
        obj.reps_name =  item?.assigned_rep?.first_name +" " +item?.assigned_rep?.last_name;
        obj.event_name =  item?.bookings?.event_name;
        obj.checkout = "________________";
        item.bookingKitSetId &&
        item.bookingKitSetId.map((kset) =>{
            setArray.push(kset.setId[0].set_name);
            checkedLineArray.push("________________");
        });
        obj.sets = setArray;
        obj.checkLines = checkedLineArray;
        return obj;
        }) : [];
      
        const pdfDoc = new jsPDF();
    
        const columns = ["BOOKING", "REPS NAME", "EVENT NAME","SET","CHECKED"];



        pdfData.forEach((record, index) => {
            if (index > 0) {
              pdfDoc.addPage(); // Add a new page for each record after the first one
            }
            const header = columns.map((col) => ({ title:col, dataKey: col.toLowerCase() }));
            const body =  [[record.booking_id, record.reps_name, record.event_name, this.formatSets(record.sets),this.formatLines(record.checkLines)]];
      
            pdfDoc.autoTable({ head: [header], body,didDrawPage: this.didDrawPageCallback ,columnStyles: { 
                0: { halign: "center"},
                1: { halign: "center"},
                2: { halign: "center"},
                3: { halign: "center",overflow:"ellipsize",minCellWidth:80},
                4: { halign: "center"} 
            },
            // bodyStyles:{
            //     4: {valign:"bottom"}
            // },
            headStyles : {
                halign: "center"
            }});
          });
    
        pdfDoc.save("booking-dispatch.pdf");
      };

        didDrawPageCallback = (data) => {
            // Draw a border around the table on each page
            const { doc, settings } = data;
            const startX = settings.margin.left;
            const startY = settings.margin.top;
            const columnWidths = data.table.columns.map((col) => col.width);
            const rowHeight = data.table.body[0].height;
            const headHeight = data.table.head[0].height;
            doc.setDrawColor(0); // Set the border color to black
            doc.setLineWidth(0.2); // Set the border line width

            // Draw borders for each column
            let currentX = startX;
            columnWidths.forEach((width) => {
                doc.rect(currentX, startY, width, rowHeight+headHeight);
                currentX += width;
            });

        };

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if (nextProps.refreshList == true || nextProps.redirect == true) {
            this.setState(
                {
                    activePage: 1,
                    dispatchModal: false,
                },
                () => {
                    this.getList();
                },
            );
        }

        if (
            nextProps.activePage != "" &&
            nextProps.totalItemsCount != "" &&
            nextProps.limit != ""
        ) {
            this.handlePageStates(
                nextProps.activePage,
                nextProps.totalItemsCount,
                this.state.limit,
            );
        }

        if (nextProps.editSet == true) {
            this.showKitDetails(nextProps.set);
        }

        if (nextProps.repsList !== undefined) {
            this.showRepsList(nextProps.repsList);
        }

        // if (nextProps.hospitalsList !== undefined) {
        //     this.showHospitalsList(nextProps.hospitalsList);
        // }

        if (nextProps.bookingList !== undefined) {
            this.showXlsData(nextProps.bookingList);
        }
    }

    render() {
        const { bookingList, loading } = this.props;
        if (!this.state.user) {
            return <Redirect to="/" />;
        }

        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }

        const { error } = this.props;
        //const { username, password} = this.state;
        const { options, selectedOption } = this.state;
        const { boxOptions, selectedNumOfBox } = this.state;
        const { dispatchedByOptions, selectedDispatchedByOptions, selectedDispatchedByValue } = this.state;

        return (
            <div>
                <Navbar activePage="dashboard" />
                <main className="offset">
                    <div className="container-fluid">
                        <div className="row pt-4">
                            <div className="col-lg-2 side-filters">
                                <span className="side-heading">Dashboard</span>
                                <DashboardSidebar
                                    activePage="dispatch"
                                    dispatchCount={this.state.totalItemsCount}
                                />
                            </div>

                            <div className="col-lg-10">
                                <div className="ground">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6">
                                            <div className="sub-menu">
                                                <div
                                                    className="btn-group"
                                                    role="group"
                                                    aria-label="Basic example">
                                                    <button
                                                        type="button"
                                                        className={
                                                            "btn btn-secondary " +
                                                            (this.state
                                                                .dayActive ==
                                                            "today"
                                                                ? "active "
                                                                : "")
                                                        }
                                                        onClick={() =>
                                                            this.toDay()
                                                        }>
                                                        Today
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={
                                                            "btn btn-secondary mx-1 " +
                                                            (this.state
                                                                .dayActive ==
                                                            "tomorrow"
                                                                ? "active "
                                                                : "")
                                                        }
                                                        onClick={() =>
                                                            this.nextDays()
                                                        }>
                                                        Tomorrow
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={
                                                            "btn btn-secondary " +
                                                            (this.state
                                                                .dayActive ==
                                                            "past"
                                                                ? "active "
                                                                : "")
                                                        }
                                                        onClick={() =>
                                                            this.pastBookings()
                                                        }>
                                                        Past Bookings
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <form className="form-search">
                                                <Input
                                                    className="form-control mr-sm-2"
                                                    type="text"
                                                    placeholder="Search.."
                                                    value={this.state.search}
                                                    onChange={e =>
                                                        this.handleSearch(
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                            </form>
                                        </div>
                                        <div className="col-md-4 text-right">
                                        <button type="button" className="btn btn-default mr-md-2 my-1"
                                                onClick={() => bookingList?.length ? this.handleGeneratePdf() : ""}
                                            > Generate Dispatch Pdf</button>

                                            {/*<button type="button" className="btn btn-default"> Print </button>*/}
                                            <ExportSheet
                                                header={head}
                                                fileName={"booking-dispatch"}
                                                dataSource={this.state.dataXlsx}
                                                xlsx={XLSX}
                                                className="btn btn-default add_option">
                                                <button className="btn btn-default add_option">
                                                    Print
                                                </button>
                                            </ExportSheet>
                                        </div>
                                    </div>
                                    <div className="table-scroll mt-4">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            minWidth: "200px",
                                                        }}>
                                                        Booking #
                                                    </th>
                                                    <th scope="col">
                                                        Reps name{" "}
                                                    </th>
                                                    <th scope="col">
                                                        Event Name{" "}
                                                    </th>
                                                    <th scope="col">SET</th>
                                                    <th scope="col">Date</th>
                                                    <th
                                                        scope="col"
                                                        className="text-right"></th>
                                                    <th
                                                        scope="col"
                                                        className="text-right"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bookingList &&
                                                    bookingList.map(item => (
                                                        <tr key={item._id}>
                                                            <td
                                                                width="200"
                                                                className="align-top">
                                                                <Link
                                                                    to={
                                                                        "/admin/booking/details/" +
                                                                        item
                                                                            .bookings
                                                                            ._id
                                                                    }>
                                                                    <span>
                                                                        <strong>
                                                                            {" "}
                                                                            {
                                                                                item
                                                                                    .bookings
                                                                                    .booking_id
                                                                            }{" "}
                                                                        </strong>
                                                                    </span>
                                                                </Link>
                                                            </td>
                                                            <td className="align-top">
                                                                <div className="">
                                                                    {item
                                                                        .assigned_rep
                                                                        .first_name +
                                                                        " " +
                                                                        item
                                                                            .assigned_rep
                                                                            .last_name}
                                                                </div>
                                                            </td>
                                                            <td className="align-top">
                                                                <div className="">
                                                                    {
                                                                        item
                                                                            .bookings
                                                                            .event_name
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="align-top">
                                                                <small>
                                                                    {item.bookingKitSetId &&
                                                                        item.bookingKitSetId.map(
                                                                            (
                                                                                kset,
                                                                                ind,
                                                                            ) => (
                                                                                <React.Fragment
                                                                                    key={
                                                                                        ind
                                                                                    }>
                                                                                    {/* <div><a href="javascript:;" onClick={()=>this.handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div> */}
                                                                                    <div>
                                                                                        <span>
                                                                                            {
                                                                                                kset
                                                                                                    .setId[0]
                                                                                                    .set_name
                                                                                            }{" "}
                                                                                        </span>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            ),
                                                                        )}
                                                                </small>
                                                            </td>
                                                            <td className="align-top">
                                                                {dateFormat(
                                                                    item
                                                                        .bookings
                                                                        .delivery_date,
                                                                    "mmm dS, yyyy",
                                                                )}{" "}
                                                                {
                                                                    item
                                                                        .bookings
                                                                        .delivery_date_slot
                                                                }
                                                            </td>
                                                            <td className="text-right align-top">
                                                                <div className="d-flex">
                                                                    {" "}
                                                                    {item
                                                                        .bookings
                                                                        .status ==
                                                                        "booked" && (
                                                                        <a
                                                                            href="javascript:;"
                                                                            onClick={() =>
                                                                                this.dispatchModalOpen(
                                                                                    item
                                                                                        .bookings
                                                                                        ._id,
                                                                                )
                                                                            }
                                                                            className="btn btn-primary ml-2 btn-sm">
                                                                            Dispatch
                                                                        </a>
                                                                    )}
                                                                    {item
                                                                        .bookings
                                                                        .status ==
                                                                        "dispatch" && (
                                                                        <React.Fragment>
                                                                            <div><span className="capsule green-dim mb-2">
                                                                                Dispatched
                                                                            </span></div>
                                                                            <a
                                                                                href="javascript:;"
                                                                                onClick={() =>
                                                                                    this.detailsModalOpen(
                                                                                        item.bookingdispatches,
                                                                                    )
                                                                                }
                                                                                className="btn mb-2 ">
                                                                                Details
                                                                            </a>
                                                                        </React.Fragment>
                                                                    )}
                                                                    {/* {item
                                                                        .bookings
                                                                        .status ==
                                                                        "booked" && (
                                                                        <React.Fragment>
                                                                            {this.state.sendMailLoader == item.bookings._id ?
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    className="btn btn-primary ml-2 btn-sm">
                                                                                    Sending ..
                                                                                </a>
                                                                                :
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    onClick={() =>
                                                                                        this.manageBookingEmail(
                                                                                            item
                                                                                                .bookings
                                                                                                ._id,
                                                                                        )
                                                                                    }
                                                                                    className="btn btn-primary ml-2 btn-sm">
                                                                                    Send an Email
                                                                                </a>
                                                                                

                                                                            }
                                                                        </React.Fragment>
                                                                    )} */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                {bookingList &&
                                                    bookingList.length == 0 &&
                                                    loading == false && (
                                                        <tr className="text-center">
                                                            <td colSpan="5">
                                                                No Record Found
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {bookingList && bookingList.length > 0 && (
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={
                                            this.state.totalItemsCount
                                        }
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(
                                            this,
                                        )}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                    />
                                )}

                                <Modal
                                    size="md"
                                    backdrop="static"
                                    isOpen={this.state.dispatchModal}
                                    toggle={() => this.dispatchModalClose()}>
                                    <ModalHeader
                                        className=""
                                        toggle={() =>
                                            this.dispatchModalClose()
                                        }>
                                        Add Dispatch
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className="form-group position_up"
                                            style={{zIndex:this.state.boxes.length+2}}
                                        >
                                            <label>Courier</label>
                                            <Select
                                                defaultValue={selectedOption}
                                                onChange={(e) => this.handleCourier(e)}
                                                options={options}
                                            />
                                            {error &&
                                            this.state.submitFlag == 1 ? (
                                                <span className="text-danger">
                                                    {error.courier
                                                        ? error.courier
                                                        : ""}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div className="form-group position_up"
                                            style={{ zIndex: this.state.boxes.length + 1 }}
                                        >
                                            <label>Dispatched By</label>
                                            <Select
                                                defaultValue={selectedDispatchedByOptions}
                                                onChange={(e) => this.handleDispatchedBy(e)}
                                                options={dispatchedByOptions}
                                            />
                                            {error &&
                                                this.state.submitFlag == 1 ? (
                                                <span className="text-danger">
                                                    {error.dispatched_by
                                                        ? error.dispatched_by
                                                        : ""}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </div>



                                        {selectedDispatchedByValue === "Other" ?
                                        <div className="row"> 
                                            <div className="col-sm-12 mb-3">
                                                <Input
                                                    type="text"
                                                    value={
                                                        this.state?.dispatch?.other_dispatched_by
                                                    }
                                                    onChange={e =>
                                                        this.handleOtherDispatchedName(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="form-control"
                                                    name=""
                                                    placeholder="Other Dispatched Name"
                                                />
                                                {(this.state?.dispatch?.other_dispatched_by)?.trim() ==
                                                    ""  &&
                                                    this.state
                                                        .otherDispatchedFlag ==
                                                        true ? (
                                                    <span className="text-danger">
                                                        Please Enter Other Dispatched Name
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div> 
                                        </div>: ""}


                                        <div className="form-group">
                                            <label>Number of Boxes</label>
                                            {this.state.boxes &&
                                                this.state.boxes.map(
                                                    (item, index) => (
                                                        <div
                                                            className="form-group row position_up"
                                                            style={{zIndex:this.state.boxes.length-index}}
                                                            key={index}>
                                                            <div className="col-sm-5 mb-3">
                                                            <Select
                                                                    defaultValue={selectedNumOfBox}
                                                                    onChange={(e) => this.handleBoxType(e, index)}
                                                                    options={boxOptions}
                                                                    placeholder="Box Type"
                                                                />
                                                                {item.box_type ==
                                                                    "" &&
                                                                this.state
                                                                    .submitFlag ==
                                                                    1 ? (
                                                                    <span className="text-danger">
                                                                        Please
                                                                        enter
                                                                        box type
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="col-sm-6 mb-3">
                                                                <Input
                                                                    type="number"
                                                                    value={
                                                                        item.number_of_box
                                                                    }
                                                                    onChange={e =>
                                                                        this.handleBoxNumber(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            index,
                                                                        )
                                                                    }
                                                                    className="form-control"
                                                                    name=""
                                                                    placeholder="Number of Boxes"
                                                                />
                                                                {item.number_of_box ==
                                                                    "" &&
                                                                this.state
                                                                    .submitFlag ==
                                                                    1 && item?.numberOfBoxFlag ? (
                                                                    <span className="text-danger">
                                                                        Please
                                                                        enter
                                                                        number
                                                                        of boxes
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="">
                                                                {index != 0 && (
                                                                    <i
                                                                        className="fa fa-trash"
                                                                        onClick={() =>
                                                                            this.handleBoxRemove(
                                                                                index,
                                                                            )
                                                                        }></i>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ),
                                                )}
                                            <a
                                                href="javascript:;"
                                                className="sml-btn"
                                                onClick={() =>
                                                    this.addMoreBox()
                                                }>
                                                <i className="fa fa-plus"></i>{" "}
                                                Add More
                                            </a>
                                        </div>
                                        <div className="form-group">
                                            <label>GPS Tracker</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name=""
                                                onChange={this.handleTrackerChange}
                                            />
                                           {error &&
                                            this.state.submitFlag == 1 ? (
                                                <span className="text-danger">
                                                    {error.tracker
                                                        ? error.tracker
                                                        : ""}
                                                </span>
                                            ) : (
                                                ""
                                            )}

                                        </div>
                                        <div className="form-group">
                                            <label>Notes</label>
                                            <Input
                                                type="textarea"
                                                className="form-control"
                                                name=""
                                                onChange={this.handleNotesChange}

                                            />
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="">
                                            <button
                                                onClick={() =>
                                                    this.dispatchModalClose()
                                                }
                                                className="btn btn-secondary mr-2"
                                                data-dismiss="modal">
                                                Cancel
                                            </button>
                                            {loading == false && (
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        this.handleDispatch()
                                                    }>
                                                    Ok
                                                </button>
                                            )}
                                            {loading == true && (
                                                <button
                                                    className="btn btn-primary loader-icon"
                                                    disabled>
                                                    <Spinner className="img-svg"></Spinner>
                                                </button>
                                            )}
                                        </div>
                                    </ModalFooter>
                                </Modal>

                                {/* Dispatch Modal */}

                                <Modal
                                    size="md"
                                    backdrop="static"
                                    isOpen={this.state.detailsModal}
                                    toggle={() => this.detailsModalClose()}>
                                    <ModalHeader
                                        className=""
                                        toggle={() => this.detailsModalClose()}>
                                        Dispatched Details
                                    </ModalHeader>
                                    <ModalBody>
                                        {this.state.detailsModalResult.map(
                                            (details, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="form-group">
                                                        <strong>Courier</strong>
                                                        <div>
                                                            {details.courier}
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <strong>Dispatched By</strong>
                                                        {details.other_dispatched_by !== "" ?
                                                            <div>
                                                                {details.other_dispatched_by}
                                                            </div>
                                                            :
                                                            <div>
                                                                {details.dispatched_by}
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <strong>
                                                            Number of Boxes
                                                        </strong>
                                                        {details.bookingdispatchboxes &&
                                                            details.bookingdispatchboxes.map(
                                                                (box, ind) => (
                                                                    <React.Fragment
                                                                        key={
                                                                            ind
                                                                        }>
                                                                        <div className="form-group row">
                                                                            <div className="col">
                                                                                {
                                                                                    box.box_type
                                                                                }{" "}
                                                                                -{" "}
                                                                                {
                                                                                    box.number_of_box
                                                                                }{" "}
                                                                                {box.number_of_box >
                                                                                1
                                                                                    ? "Boxes"
                                                                                    : "Box"}
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                ),
                                                            )}
                                                    </div>
                                                    <div className="form-group">
                                                        <strong>GPS Tracker</strong>
                                                        <div>
                                                            {details.tracker}
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <strong>Notes</strong>
                                                        <div>
                                                            {details.notes}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ),
                                        )}
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="">
                                            <button
                                                className="btn btn-secondary"
                                                onClick={() =>
                                                    this.detailsModalClose()
                                                }>
                                                Close
                                            </button>
                                        </div>
                                    </ModalFooter>
                                </Modal>
                                {/* Dispatch Details Modal */}

                                <Modal
                                    size="lg"
                                    backdrop="static"
                                    isOpen={this.state.kitSetDetailModal}
                                    toggle={() =>
                                        this.kitSetDetailModalClose()
                                    }>
                                    <ModalHeader
                                        className=""
                                        toggle={() =>
                                            this.kitSetDetailModalClose()
                                        }>
                                        Set Details
                                    </ModalHeader>
                                    <ModalBody>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Item Code</th>
                                                    <th>Item Name</th>
                                                    <th>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.kitDetails
                                                    .setProductModules &&
                                                    this.state.kitDetails.setProductModules.map(
                                                        (product, ind) => (
                                                            <tr key={ind}>
                                                                <td>
                                                                    {
                                                                        product
                                                                            .productID
                                                                            .code
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        product
                                                                            .productID
                                                                            .name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        product.quantity
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ),
                                                    )}
                                            </tbody>
                                        </table>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="">
                                            <button
                                                className="btn btn-secondary"
                                                onClick={() =>
                                                    this.kitSetDetailModalClose()
                                                }>
                                                Close
                                            </button>
                                        </div>
                                    </ModalFooter>
                                </Modal>

                                {/* Set Details Modal */}
                            </div>
                        </div>
                        {/*<!--/row-->*/}
                    </div>
                </main>

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    let { repsList, refreshList, error, loading } =
        state.rootReducer.bookings;
    const { bookingList, activePage, totalItemsCount, limit, redirect } =
        state.rootReducer.bookingRequests;
    const { editSet, set } = state.rootReducer.sets;
    return {
        bookingList,
        refreshList,
        loading,
        activePage,
        totalItemsCount,
        limit,
        redirect,
        editSet,
        set,
        repsList,
        error,
    };
}

export default connect(mapStateToProps)(BookingDispatch);
