import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Input } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { hospitalActions } from "../../../../actions";
import AddHospital from "./Add/Index";
import UpdateHospital from "./Update/Index";
import DeleteHospital from "../../Admin/Hospitals/Delete/Index";
import { ExportSheet } from "react-xlsx-sheet";
import * as XLSX from "xlsx";
import Select from "react-select";

const head = [
  { title: "HOSPITAL NAME", dataIndex: "hospital_name" },
  { title: "SAC CODE", dataIndex: "sac_code" },
  { title: "CONTACT PERSON", dataIndex: "contact_person" },
  { title: "CONTACT PERSON PHONE NUMBER", dataIndex: "contact_person_phone_number" },
  { title: "SWITCH BOARD PHONE", dataIndex: "switch_board_phone" },
];

class Index extends Component {
  constructor(props) {
    super(props);
    // reset login status
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      addNewModal: false,
      editModal: false,
      deleteModal: false,
      name: "",
      id: "",
      editHospitalInfo: {
        id: "",
        name: "",
        switch_board_phone_number: "",
        contact_person_name: "",
        contact_person_phone_number: "",
        email: "",
        addressData: { address: [{ name: "" }] },
        departmentData: { department: [{ name: "" }] },
      },
      editId: "",
      search: "",
      activePage: 1,
      limit: 50,
      totalItemsCount: 1,
      submitModalFalg: 0,
      error: false,
      suggestionHospitalList: [],
      suggestionsActive: false,
      SACOptions: [
        { value: "", label: "Show All" },
        { value: "Empty", label: "Not Available SAC Code" },
        { value: "Not Empty", label: "Available SAC Code" }
      ],
      selectedSacValue: "",
      dataXlsx: [],
    };

    this.getList = this.getList.bind(this);
    this.addNewModalOpen = this.addNewModalOpen.bind(this);
    this.addNewModalClose = this.addNewModalClose.bind(this);
    this.editModalOpen = this.editModalOpen.bind(this);
    this.editModalClose = this.editModalClose.bind(this);
    this.deleteModalOpen = this.deleteModalOpen.bind(this);
    this.deleteModalClose = this.deleteModalClose.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.refreshGetList = this.refreshGetList.bind(this);

  }

  handleSacValue(e) {
    this.setState(
      {
        selectedSacValue: e.value,
        activePage: 1,
      },
      () => {
        this.getList();
      }
    );
  }


  showXlsData(data) {
    var dataXlsx = [];

    data &&
      data.map(item => {

        dataXlsx.push({
          hospital_name: item.name,
          sac_code: item.sac_code,
          contact_person: item.contact_person_name,
          switch_board_phone: item.switch_board_phone_number,
          contact_person_phone_number: item.contact_person_phone_number,
        });
      });

    this.setState({
      dataXlsx: dataXlsx,
    });
  }

  handlePageChange(pageNumber) {
    //console.log(`active page is ${pageNumber}`);
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getList();
      },
    );
  }

  handlePageStates(activePage, totalItemsCount, limit) {
    this.setState({
      activePage: activePage,
      totalItemsCount: totalItemsCount,
      limit: limit,
    });
  }

  getList() {
    this.props.dispatch(
      hospitalActions.getList({
        search: this.state.search,
        page: this.state.activePage,
        limit: this.state.limit,
        sac_filter: this.state.selectedSacValue
      }),
    );
  }

  handleSearch(val) {
    this.setState(
      {
        search: val,
        activePage: 1,
      },
      () => {
        this.getList();
      },
    );
  }

  addNewModalOpen() {
    this.setState({ addNewModal: !this.state.addNewModal });
  }

  addNewModalClose() {
    this.setState({
      addNewModal: false,
      submitModalFalg: 0,
    });
  }


  editModalOpen(id) {
    this.setState({ editId: id, editModal: !this.state.editModal });
  }

  editModalClose() {
    this.setState({
      editModal: false,
      editId: 0

    });
  }

  refreshGetList() {
    this.getList();
  }

  deleteModalOpen(id) {
    this.setState({ id: id, error: false, deleteModal: true });
  }

  deleteModalClose() {
    this.setState({
      deleteModal: false,
      name: "",
    });
  }



  componentDidMount() {
    this.getList();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.refreshListHospital == true) {
      this.setState(
        {
          activePage: 1,
        },
        () => {
          this.getList();
        },
      );
    }

    if (
      nextProps.activePage != "" &&
      nextProps.totalItemsCount != "" &&
      nextProps.limit != ""
    ) {
      this.handlePageStates(
        nextProps.activePage,
        nextProps.totalItemsCount,
        this.state.limit,
      );
    }

    if (nextProps.modal == false) {
      this.addNewModalClose();
    }

    if (nextProps.editModal == true) {
      this.editModalOpen();
    }

    if (nextProps.editModal == false) {
      this.editModalClose();
    }

    if (nextProps.deleteModal == false) {
      this.deleteModalClose();
    }

    if (nextProps.lists !== undefined) {
      this.showXlsData(nextProps.lists);
    }
  }

  render() {
    if (!this.state.user) {
      return <Redirect to="/" />;
    }
    if (this.state.user.data.user_role !== "reps") {
      return <Redirect to="/" />;
    }

    const { lists, loading } = this.props;
    let filteredLists = lists;

    if (lists != undefined) {
      console.log("lists", lists);
      if (this.state.selectedSacValue === "Not Empty") {
        filteredLists = lists.filter(item => item.sac_code != "");
        console.log("listes", filteredLists);
      }
      if (this.state.selectedSacValue === "Empty") {
        filteredLists = lists.filter(item => item.sac_code == "");
        console.log("listes", filteredLists);
      }
    }

    return (
      <div>
        <Navbar activePage="hospitals" />
        <div className="container">
          <div className="pt-4 row align-items-center">
            <div className="col-md-3">
              <div className="sub-menu">
                <h1 className="heading">Hospitals</h1>
              </div>
            </div>
            <div className="col-md-3">
              <form className="form-search">
                <Input
                  className="form-control mr-sm-2"
                  type="text"
                  placeholder="Search.."
                  value={this.state.search}
                  onChange={e => this.handleSearch(e.target.value)}
                />
              </form>
            </div>
            <div className="col-md-3">
              <form className="form-search">
                <Select
                  onChange={selectedOption => this.handleSacValue(selectedOption)}
                  options={this.state.SACOptions}
                  placeholder="SAC Code Filter"
                />
              </form>
            </div>

            <div className="col-md-3 text-right">
              <button
                className="btn btn-primary add_option mr-3"
                onClick={this.addNewModalOpen}>
                <i className="fa fa-plus"></i>{" "}
                <span>Add New</span>
              </button>

              < ExportSheet
                header={head}
                fileName={"hospitals"}
                dataSource={this.state.dataXlsx}
                xlsx={XLSX}
              >
                <button
                  className="btn btn-default add_option"
                >
                  Print
                </button>
              </ExportSheet >
            </div>
          </div>
          <div id="table-scroll" className="table-scroll mt-4">
            <table id="main-table" className="main-table">
              <thead>
                <tr>
                  <th scope="col" style={{ minWidth: "250px" }}>
                    Hospital Name
                  </th>
                  <th scope="col" style={{ minWidth: "100px" }}>
                    Sac Code
                  </th>
                  <th scope="col" style={{ minWidth: "100px" }}>
                    Contact Person
                  </th>
                  <th scope="col" style={{ minWidth: "100px" }}>
                    Contact Person Phone Number
                  </th>
                  <th scope="col" style={{ minWidth: "100px" }}>
                    Switch Board Phone
                  </th>
                  <th scope="col">Department</th>
                  <th scope="col" className="text-right" width="100">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {lists &&
                  lists.map(item => (
                    <tr key={item._id}>
                      <td data-th="Hospital Name">
                        <div className="">
                          <strong>{item.name}</strong>
                          {item.addresses &&
                            item.addresses.map((sub, subind) => (
                              <small key={subind} style={{ display: "block" }}>
                                {sub.name}
                              </small>
                            ))}
                        </div>
                      </td>
                      <td data-th="Sac Code">{item.sac_code}</td>
                      <td data-th="Contact Person">
                        {item.contact_person_name}
                      </td>
                      <td data-th="Contact Person Phone Number">
                        {item.contact_person_phone_number}
                      </td>
                      <td data-th="Switch Board Phone">
                        {item.switch_board_phone_number}
                      </td>
                      <td data-th="Department">
                        {item.departments.map((department, ind) => (
                          <div key={ind}>
                            {ind > 0 && ","}
                            {department.name}
                          </div>
                        ))}
                      </td>

                      <td className="text-right">
                        <div className="action-area dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <span className="dots">...</span>
                          </a>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown">
                            <a
                              className="dropdown-item"
                              onClick={() => this.editModalOpen(item._id)}
                              href="javascript:;">
                              Edit
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() => this.deleteModalOpen(item._id)}
                              href="javascript:;">
                              Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                {lists && lists.length == 0 && loading == false && (
                  <tr className="text-center">
                    <td colSpan="6">No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {lists && lists.length > 0 && (
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.limit}
            totalItemsCount={this.state.totalItemsCount}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            itemClass="page-item"
            linkClass="page-link"
            innerClass="pagination justify-content-center"
            activeLinkClass="active"
            nextPageText="Next"
            prevPageText="Previous"
          />
        )}

        {/* Add Modal */}
        <AddHospital
          addNewModalClose={this.addNewModalClose.bind(this)}
          addNewModal={this.state.addNewModal}
        />

        {/* Edit Modal */}
        <UpdateHospital
          editModalClose={this.editModalClose.bind(this)}
          editModal={this.state.editModal}
          editId={this.state.editId}
          refreshGetList={this.refreshGetList.bind(this)}
        />

        {/* Delete Modal */}
        <DeleteHospital
          deleteModalClose={this.deleteModalClose.bind(this)}
          deleteModal={this.state.deleteModal}
          deleteId={this.state.id}
          refreshGetList={this.refreshGetList.bind(this)}
        />

        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { lists, activePage, totalItemsCount, limit } =
    state.rootReducer.hospitals;
  const {
    error,
    modal,
    editModal,
    list,
    refreshListHospital,
    loading,
    deleteModal,
  } = state.rootReducer.hospitals;
  return {
    lists,
    error,
    modal,
    editModal,
    refreshListHospital,
    list,
    loading,
    deleteModal,
    activePage,
    totalItemsCount,
    limit,
  };
}

export default connect(mapStateToProps)(Index);
