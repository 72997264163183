import React, { Component } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { setActions } from "../../../../actions";
import { setService } from "../../../../services";
import { history } from "../../../../helpers";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            kitList: [],
            productList: [],
            selectedKit: "",
            selectedProductId: "",
            selectedProductList: [],
            quantity: "",
            error: {},
            //product_error: "",
            kit_error: "",
            searchProducts: "",
            setData: {
                set_name: "",
                description: "",
                kitID: "",
                productData: {
                    products: [],
                },
                setDocs:""
            },
            loading:false
        };

        this.handleOnKitSearch = this.handleOnKitSearch.bind(this);
        this.handleOnKitSelect = this.handleOnKitSelect.bind(this);
        this.handleKitList = this.handleKitList.bind(this);
        this.addSet = this.addSet.bind(this);
        this.fileHandler = this.fileHandler.bind(this);
    }

    handleSetData = (name, value) => {
        const { setData } = this.state;
        setData[name] = value;
        this.setState({
            setData,
        });
    };

    handleOnKitSearch(searchString, result) {
        console.log(result);
        const { setData } = this.state;
        setData["kitID"] = "";
        setData["selectedKit"] = "";
        this.setState({
            setData,
        });

        this.props.dispatch(setActions.getKitsList({ search: searchString }));
    }

    handleOnKitSelect(item) {
        const { setData } = this.state;
        setData["kitID"] = item._id;
        setData["selectedKit"] = item;
        this.setState({
            setData,
        });
    }

    fileHandler = (event) => {
        const { setData } = this.state;
        const file = event.target.files[0];
        setData["setDocs"] = file;
        this.setState({
            setData,
        });
    };

    handleKitList(kitList) {
        this.setState({
            kitList: kitList,
        });
    }

    addSet() {
        this.setState({loading : true});
        var selectedProductList = this.state.selectedProductList;
        var tempProducts = [];

        selectedProductList.map((product) => {
            var tempObj = {};
            tempObj.id = product._id;
            tempObj.quantity = product.quantity;
            tempProducts.push(tempObj);
        });

        this.setState(
            {
                setData: {
                    ...this.state.setData,
                    productData: {
                        products: tempProducts,
                    },
                },
            },
            () => {
                
                
                let obj = {
                    set_name : this.state.setData.set_name,
                    description : this.state.setData.description,
                    kitID : this.state.setData.kitID,
                    setDocs:this.state.setData.setDocs,
                    productData:{products : this.state.setData.productData.products},
                    selectedKit:this.state.setData.selectedKit
                };

                const formData = new FormData();
                formData.append("set_name", obj.set_name);
                formData.append("description", obj.description);
                formData.append("kitID", obj.kitID);
                formData.append("setDocs", obj.setDocs);
                formData.append("selectedKit", obj.selectedKit);
                formData.append("productData", JSON.stringify(obj.productData));
                setService.addSet(formData).then((res) => {
                    if(res.data.status === 200){
                        history.push("/admin/kits");
                    }
                })
                .catch((error) => {
                    this.setState({loading : false});
                    this.setState({error:error.response.data.data.errors});
                });
            }
        );
    }

    componentDidMount() {
        this.handleOnKitSearch("", "");
    //this.handleOnProductSearch("", "");
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);

        if (nextProps.kitsList) {
            this.handleKitList(nextProps.kitsList);
        }
        if (nextProps.productsList) {
            this.handleProductList(nextProps.productsList);
        }
    }

    render() {
        const { redirect,  } = this.props;
        const { error,loading } = this.state;
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        if (redirect) {
            this.setState({loading : false});
            return <Redirect to="/admin/kits" />;
        }

        return (
            <div>
                <Navbar activePage="kits" />
                <section className="b-burger">
                    <div className="container">
                        <h1 className="date-scroll pt-4">
                            {" "}
                            <Link to="/admin/kits">
                                <i className="fa fa-long-arrow-left smbtn"></i>{" "}
                                <strong>Back</strong>
                            </Link>
                        </h1>

                        <div className="row pt-4">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="d-card">
                                    <h4>Basic Details</h4>
                                    <div className="form-group">
                                        <label>Select Kit</label>
                                        <ReactSearchAutocomplete
                                            //className="form-control chosen-select"
                                            items={this.state.kitList}
                                            onSearch={this.handleOnKitSearch}
                                            onSelect={this.handleOnKitSelect}
                                            fuseOptions={{ minMatchCharLength: 1 }}
                                            inputDebounce="100"
                                            autoFocus
                                            placeholder="Search..."
                                            // styling={{searchIconMargin:"0 0 0 90%"}}
                                        />
                                        {this.state.error && error ? (
                                            <span className="text-danger">
                                                {error.kitID ? error.kitID : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Set Name (Set Unique Identifier)</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={this.state.setData.set_name}
                                            onChange={(e) =>
                                                this.handleSetData("set_name", e.target.value)
                                            }
                                        />
                                        {this.state.error && error ? (
                                            <span className="text-danger">
                                                {error.set_name ? error.set_name : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="form-group">
                                            <label>
                                                Upload PDF (Maximum file size: 25 MB)
                                            </label>
                                            <Input
                                                id="setfile"
                                                customLabel="Browse File"
                                                name="file"
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => this.fileHandler(e)}
                                            />
                                            {this.state.error && error ? (
                                                <span className="text-danger">
                                                    {error.setDocs ? error.setDocs : ""}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                    </div>

                                    <div className="form-group">
                                        <label>Set Description</label>
                                        <textarea
                                            className="form-control"
                                            rows="5"
                                            value={this.state.setData.description}
                                            onChange={(e) =>
                                                this.handleSetData("description", e.target.value)
                                            }
                                        ></textarea>
                                        {this.state.error && error ? (
                                            <span className="text-danger">
                                                {error.description ? error.description : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                               
                                <div className="mt-5 text-right">
                                    <Link
                                        to="/admin/kits"
                                        className="btn btn-secondary mb-3 mr-2"
                                    >
                                    Cancel
                                    </Link>

                                    {loading == false && (
                                        <button
                                            className="btn btn-primary mb-3"
                                            onClick={this.addSet}
                                        >
                                            Save Changes
                                        </button>
                                    )}
                                    {loading == true && (
                                        <button
                                            className="btn btn-primary loader-icon mb-3" 
                                            disabled
                                        >
                                            <Spinner className="img-svg"></Spinner>
                                        </button>
                                    )}

                                </div>
                            </div>
                            <div className="col-lg-4"></div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { kitsList, productsList, redirect } = state.rootReducer.sets;

    return {
        kitsList,
        productsList,
        redirect
    };
}

export default connect(mapStateToProps)(Index);
