import React, { Component } from "react";
import { Link } from "react-router-dom";
//import { connect } from "react-redux";

export default class KitNavbar extends Component {
    // constructor(props) {
    //     super(props);
    //     // reset login status
    //     this.props.dispatch(authActions.logout());
    //     this.state = {
    //         username: "",
    //         password: "",
    //         submitted: false
    //     };

    //     this.handleUsernameChange = this.handleUsernameChange.bind(this);
    //     this.handlePasswordChange = this.handlePasswordChange.bind(this);
    //     this.handleSubmit = this.handleSubmit.bind(this);
    // }
    
    // handleSubmit(e) {
    //     console.log("dsads");
    //     e.preventDefault();
    //     this.setState({ submitted: true });
    //     const { username, password } = this.state;
    //     const { dispatch } = this.props;
    //     console.log(username,password);
        
    //     //if (username && password) {
    //     dispatch(authActions.login(username, password));
    //     //}
    // }

    // handleUsernameChange(e) {
    //     this.setState({ username: e.target.value });
    // }

    // handlePasswordChange(e) {
    //     this.setState({ password: e.target.value });
    // }

    // componentDidMount(){
          
    // }

    render() {
        //const { loggingIn } = this.props;
        //const { username, password} = this.state;
        return (
            <div>
                <ul className="sub-menu">
                    <li><Link to="/admin/categories" className={"kit-navbar "+(this.props.activePage == "categories" ? "active" : "")}>Kits</Link></li>
                    <li><Link to="/admin/products" className={"kit-navbar "+(this.props.activePage == "products" ? "active" : "")}>Products</Link></li>
                    <li><Link to="/admin/kits" className={"kit-navbar "+(this.props.activePage == "sets" ? "active" : "")}>Sets</Link></li>    
                </ul> 
            </div>
        );
    }
}

//export default connect()(KitNavbar);