import { authHeader } from "../helpers";

export const productService = {
    addProduct,
    getProductsList,
    getById,
    importProducts,
    exportProduct,
    update,
    delete: _delete
    
};

function exportProduct(product) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(product)
    };
    return fetch(process.env.REACT_APP_APIURL + "products/productExport", requestOptions).then(handleResponse);
}

function importProducts(formData) {
    const requestOptions = {
        method: "POST",
        body: formData,
    };
    return fetch(process.env.REACT_APP_APIURL + "products/importProducts", requestOptions)
        .then(handleResponse);
}


function getProductsList(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"products/getProducts", requestOptions).then(handleResponse)
        .then(productsList => {
            return productsList;
        });
}

function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_APIURL+`products/getProduct/${id}`, requestOptions).then(handleResponse);
}

function addProduct(productInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(productInfo)
    };

    return fetch(process.env.REACT_APP_APIURL+"products/saveProducts", requestOptions).then(handleResponse);
}

function update(productInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(productInfo)
    };

    return fetch(process.env.REACT_APP_APIURL+"products/updateProducts", requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(id)
    };

    return fetch(process.env.REACT_APP_APIURL+"products/deleteProducts", requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}