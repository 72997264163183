import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./DashboardSidebar";
import Return from "../Booking/Return";
import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import moment from "moment";
import dateFormat from "dateformat";
import { returnBookingActions, setActions } from "../../../../actions";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
}${separator}${date < 10 ? `0${date}` : `${date}`}`;

const head = [
    { title: "BOOKING ID", dataIndex: "booking" },
    { title: "EVENT NAME", dataIndex: "event_name" },
    { title: "SET", dataIndex: "set" },
    { title: "BOXES", dataIndex: "boxes" },
];

class BookingReturn extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            //bookingList: false
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 0,
            kitSetDetailModal: false,
            kitSetData: [],
            kitDetailsClickFlag: 0,
            kitDetails: [],
            dispatchModal: false,
            acceptModal: false,
            bookingId: 0,
            users: [],
            selectedUser: [{ label: "Booked By", value: "" }],
            selectedUserId: "",
            // hospitals: [],
            // selectedHospital: [{ label: "All Hospital", value: "" }],
            // selectedHospitalId: "",
            requestCount: 0,
            rejectComment: "",
            trackers: [
                { label: "A", value: "A" },
                { label: "B", value: "B" },
            ],
            selectedTrackers: [],
            date: todayDate,
            todate: todayDate,
            dayActive: "today",
            returnModal: false,
            dataXlsx: [],
        };

        this.getList = this.getList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.returnModalOpen = this.returnModalOpen.bind(this);
        this.returnModalClose = this.returnModalClose.bind(this);
        this.returnSuccess = this.returnSuccess.bind(this);
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getList();
            },
        );
    }

    toDay() {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const todayDate = `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date < 10 ? `0${date}` : `${date}`}`;

        this.setState(
            {
                dayActive: "today",
                date: todayDate,
                todate: todayDate,
            },
            () => {
                this.getList();
            },
        );
    }
    nextDays() {
        let newDate = new Date();
        let date = newDate.getDate() + 1;
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const tomorrowDate = `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date < 10 ? `0${date}` : `${date}`}`;

        this.setState(
            {
                dayActive: "tomorrow",
                date: tomorrowDate,
                todate: tomorrowDate,
            },
            () => {
                this.getList();
            },
        );
    }

    pastBookings() {
        var pastYearDate = moment().subtract(2, "years").format("YYYY-MM-DD");
        var yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");

        this.setState(
            {
                dayActive: "past",
                date: pastYearDate,
                todate: yesterdayDate,
            },
            () => {
                this.getList();
            },
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        if (totalItemsCount == undefined) {
            totalItemsCount = 0;
        }

        this.setState({
            activePage: activePage,
            totalItemsCount: totalItemsCount,
            limit: limit,
        });
    }

    getList() {
        this.props.dispatch(
            returnBookingActions.getList({
                bookedByFilter: this.state.selectedUserId,
                hospitalFilter: "",
                type: ["loan"],
                status: ["dispatch", "close"],
                returnDateFiter: this.state.date,
                toReturnDateFiter: this.state.todate,
                search: this.state.search,
                page: this.state.activePage,
                limit: this.state.limit,
            }),
        );
    }

    handleSearch(val) {
        this.setState(
            {
                search: val,
                activePage: 1,
            },
            () => {
                this.getList();
            },
        );
    }

    kitSetDetailModalClose() {
        this.setState({
            kitSetDetailModal: false,
            kitSetData: [],
        });
    }

    showKitDetails(kitDetails) {
        if (this.state.kitDetailsClickFlag == 1) {
            this.setState({
                kitDetails: kitDetails,
                kitSetDetailModal: true,
                kitDetailsClickFlag: 0,
            });
        }
    }

    handleKitDetails(id) {
        this.setState({
            kitDetailsClickFlag: 1,
        });

        this.props.dispatch(setActions.getById(id));
    }

    returnModalOpen(id) {
        this.setState({
            returnModal: true,
            bookingId: id,
        });
    }

    returnModalClose() {
        this.setState({
            bookingId: 0,
            returnModal: false,
        });
    }

    returnSuccess() {
        this.setState(
            {
                bookingId: 0,
                returnModal: false,
                activePage: 1,
            },
            () => {
                this.getList();
            },
        );
    }

    showXlsData(data) {
        var dataXlsx = [];
        var kitset = "";
        var boxesSet = "";

        data &&
            data.map(item => {
                kitset = "";
                boxesSet = "";

                item.bookingKitSetId &&
                    item.bookingKitSetId.map(kit => {
                        if (kitset != "") {
                            kitset += "\n" + kit.setId[0].set_name;
                        } else {
                            kitset += kit.setId[0].set_name;
                        }
                    });

                item.bookingdispatches &&
                    item.bookingdispatches[0].bookingdispatchboxes.map(box => {
                        if (boxesSet != "") {
                            boxesSet +=
                                "\n" +
                                box.box_type +
                                " - " +
                                box.number_of_box +
                                (box.number_of_box > 1 ? "Boxes" : "Box");
                        } else {
                            boxesSet +=
                                box.box_type +
                                " - " +
                                box.number_of_box +
                                (box.number_of_box > 1 ? "Boxes" : "Box");
                        }
                    });

                dataXlsx.push({
                    booking: item.bookings.booking_id,
                    event_name:  item.bookings.event_name,
                    set: kitset,
                    boxes: boxesSet,
                });
            });

        this.setState({
            dataXlsx: dataXlsx,
        });
    }

    capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    componentDidMount() {
        this.toDay();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if (nextProps.refreshList == true || nextProps.redirect == true) {
            this.setState(
                {
                    activePage: 1,
                    dispatchModal: false,
                },
                () => {
                    this.getList();
                },
            );
        }

        if (
            nextProps.activePage != "" &&
            nextProps.totalItemsCount != "" &&
            nextProps.limit != ""
        ) {
            this.handlePageStates(
                nextProps.activePage,
                nextProps.totalItemsCount,
                this.state.limit,
            );
        }

        if (nextProps.editSet == true) {
            this.showKitDetails(nextProps.set);
        }

        if (nextProps.returnList !== undefined) {
            this.showXlsData(nextProps.returnList);
        }
    }

    render() {
        const { returnList, loading } = this.props;
        if (!this.state.user) {
            return <Redirect to="/" />;
        }

        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <Navbar activePage="dashboard" />
                <main className="offset">
                    <div className="container-fluid">
                        <div className="row pt-4">
                            <div className="col-lg-2 side-filters">
                                <span className="side-heading">Dashboard</span>
                                <DashboardSidebar
                                    activePage="return"
                                    returnCount={this.state.totalItemsCount}
                                />
                            </div>

                            <div className="col-lg-10">
                                <div className="ground">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6">
                                            <div className="sub-menu">
                                                <div
                                                    className="btn-group"
                                                    role="group"
                                                    aria-label="Basic example">
                                                    <button
                                                        type="button"
                                                        className={
                                                            "btn btn-secondary " +
                                                            (this.state
                                                                .dayActive ==
                                                            "today"
                                                                ? "active "
                                                                : "")
                                                        }
                                                        onClick={() =>
                                                            this.toDay()
                                                        }>
                                                        Today
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={
                                                            "btn btn-secondary mx-1 " +
                                                            (this.state
                                                                .dayActive ==
                                                            "tomorrow"
                                                                ? "active "
                                                                : "")
                                                        }
                                                        onClick={() =>
                                                            this.nextDays()
                                                        }>
                                                        Tomorrow
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={
                                                            "btn btn-secondary " +
                                                            (this.state
                                                                .dayActive ==
                                                            "past"
                                                                ? "active "
                                                                : "")
                                                        }
                                                        onClick={() =>
                                                            this.pastBookings()
                                                        }>
                                                        Past Bookings
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <form className="form-search">
                                                <Input
                                                    className="form-control mr-sm-2"
                                                    type="text"
                                                    placeholder="Search.."
                                                    value={this.state.search}
                                                    onChange={e =>
                                                        this.handleSearch(
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                            </form>
                                        </div>
                                        <div className="col-md-4 text-right">
                                            {/*<button type="button" className="btn btn-default"> Print </button>*/}
                                            <ExportSheet
                                                header={head}
                                                fileName={"booking-return"}
                                                dataSource={this.state.dataXlsx}
                                                xlsx={XLSX}>
                                                <button className="btn btn-default add_option">
                                                    Print
                                                </button>
                                            </ExportSheet>
                                        </div>
                                    </div>
                                    <div className="table-scroll mt-4">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            minWidth: "200px",
                                                        }}>
                                                        Booking #
                                                    </th>
                                                    <th scope="col">
                                                        Event Name{" "}
                                                    </th>
                                                    <th scope="col">Set</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">
                                                        Dispatched Boxes
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="text-right"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {returnList &&
                                                    returnList.map(item => (
                                                        <tr key={item._id}>
                                                            <td
                                                                width="200"
                                                                data-th="Booking #"
                                                                className="align-top">
                                                                <Link
                                                                    to={
                                                                        "/admin/booking/details/" +
                                                                        item
                                                                            .bookings
                                                                            ._id
                                                                    }>
                                                                    <span>
                                                                        <strong>
                                                                            {" "}
                                                                            {
                                                                                item
                                                                                    .bookings
                                                                                    .booking_id
                                                                            }{" "}
                                                                        </strong>
                                                                    </span>
                                                                </Link>
                                                            </td>
                                                            <td
                                                                data-th="Hospital Name"
                                                                className="align-top">
                                                                <div className="">
                                                                    {
                                                                        item
                                                                            .bookings
                                                                            .event_name
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td
                                                                data-th="Set"
                                                                className="align-top">
                                                                <ul className="list">
                                                                    {item.bookingKitSetId &&
                                                                        item.bookingKitSetId.map(
                                                                            (
                                                                                kset,
                                                                                ind,
                                                                            ) => (
                                                                                <React.Fragment
                                                                                    key={
                                                                                        ind
                                                                                    }>
                                                                                    <li>
                                                                                        <span
                                                                                            className={
                                                                                                "status circle mr-1 " +
                                                                                                (kset.status ==
                                                                                                "close"
                                                                                                    ? "green "
                                                                                                    : "red ")
                                                                                            }
                                                                                            data-toggle="tooltip"
                                                                                            data-placement="top"
                                                                                            title=""
                                                                                            data-original-title="Retuned"></span>
                                                                                        <span>
                                                                                            {
                                                                                                kset
                                                                                                    .setId[0]
                                                                                                    .set_name
                                                                                            }{" "}
                                                                                        </span>
                                                                                    </li>
                                                                                </React.Fragment>
                                                                            ),
                                                                        )}
                                                                </ul>
                                                            </td>
                                                            <td className="align-top">
                                                                {dateFormat(
                                                                    moment(
                                                                        item
                                                                            .bookings
                                                                            .collection_date,
                                                                    ).add(
                                                                        1,
                                                                        "days",
                                                                    ),
                                                                    "ddd, mmm dS, yyyy",
                                                                )}
                                                            </td>
                                                            <td
                                                                data-th="Dispatched Boxes"
                                                                className="align-top">
                                                                {item.bookingdispatches &&
                                                                    item.bookingdispatches[0].bookingdispatchboxes.map(
                                                                        (
                                                                            box,
                                                                            ind,
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    ind
                                                                                }>
                                                                                <div>
                                                                                    {
                                                                                        box.box_type
                                                                                    }{" "}
                                                                                    -{" "}
                                                                                    {
                                                                                        box.number_of_box
                                                                                    }{" "}
                                                                                    {box.number_of_box >
                                                                                    1
                                                                                        ? "Boxes"
                                                                                        : "Box"}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        ),
                                                                    )}
                                                            </td>
                                                            <td className="text-right align-top">
                                                                {item.bookings
                                                                    .status ==
                                                                    "dispatch" &&
                                                                    (this.state
                                                                        .dayActive ==
                                                                        "today" ||
                                                                        this
                                                                            .state
                                                                            .dayActive ==
                                                                            "past") && (
                                                                        <a
                                                                            href="javascript:;"
                                                                            onClick={() =>
                                                                                this.returnModalOpen(
                                                                                    item
                                                                                        .bookings
                                                                                        ._id,
                                                                                )
                                                                            }
                                                                            className="btn btn-primary ml-2">
                                                                            Return
                                                                        </a>
                                                                    )}
                                                                {item.bookings
                                                                    .status ==
                                                                    "close" &&
                                                                    (this.state
                                                                        .dayActive ==
                                                                        "today" ||
                                                                        this
                                                                            .state
                                                                            .dayActive ==
                                                                            "past") && (
                                                                        <span className="capsule green-dim">
                                                                            Returned{" "}
                                                                        </span>
                                                                    )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                {returnList &&
                                                    returnList.length == 0 &&
                                                    loading == false && (
                                                        <tr className="text-center">
                                                            <td colSpan="5">
                                                                No Record Found
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {returnList && returnList.length > 0 && (
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={
                                            this.state.totalItemsCount
                                        }
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(
                                            this,
                                        )}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                    />
                                )}

                                <Modal
                                    size="lg"
                                    backdrop="static"
                                    isOpen={this.state.kitSetDetailModal}
                                    toggle={() =>
                                        this.kitSetDetailModalClose()
                                    }>
                                    <ModalHeader
                                        className=""
                                        toggle={() =>
                                            this.kitSetDetailModalClose()
                                        }>
                                        Set Details
                                    </ModalHeader>
                                    <ModalBody>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Item Code</th>
                                                    <th>Item Name</th>
                                                    <th>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.kitDetails
                                                    .setProductModules &&
                                                    this.state.kitDetails.setProductModules.map(
                                                        (product, ind) => (
                                                            <tr key={ind}>
                                                                <td>
                                                                    {
                                                                        product
                                                                            .productID
                                                                            .code
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        product
                                                                            .productID
                                                                            .name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        product.quantity
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ),
                                                    )}
                                            </tbody>
                                        </table>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="">
                                            <button
                                                className="btn btn-secondary"
                                                onClick={() =>
                                                    this.kitSetDetailModalClose()
                                                }>
                                                Close
                                            </button>
                                        </div>
                                    </ModalFooter>
                                </Modal>

                                {/* Set Details Modal */}

                                <Return
                                    bookingId={this.state.bookingId}
                                    returnModal={this.state.returnModal}
                                    returnModalOpen={this.returnModalOpen}
                                    returnModalClose={this.returnModalClose}
                                    returnSuccess={this.returnSuccess}
                                />
                            </div>
                        </div>
                        {/*<!--/row-->*/}
                    </div>
                </main>

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        returnList,
        activePage,
        totalItemsCount,
        limit,
        loading,
        redirect,
    } = state.rootReducer.returnBookings;
    const { editSet, set } = state.rootReducer.sets;
    return {
        returnList,
        loading,
        activePage,
        totalItemsCount,
        limit,
        redirect,
        editSet,
        set,
    };
}

export default connect(mapStateToProps)(BookingReturn);
