import { returnBookingConstants } from "../constants";
import { returnBookingService } from "../services";

export const returnBookingActions = {
    getList,
    countByDate
};

function getList(search) {
    return dispatch => {
        dispatch(request());

        returnBookingService.getList(search)
            .then(
                returnList => dispatch(success(returnList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: returnBookingConstants.GETALLRETURNBOOKINGS_REQUEST }; }
    function success(returnList) { return { type: returnBookingConstants.GETALLRETURNBOOKINGS_SUCCESS, returnList }; }
    function failure(error) { return { type: returnBookingConstants.GETALLRETURNBOOKINGS_FAILURE, error }; }
}



function countByDate(info) {
    return dispatch => {
        dispatch(request());

        returnBookingService.countByDate(info)
            .then(
                count => dispatch(success(count)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: returnBookingConstants.COUNTRETURNBOOKINGS_REQUEST }; }
    function success(count) { return { type: returnBookingConstants.COUNTRETURNBOOKINGS_SUCCESS, count }; }
    function failure(error) { return { type: returnBookingConstants.COUNTRETURNBOOKINGS_FAILURE, error }; }
}