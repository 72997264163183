import { collectionBookingConstants } from "../constants";
import { collectionBookingService } from "../services";

export const collectionBookingActions = {
    getList,
    countByDate
};

function getList(search) {
    return dispatch => {
        dispatch(request());

        collectionBookingService.getList(search)
            .then(
                collectionList => dispatch(success(collectionList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: collectionBookingConstants.GETALLCOLLECTIONBOOKINGS_REQUEST }; }
    function success(collectionList) { return { type: collectionBookingConstants.GETALLCOLLECTIONBOOKINGS_SUCCESS, collectionList }; }
    function failure(error) { return { type: collectionBookingConstants.GETALLCOLLECTIONBOOKINGS_FAILURE, error }; }
}



function countByDate(info) {
    return dispatch => {
        dispatch(request());

        collectionBookingService.countByDate(info)
            .then(
                count => dispatch(success(count)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: collectionBookingConstants.COUNTCOLECTIONNBOOKINGS_REQUEST }; }
    function success(count) { return { type: collectionBookingConstants.COUNTCOLECTIONBOOKINGS_SUCCESS, count }; }
    function failure(error) { return { type: collectionBookingConstants.COUNTCOLECTIONBOOKINGS_FAILURE, error }; }
}