import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { hospitalService } from "../../../../../services";
import { ReactComponent as Spinner } from "../../../../../assets/images/Spinner-1s-200px.svg";
import Select from "react-select";

class Delete extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            id: "",
            error: false,
            loading: false,
            hospitalData: [],
            selectedHospital: "",
            updateError:""
        };
        this.deleteModalClose = this.deleteModalClose.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.suggestHospital = this.suggestHospital.bind(this);
        this.handleHospitalSelect = this.handleHospitalSelect.bind(this);
    }


    deleteModalClose() {
        this.resetData();
        this.props.deleteModalClose();
    }

    resetData() {
        this.setState({
            error: "",
            id: "",
            selectedHospital:"",
            updateError:""
        });
    }

    doDelete() {
        hospitalService.delete({ id: this.props.deleteId })
            .then(response => {
                console.log(response);
                this.deleteModalClose();
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error updating item:", error);
                this.setState({ error: error });
                this.suggestHospital();
            });
    }


    suggestHospital() {
        hospitalService.getList({ limit: 50, page: 1, search: "" })
            .then((res) => {
                console.log("hospitalList", res.data);
                this.setState({ hospitalData: res.data });
            }).catch((error) => {
                console.log("error", error);
            });
    }

    handleHospitalSelect(selectedOption) {
        this.setState({ selectedHospital: selectedOption });
        console.log(selectedOption.label);
    }

    async updateHospital() {
        const { selectedHospital } = this.state;
            hospitalService.updateHospitalList({
                prevHospitalId: this.props.deleteId,
                newHospitalId: selectedHospital.value
            }).then((res) => {
                console.log("updateHospitalList", res.data);
                this.deleteModalClose();
                window.location.reload();
            }).catch((error) => {
                console.log("updateerror", error);
                this.setState({ updateError: error });
            });
            
    }
  
    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("nnnnnnn", nextProps);
        if (nextProps.deleteModal == true && nextProps.deleteId > 0) {
            this.doDelete();
        }
    }

    render() {
        const { error, loading } = this.state;
        const { hospitalData,updateError } = this.state;
        const { selectedHospital } = this.state;

        const options = hospitalData
            .filter((hospital) => hospital._id !== this.props.deleteId)
            .map((hospital) => ({
                value: hospital._id,
                label: hospital.name,
            }));

        return (
            <div>
                <Modal
                    size="md"
                    backdrop="static"
                    isOpen={this.props.deleteModal}
                    toggle={() => this.deleteModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.deleteModalClose()}>
                        Delete Hospital
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">Please confirm if you want to delete this hospital?</div>
                        {error != "" ? <span className="text-danger">{error.common ? error.common : ""}</span> : ""}
                        {error && error.common && (
                            <div className="form-group">
                                <label>Select a Hospital</label>
                                <Select
                                    value={selectedHospital}
                                    onChange={this.handleHospitalSelect}
                                    options={options}
                                    placeholder="Select a Hospital"
                                />
                                {updateError != "" ? <span className="text-danger">{updateError.newHospitalId ? updateError.newHospitalId : ""}</span> : ""}
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                onClick={() => this.deleteModalClose()}
                                className="btn btn-secondary mr-2"
                                data-dismiss="modal">
                                Cancel
                            </button>
                            {loading == false && !error && !error.common ? (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.doDelete()}>
                                    Ok
                                </button>
                            )
                                :
                                <button className="btn btn-primary" onClick={() => this.updateHospital()}>Update</button>
                            }
                            {loading == true && (
                                <button
                                    className="btn btn-primary loader-icon"
                                    disabled>
                                    <Spinner className="img-svg"></Spinner>
                                </button>
                            )}
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Add Modal */}
            </div>
        );
    }
}


export default Delete;

