import React, { useEffect, useState, useRef } from "react";
import {
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
} from "reactstrap";
import { surgeonService } from "../../../../../services/surgeon.services";

const Index = (props) => {
    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState("");
    const [loading, setLoading] = useState(false);
    const [commentbyid, setCommentById] = useState("");
    const [commentlist, setCommentList] = useState([]);
    const [page, setPage] = useState(1);
    // const [noRecord, setNoRecord] = useState(false);
    const [randomPage, setRandomPage] = useState(1);

    const listInnerRef = useRef(null);

    //userid getting from localstorage
    const getUserId = () => {
        const userid = JSON.parse(localStorage.getItem("user"));
        setCommentById(userid.data._id);
        if (props.surgeonid != 0) {
            getComment();
        }
    };

    // getcomment to set
    const getComment = () => {
        console.log("get comment");
        surgeonService
            .getSurgeonsComment({
                page: page,
                surgeonId: props.surgeonid,
                search: "",
            })
            .then((res) => {
                if (page > 1 && commentlist.length > 0) {
                    let oldList = commentlist;
                    let newList = oldList.concat(res.data);
                    setCommentList(newList);
                    // setNoRecord(true);
                } else {
                    //listInnerRef.current?.scrollIntoView({block: "end",behavior: "smooth"});
                    setCommentList([]);
                    // setNoRecord(false);

                    if (page == 1 || commentlist.length > 0) {
                        setTimeout(function () {
                            setCommentList(res.data);
                            // setNoRecord(true);
                        }, 300);
                    }
                }
            });
    };

    const handleComment = (e) => {
        setComment(e.target.value);
    };

    //post comment
    const handleCommentSubmit = () => {
        const addComment = {
            comment: comment,
            commentBy: commentbyid,
            surgeonId: props.surgeonid,
        };
        console.log(addComment);
        surgeonService
            .postSurgeonsComment(addComment)
            .then((res) => {
                console.log(res);
                setLoading(false);
                setComment("");
                setCommentError("");
                setPage(1);
                setRandomPage((Math.random() + 1).toString(36).substring(7));
            })
            .catch((err) => {
                setCommentError(err.comment);
            });
    };
    const handleDelete = (id) => {
        surgeonService.deleteSurgeonsComment({ id: id }).then((res) => {
            console.log(res);
            setPage(1);
            setRandomPage((Math.random() + 1).toString(36).substring(7));
        });
    };

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                listInnerRef.current;
            let newClientHeight = clientHeight + 1;
            if (scrollTop + newClientHeight === scrollHeight) {
                // TO SOMETHING HERE
                console.log("Reached bottom");
                let newPage = Number(page) + 1;
                setPage(newPage);
                setRandomPage((Math.random() + 1).toString(36).substring(7));
            }
        }
    };

    const handleCloseCommentModal = () => {
        setCommentError("");
        props.closeCommentModal();
    };

    /*const getUserInitials = (Name) => {
        if (Name != "") {
            const initials = Name.charAt(0);
            return initials.toUpperCase();
        }
        else {
            return "";
        }

    };*/

    useEffect(() => {
        setCommentList([]);
        // setNoRecord(false);
        setCommentError("");
        getUserId();
    }, [props.surgeonid]);

    useEffect(() => {
        if (props.surgeonid != 0) {
            getComment();
        }
    }, [randomPage]);

    return (
        <>
            <Modal
                size="lg"
                height="auto"
                backdrop="static"
                isOpen={props.commentModal}
                toggle={() => handleCloseCommentModal()}
                className="surgeons-modal"
            >
                <ModalHeader
                    className=""
                    toggle={() => handleCloseCommentModal()}
                >
                    Comment
                </ModalHeader>
                <ModalBody>
                    <form className="">
                        <div className="form-group row">
                            <div className="col">
                                <label>Comment</label>
                                <Input
                                    type="textarea"
                                    className="form-control"
                                    placeholder="Add your comment..."
                                    value={comment}
                                    onChange={handleComment}
                                    style={{ height: "100px" }}
                                />
                                {commentError && commentError && (
                                    <span className="text-danger">
                                        {commentError}
                                    </span>
                                )}
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter className="border-top-0 border-bottom mb-4 pb-4">
                    <div className="">
                        <button
                            onClick={() => handleCloseCommentModal()}
                            className="btn btn-secondary mr-2"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                        {loading ? (
                            <button
                                className="btn btn-primary loader-icon"
                                disabled
                            >
                                <Spinner className="img-svg"></Spinner>
                            </button>
                        ) : (
                            <button
                                className="btn btn-primary"
                                onClick={handleCommentSubmit}
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </ModalFooter>
                <div
                    ref={listInnerRef}
                    onScroll={onScroll}
                    className="table-scroll px-4"
                    style={{ height: "200px" }}
                >
                    <div className="surgeons-comment">
                        {commentlist.length > 0 &&
                            commentlist.map((item) => (
                                <div className="list d-flex" key={item._id}>
                                    <div className="">
                                        <div className="avtar">
                                            {item.userData[0]?.first_name.charAt(0).toUpperCase()} 
                                            {item.userData[0]?.last_name.charAt(0).toUpperCase()} 
                                        </div>
                                    </div>
                                    <div className="list-area">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mr-2">
                                                    {
                                                        item.userData[0]?.first_name +" "+item.userData[0]?.last_name
                                                    }
                                                </h5>
                                                <span className="date">
                                                    {new Date(
                                                        item.surgeoncomments.createdAt
                                                    ).toLocaleString([], {
                                                        year: "numeric",
                                                        month: "short",
                                                        day: "numeric",
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                        hour12: true,
                                                    })}
                                                </span>
                                            </div>
                                            <a href="javascript:;" title="Delete"
                                                onClick={() =>
                                                    handleDelete(
                                                        item.surgeoncomments._id
                                                    )
                                                }
                                            >
                                                <i className="fa fa-trash"></i>
                                            </a>
                                        </div>
                                        <div className="comment-text">
                                            <p>
                                                {item.surgeoncomments.comment}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    {/* {noRecord == true && commentlist.length == 0 && (
                        <p className="text-center">
                            <td colSpan="6">No Record Found</td>
                        </p>
                    )} */}
                </div>
            </Modal>
        </>
    );
};

export default Index;
