import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input

} from "reactstrap";
import Select from "react-select";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import {
    bookingActions,
    kitActions,
    setActions,
} from "../../../../actions";
import dateFormat from "dateformat";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import DatePicker from "react-datepicker";
import { bookingService } from "../../../../services/booking.services";
import KitModal from "../Kits/loanCharge";

const deliverySlots = [
    { value: "Pre 9AM", label: "Pre 9AM" },
    { value: "Pre 10AM", label: "Pre 10AM" },
    { value: "Pre 12", label: "Pre 12" },
    { value: "Same Day", label: "Same Day" },
];

const operationSlots = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
    { value: "TBD", label: "TBD" },
];

const dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class Edit extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            kitSetErrorFlag: 0,
            updateBookingInfo: {
                id: "",
                booking_type: "loan",
                // po_number: "",
                event_name: "",
                name_of_location: "",
                address: "",
                postcode: "",
                phone_number: "",
                delivery_address: "",
                collection_address: "",
                contact_detail: "",
                shipping_charge: "",
                additional_notes: "",
                assigned_rep: "",
                additional_reps: [],
                delivery_date: new Date("2022-05-11"),
                delivery_date_slot: "11 am",
                operation_date: new Date("2022-05-11"),
                operation_date_slot: "12 pm",
                collection_date: new Date("2022-05-14"),
                return_date: "",
                reject_status: "",
                kitData: [],
                kitSetData: [],
                log_status: "",
                log_by_id: "",
                other_opration: "",
                mail_sent: false,
                // po_check: false,
                pickup_location_checkbox: false,
                pickup_location_text: "",
                isOpen: false,
            },
            bookingInfo: {},
            selectedDeliverySlot: [],
            selectedOperationSlots: [],
            selectedRep: [],
            selectedAdditionalRep: [],
            reps: [],
            additionalReps: [],
            repsDetails: [],
            hospitalContactPerson: "",
            hospitalContactPersonEmail: "",
            hospitalContactPersonPhone: "",
            searchReps: "",
            searchOps: "",
            kits: [],
            kitListFlag: 0,
            selectedKit: [],
            kitWithKitSet: [],
            kitSetDetailModal: false,
            kitDetails: [],
            kitDetailsClickFlag: 0,
            // bookingType: [
            //     { value: "loan", label: "Loan" },
            //     { value: "consignment", label: "Consignment" },
            // ],
            // selectedBookingType: [],
            singleSelectedKitId: 0,
            Allkits: [],
            cartFlag: false,
            cartItemCount: 0,
            addedKitFlag: false,
        };

        this.getList = this.getList.bind(this);
        this.getSingleBooking = this.getSingleBooking.bind(this);
        this.handleUpdateBookingInfo = this.handleUpdateBookingInfo.bind(this);
        this.setSelectedOptionDeliverySlot =
            this.setSelectedOptionDeliverySlot.bind(this);
        this.setSelectedOptionOperationSlots =
            this.setSelectedOptionOperationSlots.bind(this);
        this.setSelectedOptionRep = this.setSelectedOptionRep.bind(this);
        this.setSelectedOptionAdditionalRep =
            this.setSelectedOptionAdditionalRep.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setSelectedOptionKit = this.setSelectedOptionKit.bind(this);
        this.handleAddKit = this.handleAddKit.bind(this);
        this.handelAddRemovSet = this.handelAddRemovSet.bind(this);
        this.handleHireCharge = this.handleHireCharge.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.kitSetDetailModalClose = this.kitSetDetailModalClose.bind(this);
        // this.setSelectedOptionBookingType =
        //     this.setSelectedOptionBookingType.bind(this);
    }

    openModal = () => {
        this.setState({ isOpen: true });
    };

    closeModal = () => {
        this.setState({ isOpen: false });
    };

    getSingleBooking(id) {
        this.props.dispatch(bookingActions.getById(id));
    }

    getList() {
        this.props.dispatch(
            bookingActions.getRepsList({ search: this.state.searchReps }),
        );
        this.props.dispatch(kitActions.kitListDropdown({ search: "" }));
    }

    cartToggle() {
        this.setState({
            cartFlag: !this.state.cartFlag,
        });
    }

    setBookingData(data) {
        var defaultReps = this.state.reps.find(
            val => val.value === data.assigned_rep,
        );
        var defaultAdditionalReps = [];
        data.additional_reps.map(repValue => {
            defaultAdditionalReps.push(
                this.state.additionalReps.find(val => val.value === repValue),
            );
        });

        const { updateBookingInfo } = this.state;
        updateBookingInfo["id"] = data["_id"];
        updateBookingInfo["operation_date_slot"] = data["operation_date_slot"];
        // updateBookingInfo["po_number"] = data["po_number"];
        updateBookingInfo["event_name"] = data["event_name"];
        updateBookingInfo["name_of_location"] = data["name_of_location"];
        updateBookingInfo["address"] = data["address"];
        updateBookingInfo["postcode"] = data["postcode"];
        updateBookingInfo["phone_number"] = data["phone_number"];
        updateBookingInfo["delivery_address"] = data["delivery_address"];
        updateBookingInfo["collection_address"] = data["collection_address"];
        updateBookingInfo["contact_detail"] = data["contact_detail"];
        updateBookingInfo["shipping_charge"] = data["shipping_charge"];
        updateBookingInfo["delivery_date_slot"] = data["delivery_date_slot"];
        updateBookingInfo["assigned_rep"] = data["assigned_rep"];

        updateBookingInfo["delivery_date"] = new Date(data["delivery_date"]);
        updateBookingInfo["operation_date"] = new Date(data["operation_date"]);
        updateBookingInfo["collection_date"] = new Date(
            data["collection_date"],
        );
        updateBookingInfo["additional_notes"] = data["additional_notes"];
        // updateBookingInfo["booking_type"] = data["booking_type"];
        updateBookingInfo["status"] = data["status"];
        updateBookingInfo["other_opration"] = data["other_opration"];
        // updateBookingInfo["po_check"] = data["po_check"];
        updateBookingInfo["pickup_location_checkbox"] = data["pickup_location_checkbox"];
        updateBookingInfo["pickup_location_text"] = data["pickup_location_text"];

        var tempAdditionalReps = [];
        data.additional_reps.map(item => {
            tempAdditionalReps.push({ id: item });
        });
        updateBookingInfo["additional_reps"] = tempAdditionalReps;


        this.setState(
            {
                updateBookingInfo,
                bookingInfo: data,
                selectedOperationSlots: {
                    value: data.operation_date_slot,
                    label: data.operation_date_slot,
                },
                selectedDeliverySlot: {
                    value: data.delivery_date_slot,
                    label: data.delivery_date_slot,
                },
                selectedRep: defaultReps,
                selectedAdditionalRep: defaultAdditionalReps,
                // selectedBookingType: [
                //     {
                //         value: data.booking_type,
                //         label: data.booking_type
                //     },
                // ],
            },

        );

        console.log("booking dfdsfdsfd fsdf ", data);

        data.bookingKitId.map(item => {
            this.props.dispatch(
                bookingActions.getKitSetByKit({ kitId: item.kitId }),
            );
        });
    }

    handlePickupCheckbox (value) {
        const { updateBookingInfo } = this.state;
        updateBookingInfo["pickup_location_checkbox"] = value;
        this.setState({
            updateBookingInfo,
            // changesMade: true,
        });
    }

    showRepsList(repsList) {
        var tempRepsList = [];
        repsList.map(item => {
            var option = {
                value: item._id,
                label: item.first_name + " " + item.last_name,
            };
            tempRepsList.push(option);
        });
        this.setState({
            repsDetails: repsList,
            reps: tempRepsList,
            additionalReps: tempRepsList,
        });
    }

    // setSelectedOptionBookingType(e) {
    //     var selectedBookingType = [{ value: e.value, label: e.label }];

    //     this.setState({
    //         selectedBookingType: selectedBookingType,
    //         updateBookingInfo: {
    //             ...this.state.updateBookingInfo,
    //             booking_type: e.value,
    //         },
    //     });
    // }

    setSelectedOptionRep(e) {
        var selectedRep = [{ value: e.value, label: e.label }];
        this.setState({
            selectedRep: selectedRep,
            updateBookingInfo: {
                ...this.state.updateBookingInfo,
                assigned_rep: e.value,
            },
        });
    }

    setSelectedOptionAdditionalRep = selectedAdditionalRep => {
        var tempAdditionalReps = [];
        selectedAdditionalRep.map(item => {
            tempAdditionalReps.push({ id: item.value });
        });
        this.setState(
            {
                selectedAdditionalRep: selectedAdditionalRep,
                updateBookingInfo: {
                    ...this.state.updateBookingInfo,
                    additional_reps: tempAdditionalReps,
                },
            },
            () => {
                console.log(this.state.updateBookingInfo.additional_reps);
            },
        );
    };

    setSelectedOptionKit(e) {
        var selectedKit = [{ value: e.value, label: e.label }];
        if (this.state.updateBookingInfo.status == "pending") {
            var kLength = this.state.kitWithKitSet.filter(
                k => k._id == e.value,
            ).length;
            if (kLength == 0) {
                this.setState({
                    addedKitFlag: true,
                });
            } else {
                this.setState({
                    addedKitFlag: false,
                });
            }
        }
        this.setState(
            {
                selectedKit: selectedKit,
                cartFlag: false,
            },
            () => {
                this.handleAddKit();
            },
        );
    }

    showKitsList(kitList) {
        var tempKitList = [];
        kitList.map(item => {
            var option = { value: item._id, label: item.name };
            tempKitList.push(option);
        });
        this.setState({
            kits: tempKitList,
            Allkits: tempKitList,
            kitListFlag: 1,
        });
    }

    handleAddKit() {
        if (this.state.selectedKit.length > 0) {
            this.setState({
                kitSetErrorFlag: 0,
            });
            this.props.dispatch(
                bookingActions.getKitSetByKit({
                    kitId: this.state.selectedKit[0].value,
                }),
            );
            this.setState({
                singleSelectedKitId: this.state.selectedKit[0].value,
            });
        }
    }

    async showKitsSetList(data) {
        const {
            kitWithKitSet,
            updateBookingInfo,
            bookingInfo,
            singleSelectedKitId,
        } = this.state;
        var subKitSet = [];
        var lnt = 0;
        var hire_charge = 695;

        data[0]["kitsets"].map(kset => {
            lnt = data[0].bookingKitSets.filter(
                rs =>
                    rs.setId === kset._id &&
                    rs.bookingId == updateBookingInfo.id,
            ).length;
            if (lnt > 0) {

                data[0].bookingKitSets
                    .filter(
                        rs =>
                            rs.setId === kset._id &&
                            rs.bookingId == updateBookingInfo.id,
                    )
                    .map(result => {
                        subKitSet.push({
                            bookingKitSets: data[0]["bookingKitSets"],
                            set_name: kset.set_name,
                            _id: kset._id,
                            hire_charge: result.hire_charge,
                            added: lnt,
                            setDoc_urlpath:kset?.setDoc_urlpath,
                            setDocument:kset?.setDocuments
                        });

                    });
            }
            else {
                subKitSet.push({
                    bookingKitSets: data[0]["bookingKitSets"],
                    set_name: kset.set_name,
                    _id: kset._id,
                    hire_charge: hire_charge,
                    added: lnt,
                    setDoc_urlpath:kset?.setDoc_urlpath,
                    setDocument:kset?.setDocuments
                });
            }



        });

        var kitquantity = "";
        var kithire_charge = "";

        bookingInfo.bookingKitId
            .filter(item => item.kitId == data[0]._id)
            .map(maini => {
                kithire_charge = maini.hire_charge;
                kitquantity = maini.quantity;
            });

        if (kitWithKitSet.filter(item => item._id == data[0]._id) == 0) {
            var cartAdded = 0;
            if (subKitSet.length) {
                cartAdded = subKitSet.filter(item => item.added == 1).length;
            }
            kitWithKitSet.push({
                kitDocument: data[0].kitDocument,
                cartAdded: cartAdded,
                name: data[0].name,
                _id: data[0]._id,
                hire_charge: kithire_charge,
                quantity: kitquantity,
                sets: subKitSet,
            });
        }
        else {
            kitWithKitSet.map(function (kits) {
                if (kits._id == data[0]._id) {
                    kits.sets.map(function (subSets) {
                        if (subSets.added == 0) {
                            subSets.hire_charge = hire_charge;
                        }
                    });
                }
            });
        }

        if (this.state.updateBookingInfo.status == "pending" &&
            this.state.kitWithKitSet.filter(item => (item.quantity > 0 || item.hire_charge > 0)
                && item._id == singleSelectedKitId).length > 0) {
            kitWithKitSet.filter((ft) => ft._id == singleSelectedKitId).map((itm) => {
                itm.sets.filter((sft) => sft.added == 0).map((st) => {
                    st.hire_charge = itm.hire_charge;
                });

            });
        }

        var kits = this.state.Allkits;
        kits = kits.filter(rs => rs.value !== singleSelectedKitId);


        this.setState(
            {
                kitWithKitSet,
                selectedKit: [],
                kits: kits,
            },
            () => {
                this.calculateItemCount();
            },
        );
    }

    handelRemovKitFromCart(kiIndex, kit_id) {
        const { kitWithKitSet } = this.state;
        kitWithKitSet.map(item => {
            if (item._id == kit_id) {
                item.sets.map(subItem => {
                    subItem.added = 0;
                });
                item.cartAdded = 0;
            }
        });

        this.setState(
            {
                kitWithKitSet,
                cartFlag: !this.state.cartFlag,
            },
            () => {
                this.calculateItemCount();
            },
        );
    }

    calculateItemCount() {
        const { kitWithKitSet } = this.state;
        let cartItemCount = 0;

        cartItemCount = kitWithKitSet
            .map(datum => datum.cartAdded)
            .reduce((a, b) => a + b);
        this.setState({
            cartItemCount: cartItemCount,
        });
    }

    handelRemovKit(kiIndex, kit_id) {
        console.log(kiIndex, kit_id);

        this.setState({
            singleSelectedKitId: 0,
            kits: this.state.Allkits,
            addedKitFlag: false,
        });
    }

    handelAddRemovSet(kitId, setId, flag) {
        const { kitWithKitSet } = this.state;
        kitWithKitSet.map(item => {
            if (item._id == kitId) {
                item.sets.map(subItem => {
                    if (subItem._id == setId) {
                        subItem.added = flag;
                    }
                });
                if (item.sets.filter(rs => rs.added === 1).length) {
                    item.cartAdded = item.sets.filter(
                        rs => rs.added === 1,
                    ).length;
                } else {
                    item.cartAdded = 0;
                }
            }
        });
        this.setState(
            {
                kitWithKitSet,
                kitSetErrorFlag: 0,
            },
            () => {
                this.calculateItemCount();
            },
        );
    }

    handleHireCharge(kitId, setId, val) {
        const { kitWithKitSet } = this.state;
        if (val != "" && val < 0) {
            val = "";
        }

        kitWithKitSet.map(item => {
            if (item._id == kitId) {
                item.sets.map(subItem => {
                    if (subItem._id == setId) {
                        subItem.hire_charge = val;
                    }
                });
            }
        });

        this.setState({
            kitWithKitSet,
        });
    }

    handleUpdateBookingInfo(name, value) {
        const { updateBookingInfo } = this.state;
        if (
            name == "delivery_date" ||
            name == "collection_date" ||
            name == "operation_date"
        ) {
            let date = dateFormat(value, "yyyy-mm-dd");
            bookingService
                .checkHolidayDate({ date: date })
                .then(response => {
                    console.log("checkholiday", response);
                    if (response.data && response.data.length !== 0) {
                        if (name === "delivery_date") {
                            this.setState(prevState => ({
                                deliveryDateError:
                                    "Delivery Date falls on a holiday.",
                                operationDateError:
                                    prevState.operationDateError,
                                collectionDateError:
                                    prevState.collectionDateError,
                            }));
                        } else if (name === "collection_date") {
                            this.setState(prevState => ({
                                deliveryDateError: prevState.deliveryDateError,
                                collectionDateError:
                                    "Collection Date falls on a holiday.",
                                operationDateError:
                                    prevState.operationDateError,
                            }));
                        } else if (name === "operation_date") {
                            this.setState(prevState => ({
                                deliveryDateError: prevState.deliveryDateError,
                                collectionDateError:
                                    prevState.collectionDateError,
                                operationDateError:
                                    "Meeting Date falls on a holiday.",
                            }));
                        }
                    } else {
                        this.setState(prevState => ({
                            deliveryDateError:
                                name === "delivery_date"
                                    ? ""
                                    : prevState.deliveryDateError,
                            operationDateError:
                                name === "operation_date"
                                    ? ""
                                    : prevState.operationDateError,
                            collectionDateError:
                                name === "collection_date"
                                    ? ""
                                    : prevState.collectionDateError,
                        }));
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }

        if (name == "shipping_charge" && value != "" && value < 0) {
            value = "";
        }
        updateBookingInfo[name] = value;
        // if (name == "po_check" && value == true) {
        //     updateBookingInfo["po_number"] = "";
        // }
        // if (name == "po_number" && value == "") {
        //     updateBookingInfo["po_check"] = true;
        // }
        // else if (name == "po_number" && value != "") {
        //     updateBookingInfo["po_check"] = false;
        // }

        // if (updateBookingInfo["pickup_location_checkbox"] == false) {
        //     updateBookingInfo["pickup_location_text"] = "";
        // }

        this.setState(
            {
                updateBookingInfo,
            },
            () => {
                console.log(this.state.updateBookingInfo);
            },
        );
    }

    setSelectedOptionDeliverySlot(e) {
        var selectedDeliverySlot = [{ value: e.value, label: e.label }];
        this.setState({
            selectedDeliverySlot: selectedDeliverySlot,
            updateBookingInfo: {
                ...this.state.updateBookingInfo,
                delivery_date_slot: e.value,
            },
        });
    }

    setSelectedOptionOperationSlots(e) {
        var selectedOperationSlots = [{ value: e.value, label: e.label }];
        this.setState({
            selectedOperationSlots: selectedOperationSlots,
            updateBookingInfo: {
                ...this.state.updateBookingInfo,
                operation_date_slot: e.value,
            },
        });
    }

    sendEmailConfirmHandle() {
        const { updateBookingInfo } = this.state;
        updateBookingInfo["mail_sent"] = true;
        this.setState(
            {
                updateBookingInfo,
            },
            () => {
                this.handleSubmit();
            },
        );
    }

    saveChangeHandle() {
        const { updateBookingInfo } = this.state;
        updateBookingInfo["mail_sent"] = false;
        this.setState(
            {
                updateBookingInfo,
            },
            () => {
                this.handleSubmit();
            },
        );
    }

    handleSubmit() {
        const { updateBookingInfo, kitWithKitSet } = this.state;
        var kitError = 0;
        var kitData = [];
        var kitSetData = [];
        let kitSetFlag = 0;
        var kitSetErrorFlag = 0;

        kitWithKitSet.map(item => {
            item.sets
                .filter(rs => rs.added === 1)
                .map(sub => {
                    kitSetFlag = 1;
                    kitError = 1;
                    kitSetData.push({
                        id: sub._id,
                        hire_charge: sub.hire_charge,
                        kitId: item._id,
                    });
                });
            if (item.sets.filter(rs => rs.added === 1).length) {
                kitData.push({
                    id: item._id,
                    hire_charge: item.hire_charge,
                    quantity: item.quantity,
                });
            }
        });

        updateBookingInfo["return_date"] = "";

        if (updateBookingInfo["collection_date"] != "") {
            var return_date = new Date(updateBookingInfo["collection_date"]);
            return_date = return_date.setDate(return_date.getDate() + 1);
            updateBookingInfo["return_date"] = dateFormat(
                return_date,
                "yyyy-mm-dd",
            );
        }

        if (updateBookingInfo["booking_type"] == "consignment") {
            updateBookingInfo["operation_date"] =
                updateBookingInfo["collection_date"];
        }

        updateBookingInfo["kitData"] = kitData;
        updateBookingInfo["kitSetData"] = kitSetData;

        console.log(kitSetFlag);


        if( updateBookingInfo["pickup_location_checkbox"] == false){
            updateBookingInfo["pickup_location_text"] = "";
         }


        if (
            // updateBookingInfo["po_number"] != "" &&
            updateBookingInfo["event_name"] != "" &&
            updateBookingInfo["name_of_location"] != "" &&
            updateBookingInfo["address"] != "" &&
            updateBookingInfo["postcode"] != "" &&
            updateBookingInfo["phone_number"] != "" &&
            updateBookingInfo["delivery_address"] != "" &&
            updateBookingInfo["collection_address"] != "" &&
            updateBookingInfo["contact_detail"] != "" &&
            updateBookingInfo["delivery_date"] != "" &&
            updateBookingInfo["operation_date"] != "" &&
            updateBookingInfo["collection_date"] != "" &&
            updateBookingInfo["kitData"].length != 0 &&
            updateBookingInfo["kitSetData"].length != 0
        ) {
            kitSetErrorFlag = 1;
            this.setState({
                kitSetErrorFlag: 0,
            });
        } else {
            kitSetErrorFlag = 0;
            if (kitError == 1) {
                kitSetErrorFlag = 1;
                this.setState({
                    kitSetErrorFlag: 0,
                });
            } else {
                this.setState({
                    kitSetErrorFlag: 1,
                });
            }
        }

        updateBookingInfo["log_status"] = "updated";

        if (updateBookingInfo["status"] == "pending") {
            updateBookingInfo["reject_status"] = "booked";
            updateBookingInfo["log_status"] = "booked";
        } else {
            updateBookingInfo["reject_status"] = "";
        }

        updateBookingInfo["log_by_id"] = this.state.user.data._id;

        console.log(kitSetErrorFlag);
        if (kitSetErrorFlag == 1) {
            var datass = {
                additional_notes: updateBookingInfo["additional_notes"],
                additional_reps: updateBookingInfo["additional_reps"],
                assigned_rep: updateBookingInfo["assigned_rep"],
                booking_type: updateBookingInfo["booking_type"],
                collection_date: dateFormat(
                    updateBookingInfo["collection_date"],
                    "yyyy-mm-dd",
                ),
                delivery_date: dateFormat(
                    updateBookingInfo["delivery_date"],
                    "yyyy-mm-dd",
                ),
                delivery_date_slot: updateBookingInfo["delivery_date_slot"],
                id: updateBookingInfo["id"],
                kitData: updateBookingInfo["kitData"],
                kitSetData: updateBookingInfo["kitSetData"],
                log_by_id: updateBookingInfo["log_by_id"],
                log_status: updateBookingInfo["log_status"],
                operation_date: dateFormat(
                    updateBookingInfo["operation_date"],
                    "yyyy-mm-dd",
                ),
                operation_date_slot: updateBookingInfo["operation_date_slot"],
                other_opration: updateBookingInfo["other_opration"],
                // po_number: updateBookingInfo["po_number"],
                event_name: updateBookingInfo["event_name"],
                name_of_location: updateBookingInfo["name_of_location"],
                address: updateBookingInfo["address"],
                postcode: updateBookingInfo["postcode"],
                phone_number: updateBookingInfo["phone_number"],
                delivery_address: updateBookingInfo["delivery_address"],
                collection_address: updateBookingInfo["collection_address"],
                contact_detail: updateBookingInfo["contact_detail"],
                reject_status: updateBookingInfo["reject_status"],
                return_date: updateBookingInfo["return_date"],
                shipping_charge: updateBookingInfo["shipping_charge"],
                status: updateBookingInfo["status"],
                mail_sent: updateBookingInfo["mail_sent"],
                pickup_location_checkbox: updateBookingInfo["pickup_location_checkbox"],
                pickup_location_text: updateBookingInfo["pickup_location_text"],
                // po_check: updateBookingInfo["po_check"],
            };

console.log("dataaaaa", datass);
            this.props.dispatch(bookingActions.update(datass));
        }
    }

    kitSetDetailModalClose() {
        this.setState({
            kitSetDetailModal: false,
            kitSetData: [],
        });
    }

    showKitDetails(kitDetails) {
        if (this.state.kitDetailsClickFlag == 1) {
            this.setState({
                kitDetails: kitDetails,
                kitSetDetailModal: true,
                kitDetailsClickFlag: 0,
            });
        }
    }

    handleKitDetails(id) {
        this.setState({
            kitDetailsClickFlag: 1,
        });

        this.props.dispatch(setActions.getById(id));
    }

    // capitalize(str) {
    //     return str.charAt(0).toUpperCase() + str.slice(1);
    // }

    checkAvalability(
        bookingKitSets,
        setd,
        setId,
        index,
        ind,
        kitId,
        showItems,
    ) {
        const { updateBookingInfo } = this.state;
        var htm = [];

        var currentDate = updateBookingInfo.delivery_date;
        var delivery_date = updateBookingInfo.delivery_date;
        var endDate = updateBookingInfo.delivery_date;
        var falgForHtm = 0;
        var date = "";
        var separator = "-";
        var fulldate = "";
        var nextDate = [];
        var holiday = "No";
        var isConsinment = 0;

        if (currentDate != "" && endDate != "") {
            currentDate = new Date(currentDate);
            endDate = new Date(endDate);
            var dates = [];
            while (currentDate <= endDate) {
                date = `${currentDate.getFullYear()}${separator}${currentDate.getMonth() + 1 < 10
                    ? `0${currentDate.getMonth() + 1}`
                    : `${currentDate.getMonth() + 1}`
                    }${separator}${currentDate.getDate() < 10
                        ? `0${currentDate.getDate()}`
                        : `${currentDate.getDate()}`
                    }`;

                dates.push({ fulldate: date });
                currentDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 1, // Will increase month if over range
                );
            }

            if (bookingKitSets.length == 0) {
                htm.push(<span className="capsule green-dim">Available</span>);
                currentDate = new Date(delivery_date);
                endDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 6,
                );

                while (currentDate <= endDate) {
                    holiday = "No";
                    if (
                        currentDate.getDay() == 6 ||
                        currentDate.getDay() == 0
                    ) {
                        holiday = "holiday";
                    }

                    nextDate.push({
                        date:
                            currentDate.getDate() +
                            " " +
                            dayName[currentDate.getDay()],
                        holiday: holiday,
                        status: "",
                    });

                    currentDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() + 1, // Will increase month if over range
                    );
                }
            } else {
                dates &&
                    dates.map(diffdate => {
                        fulldate = diffdate.fulldate;
                        bookingKitSets
                            .filter(rs => rs.setId == setId)
                            .map(bookingSt => {
                                if (
                                    bookingSt.bookings &&
                                    bookingSt.bookings.filter(
                                        rs =>
                                            rs._id != updateBookingInfo.id &&
                                            rs.delivery_date <= fulldate &&
                                            rs.collection_date >= fulldate,
                                    ).length
                                ) {
                                    falgForHtm += 1;
                                }

                                if (
                                    bookingSt.bookings &&
                                    bookingSt.bookings.filter(
                                        rs =>
                                            rs.booking_type != "loan" &&
                                            rs._id != updateBookingInfo.id &&
                                            rs.delivery_date <= fulldate &&
                                            rs.collection_date >= fulldate,
                                    ).length
                                ) {
                                    isConsinment = 1;
                                }
                            });
                    });

                if (falgForHtm > 0) {
                    htm.push(
                        <span className="capsule red-dim">Not Available</span>,
                    );
                }

                if (falgForHtm == 0) {
                    htm.push(
                        <span className="capsule green-dim">Available</span>,
                    );
                }

                currentDate = new Date(delivery_date);

                endDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 6,
                );

                while (currentDate <= endDate) {
                    holiday = "No";
                    falgForHtm = 0;
                    if (
                        currentDate.getDay() == 6 ||
                        currentDate.getDay() == 0
                    ) {
                        holiday = "holiday";
                    }

                    fulldate = `${currentDate.getFullYear()}${separator}${currentDate.getMonth() + 1 < 10
                        ? `0${currentDate.getMonth() + 1}`
                        : `${currentDate.getMonth() + 1}`
                        }${separator}${currentDate.getDate() < 10
                            ? `0${currentDate.getDate()}`
                            : `${currentDate.getDate()}`
                        }`;

                    bookingKitSets
                        .filter(rs => rs.setId == setId)
                        .map(bookingSt => {
                            if (
                                bookingSt.bookings &&
                                bookingSt.bookings.filter(
                                    rs =>
                                        rs.delivery_date <= fulldate &&
                                        rs.collection_date >= fulldate,
                                ).length
                            ) {
                                falgForHtm += 1;
                            }
                        });

                    if (falgForHtm > 0) {
                        nextDate.push({
                            date:
                                currentDate.getDate() +
                                " " +
                                dayName[currentDate.getDay()],
                            holiday: "na",
                            status: " Not Available",
                        });
                    }

                    if (falgForHtm == 0) {
                        nextDate.push({
                            date:
                                currentDate.getDate() +
                                " " +
                                dayName[currentDate.getDay()],
                            holiday: holiday,
                            status: "Available",
                        });
                    }
                    currentDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() + 1, // Will increase month if over range
                    );
                }
            }
        } else {
            htm.push(<span className="capsule green-dim">Available</span>);
        }

        if (showItems == 2) {
            showItems = setd.added;
        }

        if (isConsinment == 0 && setd.added == showItems) {
            return (
                <React.Fragment>
                    <td>
                        <div className="mb-2">
                            <span>{setd.set_name}</span>
                        </div>
                        {htm}

                        <span className="mr-4 kitname">
                            {setd?.setDoc_urlpath && (
                                    <span className="ml-1">
                                        <a
                                            id={
                                                "UncontrolledTooltipPdf1" +
                                                index
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            className="download-info badge1 badge-info1"
                                            href={
                                                process
                                                    .env
                                                    .REACT_APP_APIURL + setd?.setDoc_urlpath
                                            }>
                                            Download Set Pdf
                                        </a>

                                    </span>
                                )}
                        </span>
                        
                        <div key="2">
                            <div
                                className="table-chart"
                                style={{
                                    width: "auto",
                                    display: "inline-block",
                                }}>
                                <table className="mt-2 main-table table-sm">
                                    <thead>
                                        <tr
                                            style={{
                                                borderTop: "1px solid #DDD",
                                            }}>
                                            {nextDate &&
                                                nextDate.map((next, indxx) => (
                                                    <th
                                                        key={indxx}
                                                        in={indxx}
                                                        className={
                                                            (indxx == 0
                                                                ? " first_col "
                                                                : " ") +
                                                            next.holiday
                                                        }
                                                        style={{
                                                            width: "30px",
                                                        }}>
                                                        {next.date}
                                                    </th>
                                                ))}
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </td>
                    <td style={{ width: "120px", "vertical-align": "bottom" }}>
                        {/* <label>Hire Charges (Per Set)</label>
                        <Input
                            type="number"
                            min="0"
                            className="form-control"
                            value={setd.hire_charge}
                            onChange={e =>
                                this.handleHireCharge(
                                    kitId,
                                    setId,
                                    e.target.value,
                                )
                            }
                            name=""
                            placeholder="£"
                        /> */}
                    </td>

                    <td className="text-right align-bottom" width="120">
                        {setd.added == 0 && (
                            <a
                                href="javascript:;"
                                onClick={() =>
                                    this.handelAddRemovSet(kitId, setId, 1)
                                }
                                className="btn btn-primary addnow">
                                <i className="fa fa-plus"></i> Add
                            </a>
                        )}

                        {setd.added == 1 && (
                            <div
                                id="added"
                                className="mb-3 position-relative"
                                style={{ width: "110px" }}>
                                <span className="addmsg">
                                    <i className="fa fa-check"></i> Added{" "}
                                </span>
                                <a
                                    href="javascript:"
                                    onClick={() =>
                                        this.handelAddRemovSet(kitId, setId, 0)
                                    }
                                    className="ml-1">
                                    <i className="fa fa-trash"></i>
                                </a>
                            </div>
                        )}
                    </td>
                </React.Fragment>
            );
        }
    }

    checkAvalabilityForConnsignment(kit) {
        const { updateBookingInfo } = this.state;

        var currentDate = updateBookingInfo.delivery_date;
        var endDate = updateBookingInfo.collection_date;
        var date = "";
        var dates = [];
        var separator = "-";
        var fulldate = "";
        var isConsinment = 0;
        var totalConsinment = 0;

        kit.sets.map(setd => {

            currentDate = updateBookingInfo.delivery_date;
            endDate = updateBookingInfo.collection_date;
            date = "";
            dates = [];
            separator = "-";
            fulldate = "";
            isConsinment = 0;

            if (currentDate != "" && endDate != "") {
                currentDate = new Date(currentDate);
                endDate = new Date(endDate);

                while (currentDate <= endDate) {
                    date = `${currentDate.getFullYear()}${separator}${currentDate.getMonth() + 1 < 10
                        ? `0${currentDate.getMonth() + 1}`
                        : `${currentDate.getMonth() + 1}`
                        }${separator}${currentDate.getDate() < 10
                            ? `0${currentDate.getDate()}`
                            : `${currentDate.getDate()}`
                        }`;

                    dates.push({ fulldate: date });
                    currentDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() + 1, // Will increase month if over range
                    );
                }
                // console.log(setd.setId,"ln");
                if (setd.bookingKitSets.length > 0) {
                    dates &&
                        dates.map(diffdate => {
                            fulldate = diffdate.fulldate;

                            setd.bookingKitSets &&
                                setd.bookingKitSets
                                    .filter(rs => rs.setId == setd._id)
                                    .map(bookingSt => {
                                        if (
                                            bookingSt.bookings &&
                                            bookingSt.bookings.filter(
                                                rs =>
                                                    rs.booking_type != "loan" &&
                                                    rs._id !=
                                                    updateBookingInfo.id &&
                                                    rs.delivery_date <=
                                                    fulldate &&
                                                    rs.collection_date >=
                                                    fulldate,
                                            ).length
                                        ) {
                                            isConsinment = 1;
                                        }
                                    });
                        });
                    totalConsinment += isConsinment;
                }
            }
        });

        if (totalConsinment == kit.sets.length && kit.sets.length > 0) {
            return (
                <React.Fragment>
                    <tr>
                        <td colSpan="3" className=" red-dim">
                            Consignment already booked for this duration
                        </td>
                    </tr>
                </React.Fragment>
            );
        }
    }

    componentDidMount() {
        this.getList();
        const timer = setTimeout(() => {
            this.getSingleBooking(this.props.match.params.id);
        }, 1500);
        return () => clearTimeout(timer);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);

        if (nextProps.bookingInfo !== undefined) {
            this.setBookingData(nextProps.bookingInfo);
        }

        if (nextProps.repsList !== undefined) {
            this.showRepsList(nextProps.repsList);
        }

        if (
            nextProps.kitListDropdown !== undefined &&
            this.state.kitListFlag == 0
        ) {
            this.showKitsList(nextProps.kitListDropdown);
        }

        if (nextProps.kitSetList !== undefined) {
            this.showKitsSetList(nextProps.kitSetList);
        }

        if (nextProps.editSet == true) {
            this.showKitDetails(nextProps.set);
        }
    }

    render() {
        const { redirect, error, loading } = this.props;
        if (
            redirect &&
            this.state.updateBookingInfo.booking_type != "consignment"
        ) {
            return <Redirect to="/admin/bookings" />;
        }
        if (
            redirect &&
            this.state.updateBookingInfo.booking_type == "consignment"
        ) {
            return <Redirect to="/admin/bookings/consignment" />;
        }
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <Navbar activePage="bookings" />
                <div className="container">
                    <h1 className="date-scroll pt-4">
                        <Link to="/admin/bookings">
                            <i className="fa fa-long-arrow-left smbtn"></i>{" "}
                            <strong>Back</strong>
                        </Link>
                    </h1>
                    <div className="row pt-4">
                        <div className="col-lg-8">
                            <div className="d-card">
                                <h4>Basic Details</h4>
                                <div className="form-group">
                                    <label>Event Name</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={
                                            this.state.updateBookingInfo
                                                .event_name
                                        }

                                        onChange={e =>
                                            this.handleUpdateBookingInfo(
                                                "event_name",
                                                e.target.value,
                                            )
                                        }
                                    />
                                    {error ? (
                                        <span className="text-danger">
                                            {error.event_name
                                                ? error.event_name
                                                : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {/* <div className="form-group">
                                    <label>Event Address</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={
                                            this.state.updateBookingInfo
                                                .event_address
                                        }

                                        onChange={e =>
                                            this.handleUpdateBookingInfo(
                                                "event_address",
                                                e.target.value,
                                            )
                                        }
                                    />
                                    {error ? (
                                        <span className="text-danger">
                                            {error.event_address
                                                ? error.event_address
                                                : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div> */}

                                <label>Event Address</label>
                                <div className="row purchase_date">
                                    <div
                                        className={
                                            this.state.bookingInfo
                                                .booking_type != "consignment"
                                                ? "col-md-6"
                                                : "col-md-12"
                                        }>
                                        <div className="form-group">
                                            <label className="opaq">
                                                Name of Location{" "}
                                                {/* <span className="text-danger">
                                                    *
                                                </span> */}
                                            </label>
                                            {/* <div className="inputdata">
                                                <DatePicker
                                                    selected={
                                                        this.state.bookingInfo
                                                            .delivery_date
                                                    }
                                                    onChange={date =>
                                                        this.handleBookingInfo(
                                                            "delivery_date",
                                                            date,
                                                        )
                                                    }
                                                    dateFormat="dd-MM-yyyy EEE"
                                                    className="small"
                                                    id="datepicker"
                                                    autoComplete="off"
                                                />
                                            </div> */}
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={this.state.updateBookingInfo.name_of_location}

                                                onChange={e =>
                                                    this.handleUpdateBookingInfo(
                                                        "name_of_location",
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            {error ? (
                                                <span className="text-danger">
                                                    {error.name_of_location
                                                        ? error.name_of_location
                                                        : ""}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            {/* {this.state.bookingInfo.delivery_date!="" &&  ((new Date(this.state.bookingInfo.delivery_date).getDate() < new Date().getDate()) || ((new Date(this.state.bookingInfo.delivery_date).getMonth()+1) < (new Date().getMonth()+1)) || (new Date(this.state.bookingInfo.delivery_date).getFullYear() < new Date().getFullYear())) && 
                                                <div><span className="text-danger">The dispatch date you booked is in the past</span></div>
                                            } */}
                                        </div>
                                    </div>
                                    {this.state.bookingInfo.booking_type !=
                                        "consignment" && (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="opaq">
                                                        Address{" "}
                                                        {/* <span className="text-danger">
                                                            *
                                                        </span> */}
                                                    </label>
                                                    {/* <div className="mt-2 position_up">
                                                        <Select
                                                            defaultValue={
                                                                this.state
                                                                    .selectedDeliverySlot
                                                            }
                                                            onChange={
                                                                this
                                                                    .setSelectedOptionDeliverySlot
                                                            }
                                                            options={deliverySlots}
                                                            className="small"
                                                        />
                                                    </div> */}
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.updateBookingInfo.address}

                                                        onChange={e =>
                                                            this.handleUpdateBookingInfo(
                                                                "address",
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                    {this.state.bookingInfo.booking_type !=
                                        "consignment" && (
                                            <React.Fragment>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="opaq">
                                                            Postcode{" "}
                                                            {/* <span className="text-danger">
                                                                *
                                                            </span> */}
                                                        </label>
                                                        {/* <DatePicker
                                                            selected={
                                                                this.state
                                                                    .bookingInfo
                                                                    .operation_date
                                                            }
                                                            onChange={date =>
                                                                this.handleBookingInfo(
                                                                    "operation_date",
                                                                    date,
                                                                )
                                                            }
                                                            dateFormat="dd-MM-yyyy EEE"
                                                            id="datepicker2"
                                                            className="small"
                                                            autoComplete="off"
                                                        /> */}
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.updateBookingInfo.postcode}

                                                            onChange={e =>
                                                                this.handleUpdateBookingInfo(
                                                                    "postcode",
                                                                    e.target.value,
                                                                )
                                                            }
                                                        />
                                                        {this.state
                                                            .operationDateError && (
                                                                <div className="error-message">
                                                                    <span className="text-danger">
                                                                        {
                                                                            this.state
                                                                                .operationDateError
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )}
                                                        {error ? (
                                                            <span className="text-danger">
                                                                {error.operation_date
                                                                    ? error.operation_date
                                                                    : ""}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group" >
                                                        <label className="opaq">
                                                            Phone Number{" "}
                                                            {/* <span className="text-danger">
                                                                *
                                                            </span> */}
                                                        </label>
                                                        {/* <div className="mt-2">
                                                            <Select
                                                                defaultValue={
                                                                    this.state
                                                                        .selectedOperationSlots
                                                                }
                                                                onChange={
                                                                    this
                                                                        .setSelectedOptionOperationSlots
                                                                }
                                                                options={
                                                                    operationSlots
                                                                }
                                                                className="small"
                                                            />
                                                        </div> */}
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.updateBookingInfo.phone_number}

                                                            onChange={e =>
                                                                this.handleUpdateBookingInfo(
                                                                    "phone_number",
                                                                    e.target.value,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                        <div className="form-group mt-3">
                                            <span className="">
                                                <Input
                                                    type="checkbox"
                                                    className="ml-0 form-check-input"
                                                    value={
                                                        this.state.updateBookingInfo.pickup_location_checkbox
                                                    }
                                                    checked={
                                                        this.state.updateBookingInfo.pickup_location_checkbox
                                                    }
                                                    onChange={e =>
                                                        this.handlePickupCheckbox(
                                                             e.target.checked,
                                                        )
                                                    }
                                                />
                                            </span>
                                            <label className="ml-4 ">
                                            Is Pickup Location And Contact Details Different
                                            </label>
                                            {this.state.updateBookingInfo.pickup_location_checkbox?
                                            <Input
                                            type="text"
                                            className="form-control"
                                            value={
                                            this.state.updateBookingInfo.pickup_location_text
                                        }
                                           onChange={e =>
                                            this.handleUpdateBookingInfo( "pickup_location_text",
                                            e.target.value,
                                            )
                                        }
                                        />
                                        :""
                                            }
                                </div>
</div>
                                            </React.Fragment>
                                        )}
                                </div>
                                <div className="form-group">
                                    <label>Delivery Address</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={
                                            this.state.updateBookingInfo
                                                .delivery_address
                                        }

                                        onChange={e =>
                                            this.handleUpdateBookingInfo(
                                                "delivery_address",
                                                e.target.value,
                                            )
                                        }
                                    />
                                    {error ? (
                                        <span className="text-danger">
                                            {error.delivery_address
                                                ? error.delivery_address
                                                : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Collection Address</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={
                                            this.state.updateBookingInfo
                                                .collection_address
                                        }

                                        onChange={e =>
                                            this.handleUpdateBookingInfo(
                                                "collection_address",
                                                e.target.value,
                                            )
                                        }
                                    />
                                    {error ? (
                                        <span className="text-danger">
                                            {error.collection_address
                                                ? error.collection_address
                                                : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Contact Detail</label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={
                                            this.state.updateBookingInfo
                                                .contact_detail
                                        }

                                        onChange={e =>
                                            this.handleUpdateBookingInfo(
                                                "contact_detail",
                                                e.target.value,
                                            )
                                        }
                                    />
                                    {error ? (
                                        <span className="text-danger">
                                            {error.contact_detail
                                                ? error.contact_detail
                                                : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="d-card mt-4">
                                <h4>Meeting Delivery/Collection</h4>
                                <div className="row purchase_date">
                                    <div
                                        className={
                                            this.state.updateBookingInfo
                                                .booking_type != "consignment"
                                                ? "col-md-6"
                                                : "col-md-12"
                                        }>
                                        <div className="form-group">
                                            <label>
                                                Delivery Date{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <DatePicker
                                                selected={
                                                    this.state.updateBookingInfo
                                                        .delivery_date
                                                }
                                                onChange={date =>
                                                    this.handleUpdateBookingInfo(
                                                        "delivery_date",
                                                        date,
                                                    )
                                                }
                                                dateFormat="dd-MM-yyyy EEE"
                                                className="small"
                                                id="datepicker"
                                                autoComplete="off"
                                            />
                                            {this.state.deliveryDateError && (
                                                <div className="error-message">
                                                    <span className="text-danger">
                                                        {
                                                            this.state
                                                                .deliveryDateError
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {error ? (
                                                <div>
                                                    <span className="text-danger">
                                                        {error.delivery_date
                                                            ? error.delivery_date
                                                            : ""}
                                                    </span>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.updateBookingInfo
                                                .delivery_date != "" &&
                                                (new Date(
                                                    this.state.updateBookingInfo.delivery_date,
                                                ).getDay() == 6 ||
                                                    new Date(
                                                        this.state.updateBookingInfo.delivery_date,
                                                    ).getDay() == 0) && (
                                                    <div>
                                                        <span className="text-danger">
                                                            Selected date is a
                                                            weekend/holiday but
                                                            you can still make a
                                                            booking
                                                        </span>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    {this.state.updateBookingInfo
                                        .booking_type != "consignment" && (
                                                    <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label></label>
                                                        <div className="mt-2 position_up" style={{ zIndex: 5 }}>
                                                        <Select
                                                            value={
                                                                this.state
                                                                    .selectedDeliverySlot
                                                            }
                                                            onChange={
                                                                this
                                                                    .setSelectedOptionDeliverySlot
                                                            }
                                                            options={deliverySlots}
                                                            className="small"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    {this.state.updateBookingInfo
                                        .booking_type != "consignment" && (
                                            <React.Fragment>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            Meeting Date{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <DatePicker
                                                            selected={
                                                                this.state
                                                                    .updateBookingInfo
                                                                    .operation_date
                                                            }
                                                            onChange={date =>
                                                                this.handleUpdateBookingInfo(
                                                                    "operation_date",
                                                                    date,
                                                                )
                                                            }
                                                            dateFormat="dd-MM-yyyy EEE"
                                                            className="small"
                                                            id="datepicker2"
                                                            autoComplete="off"
                                                        />
                                                        {this.state
                                                            .operationDateError && (
                                                                <div className="error-message">
                                                                    <span className="text-danger">
                                                                        {
                                                                            this.state
                                                                                .operationDateError
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )}
                                                        {error ? (
                                                            <span className="text-danger">
                                                                {error.operation_date
                                                                    ? error.operation_date
                                                                    : ""}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label></label>
                                                        <div className="mt-2 position_up">
                                                            <Select
                                                                value={
                                                                    this.state
                                                                        .selectedOperationSlots
                                                                }
                                                                onChange={
                                                                    this
                                                                        .setSelectedOptionOperationSlots
                                                                }
                                                                options={
                                                                    operationSlots
                                                                }
                                                                className="small"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Collection Date</label>
                                            <DatePicker
                                                selected={
                                                    this.state.updateBookingInfo
                                                        .collection_date
                                                }
                                                onChange={date =>
                                                    this.handleUpdateBookingInfo(
                                                        "collection_date",
                                                        date,
                                                    )
                                                }
                                                dateFormat="dd-MM-yyyy EEE"
                                                className="small"
                                                id="datepicker3"
                                                autoComplete="off"
                                            />
                                            {this.state.collectionDateError && (
                                                <div className="error-message">
                                                    <span className="text-danger">
                                                        {
                                                            this.state
                                                                .collectionDateError
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {error ? (
                                                <div>
                                                    <span className="text-danger">
                                                        {error.collection_date
                                                            ? error.collection_date
                                                            : ""}
                                                    </span>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.updateBookingInfo
                                                .collection_date != "" &&
                                                (new Date(
                                                    this.state.updateBookingInfo.collection_date,
                                                ).getDay() == 6 ||
                                                    new Date(
                                                        this.state.updateBookingInfo.collection_date,
                                                    ).getDay() == 0) && (
                                                    <div>
                                                        <span className="text-danger mt-2">
                                                            Selected date is a
                                                            weekend/holiday but
                                                            you can still make a
                                                            booking
                                                        </span>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <table className="table">
                                        <tbody>
                                            {this.state.updateBookingInfo
                                                .status == "pending" && (
                                                    <tr>
                                                        <th
                                                            colSpan="2"
                                                            className="text-center">
                                                            Kit Selected By Reps
                                                        </th>
                                                    </tr>
                                                )}
                                            {this.state.kitWithKitSet &&
                                                this.state.updateBookingInfo
                                                    .status == "pending" &&
                                                this.state.kitWithKitSet
                                                    .filter(
                                                        item =>
                                                            (item.quantity >
                                                                0 ||
                                                                item.hire_charge >
                                                                0) &&
                                                            item.cartAdded == 0,
                                                    )
                                                    .map((kit, index) => (
                                                        <React.Fragment
                                                            key={index}>
                                                            <tr>
                                                                <th colSpan="2">
                                                                    <span className="mr-4">
                                                                        {
                                                                            kit.name
                                                                        }
                                                                        {kit
                                                                            .kitDocument
                                                                            .length >
                                                                            0 && (
                                                                                <span className="ml-1">
                                                                                    <a
                                                                                        id={
                                                                                            "UncontrolledTooltipPdf1" +
                                                                                            index
                                                                                        }
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                        className="download-info badge1 badge-info1"
                                                                                        href={
                                                                                            process
                                                                                                .env
                                                                                                .REACT_APP_APIURL +
                                                                                            "assets/uploads/kitDocuments/" +
                                                                                            kit
                                                                                                .kitDocument[0]
                                                                                                .name
                                                                                        }>
                                                                                        Download Tray List
                                                                                    </a>

                                                                                </span>
                                                                            )}
                                                                    </span>

                                                                    <span
                                                                        style={{
                                                                            "vertical-align":
                                                                                "bottom",
                                                                        }}
                                                                        className="small">
                                                                        {(kit.hire_charge >
                                                                            0 ||
                                                                            kit.quantity >
                                                                            0) && (
                                                                                <React.Fragment>
                                                                                    <span>
                                                                                        Requested
                                                                                        Quantity
                                                                                        :{" "}
                                                                                        {
                                                                                            kit.quantity
                                                                                        }
                                                                                    </span>{" "}
                                                                                    &nbsp;
                                                                                    {/* <span>
                                                                                        Hire
                                                                                        Charges
                                                                                        (Per
                                                                                        Set)
                                                                                        :{" "}
                                                                                        {
                                                                                            kit.hire_charge
                                                                                        }
                                                                                    </span> */}
                                                                                </React.Fragment>
                                                                            )}
                                                                    </span>
                                                                </th>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                            {this.state.kitWithKitSet &&
                                                this.state.kitWithKitSet
                                                    .filter(
                                                        item =>
                                                            item.cartAdded != 0,
                                                    )
                                                    .map((kit, index) => (
                                                        <React.Fragment
                                                            key={index}>
                                                            <tr>
                                                                <th colSpan="2">
                                                                    <span className="mr-4 kitname" >
                                                                        {
                                                                            kit.name
                                                                        }
                                                                        {kit
                                                                            .kitDocument
                                                                            .length >
                                                                            0 && (
                                                                                <span className="ml-1">
                                                                                    <a
                                                                                        id={
                                                                                            "UncontrolledTooltipPdf2" +
                                                                                            index
                                                                                        }
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                        className="download-info badge1 badge-info1"
                                                                                        href={
                                                                                            process
                                                                                                .env
                                                                                                .REACT_APP_APIURL +
                                                                                            "assets/uploads/kitDocuments/" +
                                                                                            kit
                                                                                                .kitDocument[0]
                                                                                                .name
                                                                                        }>
                                                                                        Download Tray List
                                                                                    </a>

                                                                                </span>
                                                                            )}
                                                                    </span>

                                                                    <span
                                                                        style={{
                                                                            "vertical-align":
                                                                                "bottom",
                                                                        }}
                                                                        className="small">
                                                                        {(kit.hire_charge >
                                                                            0 ||
                                                                            kit.quantity >
                                                                            0) && (
                                                                                <React.Fragment>
                                                                                    <span>
                                                                                        Requested
                                                                                        Quantity
                                                                                        :{" "}
                                                                                        {
                                                                                            kit.quantity
                                                                                        }
                                                                                    </span>{" "}
                                                                                    &nbsp;
                                                                                    {/* <span>
                                                                                        Hire
                                                                                        Charges
                                                                                        (Per
                                                                                        Set)
                                                                                        :{" "}
                                                                                        {
                                                                                            kit.hire_charge
                                                                                        }
                                                                                    </span> */}
                                                                                </React.Fragment>
                                                                            )}
                                                                    </span>

                                                                    <div className="mt-2">
                                                                        {kit.sets
                                                                            .filter(
                                                                                item =>
                                                                                    item.added ==
                                                                                    1,
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    setd,
                                                                                    ind,
                                                                                ) => (
                                                                                    <span
                                                                                        className="d-inline-block capsul_kit"
                                                                                        key={
                                                                                            ind
                                                                                        }>
                                                                                        {
                                                                                            setd.set_name
                                                                                        }{" "}
                                                                                    </span>
                                                                                ),
                                                                            )}
                                                                    </div>
                                                                </th>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <KitModal isOpen={this.state.isOpen} toggle={this.closeModal} item={kit} />

                                                        </React.Fragment>
                                                    ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="row align-items-end">
                                    <div className="col-12">
                                        <label>
                                            Select Kit{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div
                                                className="form-group mb-0"
                                                style={{
                                                    width: "calc(100% - 80px)",
                                                    zIndex:"999"
                                                }}>
                                                <Select
                                                    value={
                                                        this.state.selectedKit
                                                    }
                                                    onChange={
                                                        this
                                                            .setSelectedOptionKit
                                                    }
                                                    options={this.state.kits}
                                                    className="small"
                                                />
                                            </div>
                                            <div>
                                                <a
                                                    className="btn btn-primary small"
                                                    href="javascript:;"
                                                    onClick={() =>
                                                        this.cartToggle()
                                                    }>
                                                    <i className="fa fa-shopping-cart"></i>{" "}
                                                    {this.state.cartItemCount}
                                                </a>
                                            </div>
                                        </div>
                                        {this.state.kitSetErrorFlag == 1 ? (
                                            <span className="text-danger">
                                                Please add at least one kit set
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.kitWithKitSet.length > 0 &&
                                            this.state.kitWithKitSet.filter(
                                                k =>
                                                    k.cartAdded == 0 &&
                                                    k.hire_charge != "" &&
                                                    k.hire_charge != null,
                                            ).length > 0 &&
                                            this.state.addedKitFlag && (
                                                <span className="text-danger">
                                                    The selected kit is
                                                    different from requested
                                                    kits.
                                                </span>
                                            )}
                                    </div>

                                    <div className="col-sm-12 mt-3">
                                        <div
                                            className={
                                                this.state.cartFlag === true
                                                    ? "collapse1 show1"
                                                    : " collapse1 "
                                            }
                                            id="collapseExample">
                                            <div className="card card-body">
                                                <table className="table mb-0 mobile_full">
                                                    <tbody>
                                                        {this.state
                                                            .kitWithKitSet &&
                                                            this.state.kitWithKitSet
                                                                .filter(
                                                                    item =>
                                                                        item.cartAdded !=
                                                                        0,
                                                                )
                                                                .map(
                                                                    (
                                                                        kit,
                                                                        index,
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={
                                                                                index
                                                                            }>
                                                                            <tr className="mobileFullView">
                                                                                <th
                                                                                    colSpan="2"
                                                                                    className="w-100">
                                                                                    <span className="mt-4">
                                                                                        {
                                                                                            kit.name
                                                                                        }
                                                                                        {kit
                                                                                            .kitDocument
                                                                                            .length >
                                                                                            0 && (
                                                                                                <span className="ml-1">
                                                                                                    <a
                                                                                                        id={
                                                                                                            "UncontrolledTooltipPdf3" +
                                                                                                            index
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                        rel="noreferrer"
                                                                                                        className="download-info badge1 badge-info1"
                                                                                                        href={
                                                                                                            process
                                                                                                                .env
                                                                                                                .REACT_APP_APIURL +
                                                                                                            "assets/uploads/kitDocuments/" +
                                                                                                            kit
                                                                                                                .kitDocument[0]
                                                                                                                .name
                                                                                                        }>
                                                                                                        Download Tray List
                                                                                                    </a>

                                                                                                </span>
                                                                                            )}
                                                                                    </span>
                                                                                </th>
                                                                                <td className="text-right">
                                                                                    <a
                                                                                        href="javascript:;"
                                                                                        onClick={() =>
                                                                                            this.handelRemovKitFromCart(
                                                                                                index,
                                                                                                kit._id,
                                                                                            )
                                                                                        }>
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            {this.checkAvalabilityForConnsignment(
                                                                                kit,
                                                                            )}
                                                                            {kit.sets.map(
                                                                                (
                                                                                    setd,
                                                                                    ind,
                                                                                ) => (
                                                                                    <tr
                                                                                        key={
                                                                                            ind
                                                                                        }>
                                                                                        {this.checkAvalability(
                                                                                            setd.bookingKitSets,
                                                                                            setd,
                                                                                            setd._id,
                                                                                            index,
                                                                                            ind,
                                                                                            kit._id,
                                                                                            1,
                                                                                        )}
                                                                                    </tr>
                                                                                ),
                                                                            )}
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <table className="table mb-0 mobile_full">
                                        <tbody>
                                            {this.state.kitWithKitSet &&
                                                this.state.cartFlag == false &&
                                                this.state.kitWithKitSet
                                                    .filter(
                                                        item =>
                                                            item._id ==
                                                            this.state
                                                                .singleSelectedKitId,
                                                    )
                                                    .map((kit, index) => (
                                                        <React.Fragment
                                                            key={index}>
                                                            <tr className="mobileFullView">
                                                                <th colSpan="2">
                                                                    <span className="mr-4" 
                                                                    //onClick={this.openModal}
                                                                    >
                                                                        {
                                                                            kit.name
                                                                        }
                                                                        {kit
                                                                            .kitDocument
                                                                            .length >
                                                                            0 && (
                                                                                <span className="ml-1">
                                                                                    <a
                                                                                        id={
                                                                                            "UncontrolledTooltipPdf4" +
                                                                                            index
                                                                                        }
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                        className="download-info badge1 badge-info1"
                                                                                        href={
                                                                                            process
                                                                                                .env
                                                                                                .REACT_APP_APIURL +
                                                                                            "assets/uploads/kitDocuments/" +
                                                                                            kit
                                                                                                .kitDocument[0]
                                                                                                .name
                                                                                        }>
                                                                                        Download Tray List
                                                                                    </a>

                                                                                </span>
                                                                            )}
                                                                    </span>

                                                                    <span
                                                                        style={{
                                                                            "vertical-align":
                                                                                "bottom",
                                                                        }}
                                                                        className="small">
                                                                        {(kit.hire_charge >
                                                                            0 ||
                                                                            kit.quantity >
                                                                            0) && (
                                                                                <React.Fragment>
                                                                                    <span>
                                                                                        Requested
                                                                                        Quantity
                                                                                        :{" "}
                                                                                        {
                                                                                            kit.quantity
                                                                                        }
                                                                                    </span>{" "}
                                                                                    &nbsp;
                                                                                    {/* <span>
                                                                                        Hire
                                                                                        Charges
                                                                                        (Per
                                                                                        Set)
                                                                                        :{" "}
                                                                                        {
                                                                                            kit.hire_charge
                                                                                        }
                                                                                    </span> */}
                                                                                </React.Fragment>
                                                                            )}
                                                                    </span>
                                                                </th>
                                                                <td className="text-right">
                                                                    <a
                                                                        href="javascript:;"
                                                                        onClick={() =>
                                                                            this.handelRemovKit(
                                                                                index,
                                                                                kit._id,
                                                                            )
                                                                        }>
                                                                        {" "}
                                                                        <i className="fa fa-trash"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            {this.checkAvalabilityForConnsignment(
                                                                kit,
                                                                index,
                                                            )}
                                                            {kit.sets.map(
                                                                (setd, ind) => (
                                                                    <tr
                                                                        key={
                                                                            ind
                                                                        }>
                                                                        {this.checkAvalability(
                                                                            setd.bookingKitSets,
                                                                            setd,
                                                                            setd._id,
                                                                            index,
                                                                            ind,
                                                                            kit._id,
                                                                            2,
                                                                        )}
                                                                    </tr>
                                                                ),
                                                            )}
                                                            <KitModal isOpen={this.state.isOpen} toggle={this.closeModal} item={kit} />

                                                        </React.Fragment>
                                                    ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            {/* <div className="d-card">
                                <h4>Charges</h4>
                                <div className="form-group">
                                    <label>Shipping Charge</label>
                                    <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        value={
                                            this.state.updateBookingInfo
                                                .shipping_charge
                                        }
                                        onChange={e =>
                                            this.handleUpdateBookingInfo(
                                                "shipping_charge",
                                                e.target.value,
                                            )
                                        }
                                    />
                                </div>
                            </div> */}

                            <div className="d-card">
                                <h4>Booked By</h4>
                                <div className="form-group">
                                    <label>Assigned Rep</label>
                                    <div className="position_up">
                                        <Select
                                            value={this.state.selectedRep}
                                            onChange={this.setSelectedOptionRep}
                                            options={this.state.reps}
                                        />
                                        {error ? (
                                            <span className="text-danger">
                                                {error.assigned_rep
                                                    ? error.assigned_rep
                                                    : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Additional Reps</label>
                                    <Select
                                        value={this.state.selectedAdditionalRep}
                                        onChange={
                                            this.setSelectedOptionAdditionalRep
                                        }
                                        options={this.state.additionalReps}
                                        isMulti={true}
                                    />
                                </div>
                            </div>

                            <div className="d-card mt-4">
                                <h4>Additional Notes</h4>
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        value={
                                            this.state.updateBookingInfo
                                                .additional_notes
                                        }
                                        onChange={e =>
                                            this.handleUpdateBookingInfo(
                                                "additional_notes",
                                                e.target.value,
                                            )
                                        }></textarea>
                                </div>
                            </div>

                            <div className="mt-5 row">
                                {loading == false && (
                                    <React.Fragment>
                                        <div className="col-6">
                                            <Link
                                                to="/admin/bookings"
                                                className="btn btn-block btn-secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="col-6">
                                            <a
                                                href="javascript:;"
                                                onClick={() =>
                                                    this.saveChangeHandle()
                                                }
                                                className="btn btn-block btn-primary">
                                                Save Changes
                                            </a>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <a
                                                href="javascript:;"
                                                onClick={() =>
                                                    this.sendEmailConfirmHandle()
                                                }
                                                className="btn btn-block btn-primary">
                                                Save and send email
                                            </a>
                                        </div>
                                    </React.Fragment>
                                )}
                                {loading == true && (
                                    <React.Fragment>
                                        <div className="col-6">
                                            <Link
                                                to="/admin/bookings"
                                                className="btn btn-secondary btn-block">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="col-6">
                                            <button
                                                className="btn btn-primary loader-icon btn-block"
                                                disabled>
                                                <Spinner className="img-svg"></Spinner>
                                            </button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* ADD FORM */}
                </div>

                <Modal
                    size="lg"
                    backdrop="static"
                    isOpen={this.state.kitSetDetailModal}
                    toggle={() => this.kitSetDetailModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.kitSetDetailModalClose()}>
                        Set Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.kitDetails.setProductModules &&
                                    this.state.kitDetails.setProductModules.map(
                                        (product, ind) => (
                                            <tr key={ind}>
                                                <td>
                                                    {product.productID.code}
                                                </td>
                                                <td>
                                                    {product.productID.name}
                                                </td>
                                                <td>{product.quantity}</td>
                                            </tr>
                                        ),
                                    )}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                className="btn btn-secondary"
                                onClick={() => this.kitSetDetailModalClose()}>
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        bookingInfo,
        repsList,
        error,
        redirect,
        kitSetList,
        loading,
    } = state.rootReducer.bookings;
    const { kitListDropdown } = state.rootReducer.kits;
    const { editSet, set } = state.rootReducer.sets;

    return {
        bookingInfo,
        repsList,
        error,
        redirect,
        kitListDropdown,
        kitSetList,
        editSet,
        set,
        loading,
    };
}

export default connect(mapStateToProps)(Edit);
