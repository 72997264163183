import React, { Component } from "react";
import { connect } from "react-redux";
//import dateFormat from "dateformat";


class List extends Component {
    constructor(props) {
        super(props);

        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
           
            months:[]
        };
        
        this.detailHandle=this.detailHandle.bind(this);
    }

    detailHandle(e,id,did,falgForHtm){
        console.log(id,e.target.dataset.dbid,falgForHtm);
        if(e.target.dataset.flag<2){
            did=0;
        }
        this.props.bookingDetailHandle(id,did);
    }

    componentDidMount()
    {
        
      
    }

    checkAvalability(setData,indSet,ind,filter){
       
        var htm=[]; 
        var htm1=[];
        var bookedDate=[600]; 
        var fromDate=""; 
        var toDate="";
        //var difference="";
        //var TotalDays="";
        //var indexNew="";
        var falgForHtm=0;
        var booking_id=0;
        var dbooking_id=0;
        var booking_single=[];
        var bookingDate="";
        var endDate="";
        var singleBked=0;
        var dubleBked=0;
        //var i=0;
        if(setData.bookingSets.length==0)
        {
            htm1.push(<td>{setData.set_name}</td>);
            this.props.dates && this.props.dates.map((dates) => {
                htm1.push(<td data-da={dates.months} data-dd={indSet} className="albl"  data-toggle="tooltip" data-placement="top" title="Available"></td>);
                booking_single.push({date:dates.fulldate,start:"",end:"",booking_id:"",falgForHtm:""});

            });

        }
        else
        {
            
            this.props.dates && this.props.dates.map((dates,index) => {

               
               
                if(bookedDate.indexOf(index) === -1)  
                {
                   
                    falgForHtm=0;
                    fromDate="";
                    toDate="";
                    booking_id=0;
                    dbooking_id=0;

                    
                 
                    setData.bookingSets.map((bookingSt) => {

                        if(bookingSt.set_return_date && bookingSt.set_return_date!=null)
                        {
                            
                            
                            bookingSt.bookings && bookingSt.bookings.filter(rs=>rs.delivery_date<=dates.fulldate ).map((booking) => {
                                if(booking.delivery_date<=dates.fulldate && bookingSt.set_return_date>=dates.fulldate)
                                {
                                    falgForHtm+=1;
                                    if(falgForHtm>1)
                                    {
                                        //alert(dates.fulldate+"   "+setData.set_name);
                                        dbooking_id=booking._id;
                                    }
                                    if(bookingDate=="")
                                    {
                                        bookingDate=booking.delivery_date;
                                        endDate=bookingSt.set_return_date;
                                        if(booking_id==0){
                                            booking_id=booking._id;
                                        }
                                       
                                    }
                                    else if(bookingSt.set_return_date>bookingDate)
                                    {
                                        bookingDate=booking.delivery_date;
                                        if(booking_id==0){
                                            booking_id=booking._id;
                                        }
                                        
                                        endDate=bookingSt.set_return_date;
                                    }
                                }

                            });

                        }

                        else if(bookingSt.bookings && bookingSt.bookings.filter(rs=>rs.delivery_date<=dates.fulldate && rs.return_date>=dates.fulldate).length)
                        {
                            falgForHtm+=1;
                            bookingSt.bookings && bookingSt.bookings.filter(rs=>rs.delivery_date<=dates.fulldate && rs.return_date>=dates.fulldate).map((booking) => {
                                if(falgForHtm>1)
                                {
                                    dbooking_id=booking._id;
                                }
                                if(bookingDate=="")
                                {
                                    bookingDate=booking.delivery_date;
                                    endDate=booking.return_date;
                                    booking_id=booking._id;
                                }
                                else if(booking.return_date>bookingDate)
                                {
                                    bookingDate=booking.delivery_date;
                                    booking_id=booking._id;
                                    endDate=booking.return_date;
                                }

                            });


                        }
                      
                    });

                    booking_single.push({dbooking_id:dbooking_id,date:dates.fulldate,start:bookingDate,end:endDate,booking_id:booking_id,falgForHtm:falgForHtm});
                    

                    if(falgForHtm >0)
                    {
                        singleBked=1;
                        //htm.push(<td onClick={()=>this.detailHandle(booking_id)}  from={fromDate} data-todate={toDate} className={"bkd " +(falgForHtm>1 ? "dbkd " : " ") }   data-toggle="tooltip" data-placement="top" title="Booked"></td>);
                        if(filter!="Double Booked")
                        {
                            htm.push(<td onClick={(e)=>this.detailHandle(e,booking_id,dbooking_id,falgForHtm)}  data-test="1" data-dbid={dbooking_id} data-flag={falgForHtm}  from={fromDate} data-todate={toDate} className={"bkd " +(falgForHtm>1 ? "dbkd " : " ") }   data-toggle="tooltip" data-placement="top" title="Booked"></td>);

                        }
                        else if(falgForHtm>1)
                        {
                            htm.push(<td onClick={(e)=>this.detailHandle(e,booking_id,dbooking_id,falgForHtm)} data-test="2" data-dbid={dbooking_id} from={fromDate} data-todate={toDate} className="dbkd"   data-toggle="tooltip" data-placement="top" title="Booked"></td>);
 
                        }
                        else
                        {
                            htm.push(<td  from={fromDate} data-todate={toDate} className="albl"   data-toggle="tooltip" data-placement="top" title="Available"></td>);

                        }
                    }

                    if(falgForHtm>1)
                    {
                        dubleBked=1;
                    }



                    if(falgForHtm == 0)
                    {
                        htm.push(<td data-ddeeee={dates.fulldate} data-da={dates.months}  className="albl"  data-toggle="tooltip" data-placement="top" title="Available"></td>);
                    }
                }
            });

            var oldBooking="";
            var colSpan=0;
            falgForHtm=0;
            var oldDubbleBookk=0;
            var oldZero=0;
            if(ind==2)
            {
                console.log(booking_single);
            }
            
            htm1.push(<td>{setData.set_name}</td>);

            booking_single && booking_single.map((dates,ind) => {
                
                
                if(ind==0)
                {
                    falgForHtm=dates.falgForHtm;
                    oldBooking=dates.booking_id;
                    oldDubbleBookk=dates.dbooking_id;
                }

                if(dates.booking_id==0)
                {
                    if(oldBooking!=0)
                    {  
                        if(falgForHtm>1)
                        {
                            htm1.push(<td colSpan={colSpan} onClick={(e)=>this.detailHandle(e,booking_single[ind-1].booking_id,booking_single[ind-1].dbooking_id,falgForHtm)} data-test="3" data-dbid={booking_single[ind-1].dbooking_id} data-flag={falgForHtm} data-da={dates.dates} className="bkd dbkd" data-toggle="tooltip" data-placement="top" title="Booked"></td>);
                        }
                        else if(filter!="Double Booked")
                        {
                            htm1.push(<td colSpan={colSpan} onClick={(e)=>this.detailHandle(e,booking_single[ind-1].booking_id,booking_single[ind-1].dbooking_id,falgForHtm)} data-test="4" data-dbid={booking_single[ind-1].dbooking_id} data-flag={falgForHtm} data-da={dates.dates} className="bkd" data-toggle="tooltip" data-placement="top" title="Booked"></td>);

                        }
                        else
                        {
                            for(var k=0;k<colSpan;k++){
                                htm1.push(<td  data-da={dates.dates} className="albl" data-toggle="tooltip" data-placement="top" title="Available 1"></td>);
                            }

                        }
                    } 
                   
                    oldZero=1;
                    htm1.push(<td  data-da={dates.dates} className="albl" data-toggle="tooltip" data-placement="top" title="Available 4"></td>);

                    
                    oldBooking=dates.booking_id;
                    falgForHtm=dates.falgForHtm;
                    oldDubbleBookk=dates.dbooking_id;
               
                    colSpan=1;
                }
                else if(oldBooking==dates.booking_id && oldDubbleBookk!=dates.dbooking_id && dates.dbooking_id!=0)
                {

                    if(oldBooking!=0)
                    {  
                       
                        if(falgForHtm>1)
                        {
                            htm1.push(<td colSpan={colSpan} data-flag={falgForHtm} onClick={(e)=>this.detailHandle(e,booking_single[ind-1].booking_id,booking_single[ind-1].dbooking_id,falgForHtm)} data-test="5" data-dbid={booking_single[ind-1].dbooking_id}  data-da={dates.dates} className="bkd dbkd" data-toggle="tooltip" data-placement="top" title="Booked"></td>);
                        }
                        else if(filter!="Double Booked")
                        {
                            htm1.push(<td  colSpan={colSpan} data-flag={falgForHtm} onClick={(e)=>this.detailHandle(e,booking_single[ind-1].booking_id,booking_single[ind-1].dbooking_id,falgForHtm)} data-test="6" data-dbid={booking_single[ind-1].dbooking_id}  data-da={dates.dates} className="bkd" data-toggle="tooltip" data-placement="top" title="Booked"></td>);
                        }   
                        else
                        {
                            for(var lk=0;lk<colSpan;lk++){
                                htm1.push(<td  data-da={dates.dates} className="albl" data-toggle="tooltip" data-placement="top" title="Available 1"></td>);
                            }
                        }         
                    } 
                    else if(oldBooking ==0 && oldZero==0)
                    {
                        
                        htm1.push(<td colSpan={colSpan} data-da={dates.dates} className="albl" data-toggle="tooltip" data-placement="top" title="Available 4"></td>);

                    }
                    oldZero=0;
                    oldBooking=dates.booking_id;
                    falgForHtm=dates.falgForHtm;
                    oldDubbleBookk=dates.dbooking_id;
                   

                    colSpan=1;
                }
                else if(oldBooking!=dates.booking_id)
                {

                    if(oldBooking!=0)
                    {  
                       
                        if(falgForHtm>1)
                        {
                            htm1.push(<td colSpan={colSpan} data-flag={falgForHtm} onClick={(e)=>this.detailHandle(e,booking_single[ind-1].booking_id,booking_single[ind-1].dbooking_id,falgForHtm)} data-test="5" data-dbid={booking_single[ind-1].dbooking_id}  data-da={dates.dates} className="bkd dbkd" data-toggle="tooltip" data-placement="top" title="Booked"></td>);
                        }
                        else if(filter!="Double Booked")
                        {
                            htm1.push(<td  colSpan={colSpan} data-flag={falgForHtm} onClick={(e)=>this.detailHandle(e,booking_single[ind-1].booking_id,booking_single[ind-1].dbooking_id,falgForHtm)} data-test="6" data-dbid={booking_single[ind-1].dbooking_id}  data-da={dates.dates} className="bkd" data-toggle="tooltip" data-placement="top" title="Booked"></td>);
                        }   
                        else
                        {
                            for(var l=0;l<colSpan;l++){
                                htm1.push(<td  data-da={dates.dates} className="albl" data-toggle="tooltip" data-placement="top" title="Available 1"></td>);
                            }
                        }         
                    } 
                    else if(oldBooking ==0 && oldZero==0)
                    {
                        
                        htm1.push(<td colSpan={colSpan} data-da={dates.dates} className="albl" data-toggle="tooltip" data-placement="top" title="Available 4"></td>);

                    }
                    oldZero=0;
                    oldBooking=dates.booking_id;
                    falgForHtm=dates.falgForHtm;
                    oldDubbleBookk=dates.dbooking_id;
                   

                    colSpan=1;
                }
                else if((falgForHtm==1 && dates.falgForHtm>1) ||  falgForHtm>1 && dates.falgForHtm==1)
                {
                    if(oldBooking!=0)
                    {  
                       
                        if(falgForHtm>1)
                        {
                            htm1.push(<td colSpan={colSpan} data-flag={falgForHtm} onClick={(e)=>this.detailHandle(e,booking_single[ind-1].booking_id,booking_single[ind-1].dbooking_id,falgForHtm)} data-test="7" data-dbid={booking_single[ind-1].dbooking_id} data-da={dates.dates} className="bkd dbkd" data-toggle="tooltip" data-placement="top" title="Booked"></td>);
                        }
                        else if(filter!="Double Booked")
                        {
                            htm1.push(<td colSpan={colSpan} data-flag={falgForHtm} onClick={(e)=>this.detailHandle(e,booking_single[ind-1].booking_id,booking_single[ind-1].dbooking_id,falgForHtm)} data-test="8" data-dbid={booking_single[ind-1].dbooking_id} data-da={dates.dates} className="bkd" data-toggle="tooltip" data-placement="top" title="Booked"></td>);
                        }   
                        else
                        {
                            for(var m=0;m<colSpan;m++){
                                htm1.push(<td  data-da={dates.dates} className="albl" data-toggle="tooltip" data-placement="top" title="Available 1"></td>);
                            }
                        }         
                    } 
                    else if(oldBooking ==0 && oldZero==0)
                    {
                        
                        htm1.push(<td colSpan={colSpan} data-da={dates.dates} className="albl" data-toggle="tooltip" data-placement="top" title="Available 4"></td>);

                    }
                    oldZero=0;
                    oldBooking=dates.booking_id;
                    falgForHtm=dates.falgForHtm;
                    oldDubbleBookk=dates.dbooking_id;
                   

                    colSpan=1;
                }
                else if(booking_single.length-1==ind)
                {
                    if(oldBooking!=0)
                    {  
                       
                        if(falgForHtm>1)
                        {
                            htm1.push(<td colSpan={colSpan} data-flag={falgForHtm} onClick={(e)=>this.detailHandle(e,booking_single[ind-1].booking_id,booking_single[ind-1].dbooking_id,falgForHtm)} data-test="9" data-dbid={booking_single[ind-1].dbooking_id} data-da={dates.dates} className="bkd dbkd" data-toggle="tooltip" data-placement="top" title="Booked"></td>);
                        }
                        else if(filter!="Double Booked")
                        {
                            htm1.push(<td colSpan={colSpan} data-flag={falgForHtm} onClick={(e)=>this.detailHandle(e,booking_single[ind-1].booking_id,booking_single[ind-1].dbooking_id,falgForHtm)} data-test="10" data-dbid={booking_single[ind-1].dbooking_id} data-da={dates.dates} className="bkd" data-toggle="tooltip" data-placement="top" title="Booked"></td>);
                        }  
                        else
                        {
                            for(var n=0;n<colSpan;n++){
                                htm1.push(<td  data-da={dates.dates} className="albl" data-toggle="tooltip" data-placement="top" title="Available 1"></td>);
                            }
                        }          
                    } 
                    else if(oldBooking ==0 && oldZero==0)
                    {
                        
                        htm1.push(<td colSpan={colSpan} data-da={dates.dates} className="albl" data-toggle="tooltip" data-placement="top" title="Available 4"></td>);

                    }
                }
                else
                {
                    colSpan++;
                    oldZero=0;
                }
                

            });

           
            
        }

        if(filter=="" || setData.bookingSets.length==0)
        {
            return htm1;
        }
        else if(filter=="Booked" && singleBked==1)
        {
            return htm1;
        }
        else if(filter=="Double Booked" && dubleBked==1)
        {
            return htm1;
        }
        else
        {
            return [];
        }

    }

    /*{ this.props.dates && this.props.dates.map((dates,indDate) => (
        
    ))}*/

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
 
    }
    render() {
       
        return (
            // <div className="table_content"></div>
            <tbody className="table_content">
                { this.props.lists && this.props.lists.map((data,ind) => (
                    <React.Fragment key={ind}>
                        <tr className="cat-row" >
                            <th className="fix_row"><span>{data.name}</span></th>
                            <th colSpan={this.props.dates.length-1}></th>
                        </tr>
                        {data.sets && data.sets.map((setData,indSet) => (
                            <tr className="kit-row" key={indSet}>
                                
                                {this.checkAvalability(setData,indSet,ind,this.props.statusFilter)}
   
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
            </tbody>
        );
    }
}

function mapStateToProps(state) {
    const { error } = state.rootReducer.hospitals;
    const { lists} = state.rootReducer.availabilitys;
    return {
        error,
        lists
    };
}

export default connect(mapStateToProps)(List);